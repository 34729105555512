import { ChatItem } from './ChatItem';
import { useSubscription } from '@apollo/client';
import { FOLLOW_UP_QUESTION_SUBSCRIPTION } from '../../../api/graphQL';
import { useEffect, useState } from 'react';
import { useProject } from '../../../context-providers/Project';
import { useDispatch, useSelector } from 'react-redux';
import { waitingForChatResponse } from '../../../redux/reducers/generic/chatHistory';
import { addToChatHistory } from '../../../redux/actions/solo/addToChatHistory';

export const StreamingChat = ({ chatContainer }) => {
    const dispatch = useDispatch();
    let { chatHistory, projectId } = useProject();
    let chat = chatHistory.data;
    let lastIndex = chat[chat.length - 1]?.index;
    const [message, setMessage] = useState({
        role: 'assistant',
        index: lastIndex,
        content: ''
    });
    useSubscription(FOLLOW_UP_QUESTION_SUBSCRIPTION, {
        variables: { projectId, nodeId: null },
        shouldResubscribe: true,
        onData: data => {
            try {
                let msg = data.data.data.chatStream;

                //User chat has come in
                if (
                    msg.completed &&
                    msg.index == lastIndex + 1 &&
                    msg.role === 'user'
                ) {
                    dispatch(waitingForChatResponse(true));
                    dispatch(addToChatHistory(msg, true)); //someone else has answered
                    setMessage({
                        role: 'assistant',
                        index: msg.index,
                        content: '',
                        _id: msg._id
                    });
                }

                //Assistant chat has come in
                else if (
                    msg.completed &&
                    (msg.index == lastIndex || msg.index == lastIndex + 1) &&
                    msg.role === 'assistant'
                ) {
                    dispatch(waitingForChatResponse(false));
                    dispatch(addToChatHistory(msg, true));
                    setMessage({
                        role: 'assistant',
                        index: msg.index,
                        content: '',
                        _id: msg._id
                    });
                }

                //Assistant chat is slowly coming in
                else if (!msg.completed) {
                    setMessage(msg);
                }

                scrollToBottom({ chatContainer });
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
        // client: clientWithAPIKey(apiKey),
    });

    let isLoading =
        chatHistory.waitingForChatResponse &&
        (lastIndex == message.index || message.role !== 'user') &&
        chat[chat.length - 1].role === 'user';

    return (
        chatHistory.waitingForChatResponse && (
            <ChatItem message={{ ...message, isLoading }} chat={chat} />
        )
    );
};

function scrollToBottom({ chatContainer }) {
    const threshold = chatContainer?.current?.clientHeight * 0.1;
    const isNearBottom =
        chatContainer?.current?.scrollHeight -
            chatContainer?.current?.scrollTop <=
        chatContainer?.current?.clientHeight + threshold;

    try {
        if (isNearBottom) {
            setTimeout(() => {
                chatContainer.current.scrollTo({
                    top: chatContainer.current.scrollHeight + 10
                });
            }, 50);
        }
    } catch (error) {
        console.log(error);
    }
}
