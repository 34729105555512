import { ChatHistory } from './ChatHistory';
import { ChatInput } from './ChatInput';
import { useProject } from '../../../context-providers/Project';
import { ExistingProjectContext } from './ExistingProjectContext';

export const Chat = ({ isSolo, isEnterprise, isShare, setShowDemoBanner }) => {
    let { pageType } = useProject();

    return (
        pageType == 'chat' && (
            <>
                <ExistingProjectContext />
                <div className="dark overflow-y-scroll">
                    <ChatHistory isSolo={isSolo} />
                </div>
                <ChatInput
                    setShowDemoBanner={setShowDemoBanner}
                    isSolo={isSolo}
                    isEnterprise={isEnterprise}
                    isShare={isShare}
                />
            </>
        )
    );
};
