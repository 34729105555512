import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { getWebsiteDomain } from '../../../../auth_config';
import { SwitchDefault } from '../Helpers/SwitchDefault';
import { useState } from 'react';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toast } from 'sonner';

export const OnboardingLinks = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { isSubbed } = useSubscriptionPayment();

    const toggles = [
        {
            text: 'Allow RFP Upload',
            key: 'noRFP',
            value: true
        },
        {
            text: 'Email Input Required',
            key: 'noEmail',
            value: true
        },
        {
            text: 'Allow Project Type Selection',
            key: 'onlyGeneric',
            value: true
        }
    ];

    const [options, setOptions] = useState(toggles);

    const setField = (key, value) => {
        setOptions(
            options.map(option =>
                option.key === key ? { ...option, value } : option
            )
        );
    };

    const apiKey = enterpriseOrganization
        ? enterpriseOrganization.apiKey
        : null;
    let clientOnboardingLink = apiKey
        ? `${getWebsiteDomain()}/enterprise/chat/${apiKey}?`
        : '';

    let genericOnboardingLink = apiKey
        ? `${getWebsiteDomain()}/enterprise/generic/${apiKey}?`
        : '';

    let fullOnboardingLink = apiKey
        ? `${getWebsiteDomain()}/iframe/enterprise/full/${apiKey}?`
        : '';

    options.map(option => {
        if (!option.value) {
            clientOnboardingLink += `${option.key}=true&`;
            genericOnboardingLink += `${option.key}=true&`;
            fullOnboardingLink += `${option.key}=true&`;
        }
    });

    const handleViewLink = linkType => {
        if (!isSubbed) {
            toast.error(
                'Please upgrade to Enterprise Premium to view this link.'
            );
        }
    };

    return (
        <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
                Client Onboarding:
            </h3>
            <div className="mt-2 text-sm text-gray-500">
                {isSubbed ? (
                    <a
                        href={clientOnboardingLink}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 break-all hover:underline"
                    >
                        {clientOnboardingLink}
                    </a>
                ) : (
                    <button
                        onClick={() => handleViewLink('client')}
                        className="text-blue-600 hover:underline"
                    >
                        View Client Onboarding Link
                    </button>
                )}
            </div>
            <h3 className="mt-4 text-base font-semibold leading-6 text-gray-900">
                Full Project Interface:
            </h3>
            <div className="mt-2 text-sm text-gray-500">
                {isSubbed ? (
                    <a
                        href={fullOnboardingLink}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 break-all hover:underline"
                    >
                        {fullOnboardingLink}
                    </a>
                ) : (
                    <button
                        onClick={() => handleViewLink('full')}
                        className="text-blue-600 hover:underline"
                    >
                        View Full Project Interface Link
                    </button>
                )}
            </div>
            <hr className="my-4" />
            <div className="grid grid-flow-row gap-x-2 gap-y-2">
                {options.map(option => (
                    <div className="flex items-center justify-between border-gray-400 border gap-x-4 bg-gray-100 p-2 rounded-lg">
                        <div className="text-sm text-gray-700">
                            <p>{option.text}</p>
                        </div>
                        <SwitchDefault
                            enabled={option.value}
                            setEnabled={value =>
                                setField(option.key, !option.value)
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
