
import { cn } from "../Components/magicui/lib/utils";
import { useCallback, useState, useEffect, useRef } from 'react';
import { AnimatedBeamMultipleInputDemo } from "../Components/magicui/animated-beam-multiple";
import { BentoCard, BentoGrid } from "../Components/magicui/bento-grid";
import Marquee from "../Components/magicui/marquee";
import { CalendarIcon, ChatBubbleIcon, InputIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Share2Icon } from "lucide-react";
import { cubicBezier, motion } from "framer-motion";
import { BoltIcon, CodeBracketSquareIcon} from '@heroicons/react/24/outline'
import { ForceGraph2D } from 'react-force-graph';
import {CallToAction2} from './cta-section-2'
import OrbitingCircles from '../Components/magicui/orbiting-circles'

import { StarFilledIcon } from "@radix-ui/react-icons";
 
export const Highlight = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        "bg-cyan-600/20 p-1 py-0.5 font-bold text-cyan-600 dark:bg-cyan-600/20 dark:text-cyan-600",
        className,
      )}
    >
      {children}
    </span>
  );
};

const files = [
  {
    name: "Rideshare Docs",
    body: <>Overview<br/>User Stories<br/>Tech Stack<br/>Deliverables<br/>Milestones</>
  },
  {
    name: "Admin Feature Docs",
    body: <>Overview<br/>User Stories<br/>Tech Stack<br/>Deliverables<br/>Milestones</>
  },
  {
    name: "DeFi Protocol Docs",
    body: <>Overview<br/>User Stories<br/>Tech Stack<br/>Deliverables<br/>Milestones</>
  },
  {
    name: "API Feature Docs",
    body: <>Overview<br/>User Stories<br/>Tech Stack<br/>Deliverables<br/>Milestones</>
  },
  {
    name: "FintechApp Docs",
    body: <>Overview<br/>User Stories<br/>Tech Stack<br/>Deliverables<br/>Milestones</>
  },
];

const Icons = {
  gitHub: () => (
    <img className="h-[100px] w-[100px]"
      src='/github.svg' />
  ),
  notion: () => (
    <img className="h-[100px] w-[100px]"
      src='/jira.svg' />
  ),
  openai: () => (
    <svg
      width="100"
      height="100"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
    </svg>
  ),
  googleDrive: () => (
      <img className="h-[100px] w-[100px]"
      src='/aws.svg' />
  ),
  whatsapp: () => (
      <img className="h-[100px] w-[100px]"
      src='/figma-icon.svg' />
  ),
};

interface Item {
  name: string;
  description: string;
  icon: string;
  color: string;
  time: string;
}

let notifications = [
  {
    name: "Payment received",
    description: "Magic UI",
    time: "15m ago",

    icon: "💸",
    color: "#00C9A7",
  },
  {
    name: "User signed up",
    description: "Magic UI",
    time: "10m ago",
    icon: "👤",
    color: "#FFB800",
  },
  {
    name: "New message",
    description: "Magic UI",
    time: "5m ago",
    icon: "💬",
    color: "#FF3D71",
  },
  {
    name: "New event",
    description: "Magic UI",
    time: "2m ago",
    icon: "🗞️",
    color: "#1E86FF",
  },
];

notifications = Array.from({ length: 10 }, () => notifications).flat();

const Notification = ({ name, description, icon, color, time }: Item) => {

  return (
    <figure
      className={cn(
        "relative mx-auto min-h-fit w-full max-w-[400px] transform cursor-pointer overflow-hidden rounded-2xl p-4",
        // animation styles
        "transition-all duration-200 ease-in-out hover:scale-[103%]",
        // light styles
        "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
        // dark styles
        "transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
      )}
    >
      <div className="flex flex-row items-center gap-3">
        <div
          className="flex h-10 w-10 items-center justify-center rounded-2xl"
          style={{
            backgroundColor: color,
          }}
        >
          <span className="text-lg">{icon}</span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white ">
            <span className="text-sm sm:text-lg">{name}</span>
            <span className="mx-1">·</span>
            <span className="text-xs text-gray-500">{time}</span>
          </figcaption>
          <p className="text-sm font-normal dark:text-white/60">
            {description}
          </p>
        </div>
      </div>
    </figure>
  );
};


export function FeatureCard8() {
  const variant1 = {
    initial: {
      y: 0,
      scale: 0.97,
      transition: {
        delay: 0,
        duration: 0.2,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
    whileHover: {
      y: -45,
      scale: 1,
      boxShadow:
        "rgba(39,127,245,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
      transition: {
        delay: 0,
        duration: 0.2,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
  };
  const variant2 = {
    initial: {
      y: 30,
      opacity: 0,
      scale: 0.97,
      transition: {
        delay: 0,
        duration: 0.2,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
    whileHover: {
      y: 10,
      opacity: 1,
      scale: 1,
      boxShadow:
        "rgba(245,40,145,0.05) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
      transition: {
        delay: 0,
        duration: 0.2,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
  };
  const variant3 = {
    initial: {
      x: 30,
      y: 20,
      opacity: 0,
      transition: {
        delay: 0,
        duration: 0.2,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
    whileHover: {
      x: 5,
      y: 5,
      opacity: 1,
      boxShadow:
        "rgba(39,245,76,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
      transition: {
        delay: 0.05,
        duration: 0.2,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
  };
 
  const containerVariants = {
    initial: {},
    whileHover: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
 
  return (
    <div className="dark relative h-full w-full transform-gpu rounded-lg bg-white dark:bg-black md:max-h-[500px]">
      <motion.div
        variants={containerVariants}
        initial="initial"
        whileHover="whileHover"
        className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
      >
        <div className="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-y-1 overflow-hidden rounded-t-xl bg-transparent">
          <div className="relative flex flex-col items-center justify-center gap-y-2 px-10 py-20">
            <motion.div
              variants={variant1}
              className=" relative flex items-start gap-x-2 rounded-lg border border-neutral-400/20 bg-white p-4 shadow-[0px_0px_40px_-25px_rgba(0,0,0,0.25)] dark:border-neutral-800 dark:bg-neutral-900"
            >
              <div className="h-7 w-7 px-[3px] py-[5px] rounded-full bg-black">
                <img
                  className="h-full rounded-full"
                  src="/predev.png"
                  alt="predev"
                />
              </div>
              <div className="w-[calc(100%-3rem)]">
                <h3 className="text-white font-semibold">pre.dev</h3>
                <p className="text-sm text-neutral-500 dark:text-neutral-400">
                  What happens after a user clicks on an item in the main feed?
                </p>
              </div>
            </motion.div>
            <motion.div
              variants={variant2}
              className=" absolute inset-10 -bottom-14 z-[3] m-auto flex h-fit items-start gap-x-2 rounded-lg border border-neutral-400/20 bg-white p-4 shadow-[0px_0px_40px_-25px_rgba(0,0,0,0.25)] dark:border-neutral-800 dark:bg-neutral-900"
            >
              <div className="w-8 ">
                <div className="h-6 w-6 rounded-full bg-blue-500">
                  <img
                    className="h-full w-full rounded-full object-cover"
                    src="https://avatar.vercel.sh/christina"
                    alt="christina"
                  />
                </div>
              </div>
              <div className="w-[calc(100%-3rem)]">
                <h3 className="text-white font-semibold">James</h3>
                <p className="text-sm text-neutral-500 dark:text-neutral-400">
                  You are taken to a user's profile page.
                </p>
              </div>
              <motion.p
                variants={variant3}
                className="highlighted absolute -bottom-2 right-2 w-fit rounded-full border bg-cyan-500 px-2 py-0.5 text-xs text-white"
              >
                sent
              </motion.p>
            </motion.div>
          </div>
        </div>
  
      </motion.div>
    </div>
  );
}


export function FeatureCard4() {
  const variant1 = {
    initial: {
      x: 35,
      y: 5,
      scale: 0.8,
      rotate: -3,
      zIndex: 1,
      transition: {
        delay: 0.05,
        duration: 0.1,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
    whileHover: {
      x: 0,
      y: 0,
      scale: 1,
      rotate: 0,
      boxShadow:
        "rgba(39,245,76,0.15) 10px 20px 70px -20px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
      transition: {
        delay: 0.05,
        duration: 0.1,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
  };
  const variant2 = {
    initial: {
      scale: 1.1,
      zIndex: 2,
      transition: {
        delay: 0.05,
        duration: 0.1,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
    whileHover: {
      scale: 1,
      boxShadow:
        "rgba(39,127,245,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
      transition: {
        delay: 0.05,
        duration: 0.1,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
  };
  const variant3 = {
    initial: {
      x: -35,
      y: 5,
      scale: 0.8,
      rotate: 3,
      zIndex: 1,
      transition: {
        delay: 0.05,
        duration: 0.1,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
    whileHover: {
      x: 0,
      y: 0,
      scale: 1,
      rotate: 0,
      boxShadow:
        "rgba(245,40,145,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px",
      transition: {
        delay: 0.05,
        duration: 0.1,
        ease: cubicBezier(0.22, 1, 0.36, 1),
      },
    },
  };

  const containerVariants = {
    initial: {},
    whileHover: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div className="relative pb-[180px] h-full w-full transform-gpu rounded-lg bg-white dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)]">
      <motion.div
        variants={containerVariants}
        initial="initial"
        whileHover="whileHover"
        className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
      >
        <div className="flex h-full w-full items-center justify-center rounded-t-xl bg-transparent p-10">
          <motion.div className="flex md:h-[230px] w-full items-center justify-between gap-x-4">
            <motion.div
              variants={variant1}
              className="z-[3] flex h-fit w-full  flex-col items-center justify-between gap-x-2 gap-y-2 rounded-md border border-neutral-400/20 bg-white p-5 px-2.5 transition-all duration-100 ease-linear dark:border-neutral-800 dark:bg-neutral-900"
            >
              <div className="h-8 w-8 rounded-full bg-pink-300">
                <img
                  className="h-full w-full rounded-full object-cover"
                  src="https://avatar.vercel.sh/jack"
                  alt="jack"
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-700/50"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-400/80"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-300/80"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-600/80"></div>
              </div>
            </motion.div>
            <motion.div
              variants={variant2}
              className="z-[3] flex h-fit w-full flex-col items-center justify-between gap-x-2 gap-y-2 rounded-md border border-neutral-400/20 bg-white p-5 px-2.5 transition-all duration-100 ease-linear dark:border-neutral-800 dark:bg-neutral-900"
            >
              <div className="h-8 w-8 rounded-full bg-pink-300">
                <img
                  className="h-full w-full rounded-full object-cover"
                  src="https://avatar.vercel.sh/christina"
                  alt="christina"
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-700/50"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-400/80"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-300/80"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-600/80"></div>
              </div>
            </motion.div>
            <motion.div
              variants={variant3}
              className="z-[3] flex h-fit w-full flex-col items-center justify-between gap-x-2 gap-y-2 rounded-md border border-neutral-400/20 bg-white p-5 px-2.5 transition-all duration-100 ease-linear dark:border-neutral-800 dark:bg-neutral-900"
            >
              <div className="h-8 w-8 rounded-full bg-pink-300">
                <img
                  className="h-full w-full rounded-full object-cover"
                  src="https://avatar.vercel.sh/joshua"
                  alt="joshua"
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-700/50"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-400/80"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-300/80"></div>
                <div className="h-2 w-6 md:w-10 rounded-full bg-neutral-600/80"></div>
              </div>
            </motion.div>
          </motion.div>
        </div>

      </motion.div>
    </div>
  );
}
const SimpleGraph = () => {
    const fgRef = useRef(null);
    const [graphData, setGraphData] = useState({
        nodes: [
            { id: 'Landing', label: 'Landing', hours: 30 },
            { id: 'Signup', label: 'Signup', hours: 8 },
            { id: 'Signin', label: 'Signin', hours: 9 },
            { id: 'Home', label: 'Home', hours: 20 },
            { id: 'Settings', label: 'Settings', hours: 10 },
            { id: 'Feed', label: 'Feed', hours: 40 },
            { id: 'Profile', label: 'Profile', hours: 15 },
        ],
        links: [
            { source: 'Landing', target: 'Home' },
            { source: 'Home', target: 'Settings' },
            { source: 'Home', target: 'Feed' },
            { source: 'Feed', target: 'Profile' },
            { source: 'Landing', target: 'Signup' },
            { source: 'Landing', target: 'Signin' },
            { source: 'Signup', target: 'Signin' }
            ]
    });

    useEffect(() => {
        if (!fgRef.current) return;
        fgRef.current.d3Force('link').distance(link => 30); // Adjusted distance for bounciness
    }, [graphData]);

    return (
        <ForceGraph2D
            ref={fgRef}
            graphData={graphData}
            width={400}
            height={400}
            
            nodeRelSize={8} // Adjusted node size for visibility
            nodeId="id"
            linkColor={link => {
                return 'white';
            }}
            enableZoomInteraction={false}
            linkCurvature="curvature"
            linkWidth={link => 1} // Consistent link width
            d3VelocityDecay={0.1} // Adjusted for bounciness
            cooldownTicks={100} // Shorter cooldown for quicker layout stabilization
            linkDirectionalParticles={4}
            linkDirectionalParticleWidth={link => 1.5}
            onEngineStop={() => {
                fgRef.current.zoomToFit(500, 40);
            }}
            autoPauseRedraw={false}   

            nodeCanvasObject={(node, ctx, globalScale) => {
              try {
                let hours = node.hours || 0;
                    let NODE_R = Math.max(4, (hours / 40) * 50);
                    if (NODE_R > 10) {
                        NODE_R = 10;
                    }

                    // Adjusted color generation
                    const baseColor = '#888888'; // Adjust as needed
                    let fillColor =
                        hours > 0
                            ? `hsl(${
                                  (Math.pow(hours % 14, 2) * 10) % 1000
                              }, 60%, 60%)`
                            : baseColor;

                               // Draw the smaller circle for the node itself on top of the larger circle
                    const nodeCircle = new Path2D();
                    nodeCircle.arc(
                        node.x,
                        node.y,
                        NODE_R,
                        0,
                        2 * Math.PI,
                        false
                    );
                    ctx.fillStyle = fillColor;
                    ctx.fill(nodeCircle);
                    let label = node.label;
                    label = label.length > 15
                            ? label.substring(0, 15) + '...'
                            : label;
                    const fontSize = 10 / globalScale;
                    ctx.font = `${fontSize}px Sans-Serif`;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = 'white';
                    ctx.fillText(label, node.x, node.y - NODE_R - fontSize);

                } catch (err) {
                    console.log(err);
                }
            }}
        />
    );
};

export interface TestimonialCardProps {
  name: string;
  role: string;
  description: React.ReactNode;
  className?: string;
  [key: string]: any;
}

export const TestimonialCard = ({
  description,
  name,
 
  role,
  className,
  ...props // Capture the rest of the props
}: TestimonialCardProps) => (
  <div
    className={cn(
      "flex w-72 shrink-0 cursor-pointer snap-center snap-always flex-col justify-between rounded-xl p-4 shadow-xl shadow-black/[0.1] lg:min-w-92",
      // light styles
      "bg-transparent",
      // dark styles
      "dark:bg-transparent",
      className,
    )}
    {...props} // Spread the rest of the props here
  >
    <div className="select-none font-normal text-neutral-700 dark:text-neutral-400">
      {description}
    </div>
 
    <div className="select-none">
      <div className="flex flex-row py-1">
        <StarFilledIcon className="size-4 text-yellow-500" />
        <StarFilledIcon className="size-4 text-yellow-500" />
        <StarFilledIcon className="size-4 text-yellow-500" />
        <StarFilledIcon className="size-4 text-yellow-500" />
        <StarFilledIcon className="size-4 text-yellow-500" />
      </div>
      <p className="font-medium text-neutral-500">{name}</p>
      <p className="text-sm font-normal text-neutral-400">{role}</p>
    </div>
  </div>
);

export function FeaturesSection() {
  const fgRef = useRef(null)

    const features = ({fgRef}) => {
    return( [
      {
        Icon: ChatBubbleIcon,
        name: "Guided project discovery",
        description: "Our agent guides you through the discovery phase of your product, asking insightful questions to get you thinking.",
        href: "/",
        cta: "Learn more",
        className: "col-span-3 lg:col-span-1",
        background: (
          <div className="absolute flex justify-center border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_40%)] group-hover:scale-105">
           <FeatureCard8 />
         </div>
        ),
      },
      {
        Icon: MixerHorizontalIcon,
        name: "Fine-tuned, AI planning agent",
        description: "Our AI pipeline is designed to plan complex software projects with a data-driven approach to timeline forecasting.",
        className: "col-span-3 lg:col-span-1",
        href: "/",
        cta: "Learn more",
        background: (
          <div className="absolute h-full w-full border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105">
          <AnimatedBeamMultipleInputDemo />
          </div>
        ),
      },  
      {
        Icon: Share2Icon,
        name: "Architecture Generation",
        description: "Instantly visualize your high-level architecture, as pre.dev can map any new or existing products.",
        href: "/",
        cta: "Learn more",
        className: "col-span-3 lg:col-span-1",
        background: (
          <div className="absolute h-[200] w-[200] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_70%)] group-hover:scale-105">
                <SimpleGraph
                />
          </div>
        ),
      },
      {
        Icon: CodeBracketSquareIcon,
        name: "UI/UX Accelerator CodeGen",
        description: "Once your architecture is mapped out, our AI can start building the prototype frontend code in parallel with React.  Chat with the AI to get suggestions and automatically improve the code.",
        href: "/",
        cta: "Learn more",
        className: "col-span-3 lg:col-span-2",
        background: (
          <div className="absolute h-full w-full border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_70%)] group-hover:scale-105">
          <FeatureCard4 />
          </div>
        ),
      },
      {
        Icon: BoltIcon,
        name: "PM Documentation",
        description: "Efficiently manage multiple versions of product and feature docs by augmenting your intelligence with AI. ",
        className: "col-span-3 lg:col-span-1",
        href: "/",
        cta: "Learn more",
        background: (
        
            <Marquee
                  pauseOnHover
                  className="absolute top-20 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_0%,#000_40%)] "
                >
                  {files.map((f, idx) => (
                    <figure
                      key={idx}
                      className={cn(
                        "relative w-32 cursor-pointer overflow-hidden rounded-xl border p-4",
                        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
                        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
                        "transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none",
                      )}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-col">
                          <figcaption className="text-sm font-medium dark:text-white ">
                            {f.name}
                          </figcaption>
                        </div>
                      </div>
                      <blockquote className="mt-2 text-xs text-white">{f.body}</blockquote>
                    </figure>
                  ))}
                </Marquee>            

       
         
        ),
      },
      // {
      //   Icon: () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      //           <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
      //         </svg>),
      //   name: "Enterprise solution",
      //   description: "Tailor the pre.dev experience to your company's knowledge.",
      //   className: "col-span-3 lg:col-span-1",
      //   href: "/",
      //   cta: "Learn more",
      //   background: (
        
      //    <div className="dark border-[2px] border-green-500 [mask-image:linear-gradient(to_top,transparent_0%,#000_40%)] relative flex h-[300px] w-full items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105">
        
      //      {
      //        TestimonialCard({
      //         name: "Bryce Kaiser",
      //         role: "CTO City Innovations",
      //         description: (
      //           <p>
      //             "I was quoted 120 hours to scope out a million dollar project and instead
      //             <Highlight>
      //               pre.dev gave it to me instantly.
      //             </Highlight>
      //             "
      //           </p>
      //         ),
      //       })
      //    }
     
      //    </div>
      //   ),
      // }
      // , {
      //   Icon: () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      //           <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
      //         </svg>),
      //   name: "Enterprise integrations for product teams",
      //   description: "Integrate pre.dev into your existing PM workflow.",
      //   className: "col-span-3 lg:col-span-2",
      //   href: "/",
      //   cta: "Learn more",
      //   background: (
      //     <div className="dark border-[2px] border-green-500 [mask-image:linear-gradient(to_top,transparent_0%,#000_40%)] relative flex h-[300px] w-full items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105">
      //         <div className="h-20 mb-1 px-[3px] py-[5px] rounded-full bg-black">
      //           <img
      //             className="h-full rounded-full"
      //             src="/predev.png"
      //             alt="predev"
      //           />
      //         </div>
       
      //       {/* Inner Circles */}
      //       <OrbitingCircles
      //         className="h-[22px] w-[22px] border-none bg-transparent"
      //         duration={20}
      //         delay={20}
      //         radius={80}
      //       >
      //         <Icons.whatsapp />
      //       </OrbitingCircles>
      //       <OrbitingCircles
      //         className="h-[30px] w-[30px] border-none bg-transparent"
      //         duration={20}
      //         delay={10}
      //         radius={80}
      //       >
      //         <Icons.notion />
      //       </OrbitingCircles>
     
           
      //       <OrbitingCircles
      //         className="h-[45px] w-[45px] border-none bg-transparent"
      //         radius={160}
      //         duration={20}
      //         reverse
      //       >
      //         <Icons.googleDrive />
      //       </OrbitingCircles>

      //       <OrbitingCircles
      //         className="h-[45px] w-[50px] border-none"
      //         radius={160}
      //         duration={20}
      //         delay={20}
      //         reverse
      //       >
      //         <Icons.gitHub />
      //       </OrbitingCircles>
      //     </div>
      //   ),
      // },
    ])
    };

  const [feats, setFeats] = useState(features({fgRef}))

  return (
    <div id="features" className="py-20 px-8 dark">
    <BentoGrid>
      {feats.map((feature, idx) => (
        <BentoCard key={idx} {...feature} />
      ))}
    </BentoGrid>
    <CallToAction2 />
    </div>
  );
}
