export const loadingStatus = `
chat
last_chat
architecture
last_architecture
recommendedTechStack
recommendedJobRoles
last_recommendedTechStack
last_recommendedJobRoles
sow_mvp
last_sow_mvp
sow_custom
last_sow_custom
jira_hackathon
last_jira_hackathon
jira_mvp
last_jira_mvp
jira_full
last_jira_full
uiview
last_uiview
chatui
last_chatui
`;

export const enterprise_subscription_link = `
    url
    tier
    price
    priceId
    productId
    duration
    trial
`;

export const jira = `
    percentage
    version
    projectURL
`;

export const message = `
_id
nodeId
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
userTitle
tagged
`;

export const recommended_tech_stack = `
    name
    description
    useFor
    link
    reason
    helpfulLinks {
        url
        description
    }
    alternatives {
        name
        link
        description
    }
`;

export const recommended_job_roles = `
    title
    responsibilities
    qualifications
    requiredSkills
`;

export const graph = `
nodes {
    _id
    id
    label
    hours
    description
}
edges {
    _id
    source
    target
    description
}
side
type
version
`;

export const existingProjectContext = `
    _id
    fileKeyS3
    type
    title
    text
    image_url
    summary
`;

export const project = `
_id
name
type
subType
created
userEmail
firstGeneration
uniqueId
hasExistingContext
skip
fileKeyS3
solo
meetingRequested
latestTechStackVersion
latestJobRolesVersion
messageCount
isSubscribed
chatHistory {
    ${message}
}
existingProjectContexts {
    ${existingProjectContext}
}
sow_mvp_up_to_date
sow_custom_up_to_date
uiview_up_to_date
recommendedTechStack {
    ${recommended_tech_stack}
}
recommendedJobRoles {
    ${recommended_job_roles}
}
allowSoWGeneration{
    hackathon
    mvp
    full
    custom
}
graphs {
    ${graph}
}
jobRolesVersions
techStackVersions 
graphVersions
uiviewVersions
sowVersions {
    hackathon
    mvp
    full
    custom
}
`;

export const enterprise_advanced_config = `
    apiKey
    calendlyLink
    activateMeetings
    logo
    chatAgentLogo
    chatAgentName
    widgetCompletionRedirectURL
    onlyAllowHackathon
    projectCreationTitle
    initialChatMessage
    clientEmail
    disableNewProjectEmails
    disableViewProjectPlanWidget
    disableHoursInShareLink
    disablePoweredBy
    disableShareLink
    onlyNewProject
    scheduleMeetingButtonText
    scheduleMeetingHelperText
`;

export const knowledgeBaseFile = `
    _id
    name
    fileKeyS3
    created
    activated
    activatedAt
    loading
    loadingAt
    type
`;

export const enterpriseUsers = `
    enterpriseUsers {
        userEmail
        title
        role
        skills {
            title
            level
        }
        hourlyRate
        created
    }
`;

export const defaultTechStackItem = `
    _id
    name
    initialName
    link
    description
`;

export const sow = `
    projectId
    created
    title
    version
    executiveSummary
    goalsAndObjectives {
        description
        objectives
    }
    successMetrics {
        name
        description
    }
    userStories {
        userType
        action
        benefit
    }
    coreFunctionalities {
        name
        description
        priority
    }
    stakeholders {
        title
        description
    }
    techStack {
        name
        useFor
    }
    milestones {
        description
        stories {
            description
            subTasks {
                description
                complexityEstimate
                hours
                roles {
                    name
                    shortHand
                }
            }
        }
    }
    latestTechStackVersion
    latestGraphVersion
    lastUpdatedChatIndex
    enterpriseOrganizationId
    type
    completed
    content
    internal_version
    userContent
`;

export const thumbsRating = `
    id
    rating
    metadata
`;

export const enterprise = `
	_id
	name
	isAdmin
	created
	numUsersLeft
	apiKey
	apiDomains {
		_id
		url
		index
		created
	}
	${enterpriseUsers}
	subscriptionLinks {
		${enterprise_subscription_link}
	}
    defaultTechStackItems {
        ${defaultTechStackItem}
    }
	subscriptionStatus {
		status
		tier
		price
		created_at
		current_period_end
		duration
		current_period_start
		cancel_at_period_end
	}
	${enterprise_advanced_config}
    knowledgeBaseFiles {
        ${knowledgeBaseFile}
    }
`;
