'use client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../Components/Spinner';
import moment from 'moment';
import { HiUserCircle } from 'react-icons/hi';
import { TypeAnimation } from 'react-type-animation';
import { useProject } from '../../../context-providers/Project';
import { getSignedDownloadURL } from '../../../redux/actions/solo/getSignedDownloadURL';
import { selectEnterpriseOrganization } from '../../../redux/reducers/enterprise/enterpriseOrganization';
import { Markdown } from '../../Components/Markdown/other';
import ThumbsUpDown from '../../Components/ThumbsUpDown';
import { TagColleague } from './TagColleague';

const containsMarkdown = text => {
    const markdownPatterns = [
        /(^|\s)(#{1,6}\s)/, // Headers
        /(\*\*|__)(.*?)\1/, // Bold text
        /(\*|_)(.*?)\1/, // Italic text
        /(```[\s\S]*```)/, // Code block
        /(`[\s\S]*`)/, // Inline code
        /(\[.*?\]\(.*?\))/, // Links
        /(!\[.*?\]\(.*?\))/, // Images
        /(\*|-|\+|\d+\.)\s+/ // Lists
    ];

    return markdownPatterns.some(pattern => pattern.test(text));
};

export const ChatItem = ({ message, i, length, isSolo }) => {
    let background =
        !message.isLoading && message.role === 'user'
            ? 'flex px-2 py-6 sm:px-4'
            : 'flex rounded-xl bg-slate-50 px-2 py-2 dark:bg-slate-900 sm:px-4';
    let { project, projectId } = useProject();
    const dispatch = useDispatch();
    const [image_url, setImageUrl] = useState(null);
    const [showThumbsUpDown, setShowThumbsUpDown] = useState(false);

    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    const handleDownload = async e => {
        e.preventDefault();

        const signedUrl = await dispatch(
            getSignedDownloadURL(message.fileKeyS3)
        );

        // Redirect the user to the signed URL, this will start the download
        window.open(signedUrl, '_blank');
    };

    useEffect(() => {
        let asyncFunc = async () => {
            if (message.image_url && message.fileKeyS3) {
                const signedUrl = await dispatch(
                    getSignedDownloadURL(message.fileKeyS3)
                );
                setImageUrl(signedUrl);
            }
        };
        asyncFunc();
    }, [message.fileKeyS3, message.image_url, dispatch]);

    const thumbsId = `${projectId}-message-${message.index}`;

    return (
        <div
            key={i}
            className={`${background} ml-4 mr-4 w-fit`}
            onMouseEnter={() =>
                message.role === 'assistant' && setShowThumbsUpDown(true)
            }
            onMouseLeave={() => setShowThumbsUpDown(false)}
        >
            <div className="flex h-8 rounded-full relative group">
                {chatIcon(message, enterpriseOrganization)}
                <div className="absolute min-w-[150px] bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                    {message.role === 'assistant'
                        ? 'pre.dev'
                        : message.username
                          ? message.username
                          : message.userEmail}
                    <br />
                    {moment(message.datetime).format('MM/DD/YYYY, HH:mm:ss')}
                </div>
            </div>
            <div className="flex flex-col justify-center max-w-3xl rounded-xl overflow-auto">
                {message.fileKeyS3 && !image_url && (
                    <a
                        onClick={handleDownload}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cursor-pointer text-blue-500 hover:underline"
                    >
                        {message.fileKeyS3.substring(36)}
                    </a>
                )}
                <p className={`break-words`}>{text(message, length)}</p>
                {image_url && (
                    <div className="mt-2 w-full">
                        <img
                            src={image_url}
                            alt="Message Attachment"
                            className="max-w-full h-auto"
                        />
                    </div>
                )}
                {message.tagged && tagged(message)}
                {!message.isLoading && message.role === 'assistant' && (
                    <div className="flex flex-row items-center mt-2 gap-x-2">
                        {message._id && (
                            <TagColleague
                                message={message}
                                messageId={message._id}
                            />
                        )}
                        <ThumbsUpDown id={thumbsId} metadata={['message']} />
                    </div>
                )}
            </div>
        </div>
    );
};

const tagged = message => {
    return (
        <div className="flex flex-row text-xs text-gray-600 mt-4 gap-x-2">
            {message.tagged.split(',').map(email => (
                <span key={email} className="bg-blue-100 py-1 px-3 rounded-xl">
                    {email}
                </span>
            ))}
        </div>
    );
};

const date = message => {
    let formattedDate = moment(message.datetime).format('MM/DD/YYYY, HH:mm:ss');
    return (
        <div className="text-xs text-gray-500 dark:text-gray-400">
            {formattedDate}
        </div>
    );
};

const text = (message, length) => {
    let content = message.content;
    if (content) {
        content = message.content
            .replace(/\【\d+†source】/, '')
            .replace(/\【\d+†source】/g, '');
    }
    return message.isLoading && content === '' ? ( // if the message is loading, show the spinner
        <div className="flex flex-row items-center">
            <Spinner w={'4'} />
            <span className="ml-2 text-xs">
                Response may take a few moments...
            </span>
        </div>
    ) : length === 1 ? ( // if this is the first message, show the typing animation
        typeAnimation(message)
    ) : containsMarkdown(content) ? (
        <div className="overflow-auto max-w-full">
            <Markdown
                content={content}
                id={Math.random().toString(36).substring(7)}
            />
        </div>
    ) : (
        <> {content} </>
    );
};

const typeAnimation = message => {
    return (
        <TypeAnimation
            speed={99}
            cursor={true}
            sequence={[
                message.content,
                () => {
                    //get the div id="chatScroll" and scroll to the bottom
                    try {
                        let chatScroll = document.getElementById('chatScroll');
                        if (chatScroll)
                            chatScroll.scrollTop = chatScroll.scrollHeight;
                    } catch (error) {
                        console.log(error);
                    }
                }
            ]}
        />
    );
};

const iconMapping = enterpriseOrganization => {
    return {
        user: (
            <HiUserCircle className="mr-1 w-8 h-8 rounded-full text-gray-500 p-[2px]" />
        ),
        assistant: (
            <img
                className="mr-2 w-7 h-7 rounded-full bg-black p-[2px]"
                src={
                    enterpriseOrganization?.chatAgentLogo ||
                    '/predevlogosquare.png'
                }
                alt={'bot'}
            />
        )
    };
};

const chatIcon = (message, enterpriseOrganization) => {
    return (
        <div className="mr-2 flex h-8 w-8 rounded-full sm:mr-4">
            {
                iconMapping(enterpriseOrganization)[
                    message.isLoading ? 'assistant' : message.role
                ]
            }
        </div>
    );
};
