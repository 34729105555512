import { useDispatch } from 'react-redux';
import { client } from '../../../../../../api/client';
import {
    INITIATE_SUGGESTION,
    SUGGESTION_SUBSCRIPTION
} from '../../../../../../api/graphQL';
import { waitingForSuggestion } from '../../../../../../redux/reducers/generic/chatHistory';
import { Spinner } from '../../../../../Components/Spinner';
import { useSubscription } from '@apollo/client';
import { useProject } from '../../../../../../context-providers/Project';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

export const Suggest = ({ textareaRef, setAnswer, answer }) => {
    let { chatHistory, projectId } = useProject();
    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;

    let [randId, setRandId] = useState('');

    const dispatch = useDispatch();

    useSubscription(SUGGESTION_SUBSCRIPTION, {
        variables: { projectId, randId },
        shouldResubscribe: true,
        onData: data => {
            try {
                const { suggestion, completed } =
                    data.data.data.suggestionStream;
                setAnswer(suggestion);
                textareaRef.current.scrollTop =
                    textareaRef.current.scrollHeight;

                if (completed) {
                    document.getElementById('answer').focus();
                    dispatch(waitingForSuggestion(false));
                }
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
        // client: clientWithAPIKey(apiKey),
    });

    const handleSuggest = async () => {
        if (waiting) return;
        dispatch(waitingForSuggestion(true));
        try {
            setAnswer('');
            let randId = uuid();
            setRandId(randId);
            let { suggestion } = await client.mutate({
                mutation: INITIATE_SUGGESTION,
                variables: { projectId, randId }
            });
            if (answer !== suggestion) setAnswer(suggestion);
            dispatch(waitingForSuggestion(false));
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
        return;
    };

    return (
        <div className="flex flex-row justify-between my-2 items-center">
            <button
                onClick={!waiting ? handleSuggest : null}
                className={`text-xs shrink-0 text-left ${
                    waiting
                        ? 'text-gray-500'
                        : 'text-green-500 hover:text-green-600'
                } text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-xs px-4 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700`}
            >
                {chatHistory.waitingForSuggestion ? (
                    <span className="flex items-center">
                        <Spinner w="4" mr="2" />
                        Answering...
                    </span>
                ) : (
                    'Answer for me'
                )}
            </button>
            <p className="text-gray-500 text-2xs ml-2">
                Don't know how to answer? Try clicking <b>Answer for me</b>.
            </p>
        </div>
    );
};
