export let basicPlan = {
    id: 'basic',
    name: 'Basic',
    description:
        'A basic free plan for individuals or teams to try the platform',
    features: [
        'No credit card required',
        '2 project limit',
        '10 responses max per project'
    ],
    missingFeatures: [
        'Unlimited Projects',
        'Unlimited Documentation Generations',
        'Unlimited Roadmap Generations',
        'UI/UX Generation'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

let soloPlusPlan = {
    id: 'SoloPlus',
    name: 'Solo Plus',
    description: 'An intro plan for individuals',
    features: [
        'Unlimited Projects',
        'Unlimited Architecture Generations',
        'Unlimited Documentation Generations',
        'Unlimited Roadmap Generations'
    ],
    monthlyPrice: 1400,
    yearlyPrice: 14000,
    textColor: 'text-blue-400'
};

export let soloPremiumPlan = {
    id: 'SoloPremium',
    name: 'Solo Premium',
    description: 'The ultimate plan for individuals',
    features: [
        'Everything in Solo Plus +',
        'Jira Integration and Sync',
        'Wireframe Generations (Coming Soon)'
    ],
    monthlyPrice: 4900,
    yearlyPrice: 49900,
    textColor: 'text-blue-500'
};

let enterprisePremiumPlan = {
    id: 'EnterprisePremium',
    name: 'Enterprise Premium',
    description: 'An enterprise plan made for product teams',
    features: [
        'Everything in solo premium +',
        'Advanced collaboration features',
        'Configurable knowledge base',
        'Data security and compliance'
    ],
    addons: ['Project Scoping Widget', 'Auto Assign Team Members to Tasks'],
    monthlyPrice: 9900,
    yearlyPrice: 99900,
    textColor: 'text-green-500',
    perUser: true
};

export const prices = ({ disabledBasic, disabledSolo, disableEnterprrise }) => {
    let plans = [];

    if (!disabledBasic) {
        plans.push(basicPlan);
    }

    if (!disabledSolo) {
        plans.push(soloPlusPlan, soloPremiumPlan);
    }

    if (!disableEnterprrise) {
        plans.push(enterprisePremiumPlan);
    }

    return plans;
};
