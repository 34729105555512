import { GENERATE_SOW } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { getSowStatuses } from './getSowStatuses';
import {
    addToSowVersions,
    setSowVersion,
    setSowVersionLoading
} from '../../reducers/generic/project';
import { setSow } from '../../reducers/generic/sow';

export const generateSow = (projectId, type) => async (dispatch, getState) => {
    try {
        dispatch(
            setSow({
                type
            })
        );
        dispatch(setSowVersionLoading({ type, loading: true }));

        const { data } = await client.mutate({
            mutation: GENERATE_SOW,
            variables: { projectId, type },
            fetchPolicy: 'network-only'
        });

        if (data?.generateSow) {
            dispatch(setSow(data.generateSow));
            dispatch(
                setSowVersion({ type, version: data.generateSow.version })
            );
            dispatch(
                addToSowVersions({ type, version: data.generateSow.version })
            );
        }

        dispatch(getSowStatuses(projectId));
        dispatch(setSowVersionLoading({ type, loading: false }));
    } catch (error) {
        console.log({ error });
        if (error.message.includes(`API is temporarily unavailable.`)) {
            alert(`API is temporarily unavailable. Please try again later.`);
        }
        dispatch(setSowVersionLoading({ type, loading: false }));
    }
};
