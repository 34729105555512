import { GET_UIVIEWS } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { setUIView, gettingUIView } from '../../reducers/generic/projectArchitecture';
import { setChatHistory } from '../../reducers/generic/uiview';

export const getUIViews = ({projectId, nodeId}) => async dispatch => {
    try {

        dispatch(gettingUIView(true));
        const { data } = await client.query({
            query: GET_UIVIEWS,
            variables: { projectId: projectId, nodeId: nodeId},
            fetchPolicy: 'network-only'
        });
        dispatch(gettingUIView(false));
        if (data?.getUIViews) {
            console.log(data.getUIViews)

            dispatch(setUIView(data.getUIViews))
            // if(data?.getUIView.chatHistory) {
            //    dispatch(setChatHistory(data.getUIView.chatHistory));
            // }
        }
    } catch (error) {
        console.log({ error });
        if (error.message.includes(`API is temporarily unavailable.`)) {
            alert(`API is temporarily unavailable. Please try again later.`);
        }
    }
};
