import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const options = [{ value: 'moonbeam', label: 'Moonbeam' }];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ProjectTypes({
    selectedProjectType,
    setSelectedProjectType,
    projectTypes,
    subType,
    setSubType
}) {
    const changeProjectType = e => {
        setSubType(null);
        setSelectedProjectType(e);
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <p className="font-light text-gray-500 dark:text-gray-400 text-sm">
                Select Project Type:
            </p>
            <RadioGroup
                value={selectedProjectType}
                onChange={changeProjectType}
            >
                <div className="mt-2 grid grid-flow-col items-center justify-center gap-x-4">
                    {projectTypes.map(projectType => (
                        <>
                            <RadioGroup.Option
                                key={projectType.id}
                                value={projectType}
                                data-tooltip-id={projectType.id}
                                onClick={() => {
                                    changeProjectType(projectType);
                                }}
                                className={({ checked }) =>
                                    classNames(
                                        checked
                                            ? 'border-blue-600 ring-2 ring-blue-600'
                                            : 'border-gray-300',
                                        'relative flex cursor-pointer rounded-lg border bg-white py-2 px-2 gap-x-1 shadow-sm focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <>
                                        <span className="flex flex-1">
                                            <RadioGroup.Label
                                                as="span"
                                                className="text-sm font-medium text-gray-900"
                                            >
                                                {projectType.title}
                                            </RadioGroup.Label>
                                        </span>
                                        <CheckCircleIcon
                                            className={classNames(
                                                !checked ? 'invisible' : '',
                                                'h-5 w-5 text-blue-600'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        </>
                    ))}
                </div>

                <>
                    {projectTypes.map(projectType => (
                        <ReactTooltip
                            key={projectType.id}
                            id={projectType.id}
                            className="max-w-[200px]"
                            place="top"
                            content={projectType.description}
                        />
                    ))}
                </>
            </RadioGroup>
            {selectedProjectType.type === 'web3' && (
                <div className="mt-4 flex flex-row items-center gap-x-2">
                    <div className="text-sm text-gray-500">Optional:</div>

                    <RadioGroup
                        value={subType}
                        onChange={setSubType}
                        className="flex flex-row gap-x-4"
                    >
                        {options.map(projectType => (
                            <RadioGroup.Option
                                key={projectType.value}
                                value={projectType.value}
                                className={({ active }) =>
                                    classNames(
                                        active
                                            ? 'border-blue-600 ring-2 ring-blue-600'
                                            : 'border-gray-300',
                                        'relative flex cursor-pointer rounded-lg border bg-white py-2 px-4 shadow-sm focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <>
                                        <span className="flex flex-1">
                                            <span className="flex flex-col">
                                                <RadioGroup.Label
                                                    as="span"
                                                    className="mr-1 block flex flex-row gap-x-2 text-sm font-medium text-gray-900"
                                                >
                                                    <img
                                                        src="https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png"
                                                        className="h-5 w-5"
                                                    />
                                                    {projectType.label}
                                                </RadioGroup.Label>
                                            </span>
                                        </span>
                                        <CheckCircleIcon
                                            className={classNames(
                                                !checked ? 'invisible' : '',
                                                'h-5 w-5 text-blue-600'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked
                                                    ? 'border-blue-600'
                                                    : 'border-transparent',
                                                'pointer-events-none absolute -inset-px rounded-lg'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div>
            )}
        </div>
    );
}
