import { UserGroupIcon, TagIcon, CurrencyDollarIcon, UserIcon } from '@heroicons/react/24/solid';
import { HiUserGroup } from 'react-icons/hi';

export const user_management = {
    step_title: 'User Management and Collaboration',
    icon: <HiUserGroup className="h-12 w-12 text-white" aria-hidden="true"/>,
    description:
        'The User Management and Collaboration interface allows enterprise users to configure team roles, enable multi-user collaboration, manage project interactions, and set hourly rates. This ensures that team members can work together efficiently, tag each other on tasks, streamline project communication, and maintain accurate budget tracking.',
    features: [
        {
            feature_title: 'Role Assignment and Collaboration',
            description:
                'Assign roles to team members and enable multi-user collaboration on projects.',
            icon: <UserGroupIcon className="h-12 text-white" />
        }, 
        {
            feature_title: 'Hourly Rate Management and Skills-based Task Allocation',
            description:
                'Set hourly rates for team members, link to project budgets, and optimize task allocation based on skills and rates for efficient, cost-effective collaboration.',
            icon: <CurrencyDollarIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Project Tagging',
            description:
                'Allow team members to tag each other on specific tasks or project elements, ensuring clear communication and accountability throughout the project lifecycle.',
            icon: <TagIcon className="h-12 text-white" />
        },
    ],
    user_story:
        'Sarah adds her developers and designers to the project, setting their roles, hourly rates, and enabling collaboration features. She tags the UI/UX designer on a new feature discussion, and assigns a pair programming task to two React developers, ensuring accurate budget tracking for each team member\'s contribution.',
    screenshots: [
        {
            title: 'User Management and Collaboration Interface',
            image_url: '/user_management_1.png'
        },
        {
            title: 'User Management and Collaboration Interface',
            image_url: '/user_management_2.png'
        }
    ],
    enterprise_exclusive: true
};