import { capitalize } from '../../../constants';
import { useProject } from '../../../context-providers/Project';
import React, { useState, useEffect } from 'react';
import { GraphVersions } from './GraphVersions';
import ThumbsUpDown from '../../Components/ThumbsUpDown';
import { selectGraphVersion } from '../../../redux/reducers/generic/projectArchitecture';
import { useSelector } from 'react-redux';
import { TableCellsIcon } from '@heroicons/react/24/outline';
import { ArchitectureTable } from '../ArchitectureTable';
import Modal from '../../Components/Modal';

export default function TabBar() {
    let {
        project,
        graphType,
        setSearchParams,
        allSearchParams,
        projectArchitecture
    } = useProject();
    const [tabs, setTabs] = useState([]);
    const [isTableModalOpen, setIsTableModalOpen] = useState(false);

    useEffect(() => {
        if (project) {
            let tabs = projectArchitecture.graphs.map(graph => ({
                name: capitalize(graph.side),
                param: graph.side
            }));
            setTabs(tabs);
        }
    }, [project]);

    const graphVersion = useSelector(selectGraphVersion);

    const thumbsId = `${project?._id.toString()}-${graphType}-graph-${graphVersion}`;

    const openTableModal = () => {
        setIsTableModalOpen(true);
    };

    const closeTableModal = () => {
        setIsTableModalOpen(false);
    };

    return (
        <>
            <div
                id="tabBar"
                className={`backdrop-blur-lg p-4 rounded-lg flex flex-col items-center gap-y-2 mt-2 mb-2`}
            >
                <div className="flex flex-row justify-center items-center gap-x-4 items-center w-full min-w-[200px]">
                    <GraphVersions />
                    <button
                        onClick={openTableModal}
                        className="px-2 py-1 flex gap-x-2 items-center bg-gray-800 hover:bg-gray-600 rounded-md text-gray-400 hover:text-gray-300 transition-colors duration-300"
                    >
                        <TableCellsIcon className="h-5 w-5" />
                    </button>
                    <ThumbsUpDown
                        id={thumbsId}
                        metadata={[`${graphType}-graph`]}
                    />
                </div>
                <div className="items-center z-100 flex justify-center">
                    <div className="flex justify-center items-center border border-gray-400 rounded-xl px-2 h-10">
                        {tabs.map((tab, index) => (
                            <React.Fragment key={`tab-${index}`}>
                                <button
                                    onClick={() => {
                                        setSearchParams({
                                            ...allSearchParams,
                                            graphType: tab.param
                                        });
                                    }}
                                    className={`gap-x-2 inline-flex items-center justify-center text-sm font-bold py-1 ${
                                        tab.param == graphType
                                            ? 'text-gray-300 bg-gray-800'
                                            : 'text-gray-400 opacity-70 hover:bg-gray-700'
                                    } rounded-lg hover:opacity-100 transition-colors duration-300 min-w-[140px] px-2`}
                                >
                                    {tab.name}
                                </button>
                                {index < tabs.length - 1 && (
                                    <div className="border-r border-gray-400 mx-2 h-6"></div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <TableModal
                isTableModalOpen={isTableModalOpen}
                closeTableModal={closeTableModal}
            />
        </>
    );
}

export const TableModal = ({ isTableModalOpen, closeTableModal }) => {
    return (
        <Modal
            open={isTableModalOpen}
            onClose={closeTableModal}
            title="Architecture Table"
        >
            <ArchitectureTable />
        </Modal>
    );
};
