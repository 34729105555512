import { client } from '../../../api/client';
import { GET_SOLO_SUBSCRIPTION_LINKS } from '../../../api/graphQL/solo/query';

export const getSoloSubscriptionLinks = () => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: GET_SOLO_SUBSCRIPTION_LINKS
        });
        return data.getSoloSubscriptionLinks;
    } catch (error) {
        console.log({ error });
        console.log(JSON.stringify(error, null, 2));
    }
};
