import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../../context-providers/Project';
import { skipQuestions } from '../../../../redux/actions/enterprise_widget/skipQuestions';
import { chatEndingIndex, getChatEndingIndex } from '../../../../constants';
import { setWidgetState } from '../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { setShowRequestModal } from '../../../../redux/reducers/generic/project';
import { selectEnterpriseOrganization } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { useSelector } from 'react-redux';

export const SkipQuestions = ({ isWidget }) => {
    let { chatHistory, projectId, project } = useProject();
    let enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    const dispatch = useDispatch();
    const [isSkipping, setIsSkipping] = useState(false);

    let currentIndex = getChatEndingIndex(chatHistory.data);

    const handleSkipQuestions = async () => {
        if (
            window.confirm(
                'Are you sure you want to skip the initial questions?'
            )
        ) {
            setIsSkipping(true);
            await dispatch(skipQuestions(projectId));
            if (isWidget) {
                dispatch(setWidgetState('projectCompleted'));
                // if (enterpriseOrganization?.calendlyLink)
                //     dispatch(setShowRequestModal(true));
            }
        }
    };

    return (
        !project.skip &&
        currentIndex < chatEndingIndex && (
            <button
                onClick={handleSkipQuestions}
                className={`text-white bg-blue-700 border border-gray-300  hover:bg-blue-800 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-xs px-4 py-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ${
                    isSkipping ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isSkipping}
            >
                {isSkipping ? (
                    '...'
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
                        />
                    </svg>
                )}
            </button>
        )
    );
};
