import { useState } from 'react';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import Modal from '../../Components/Modal';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { Spinner } from '../../Components/Spinner';
import { useEnterpriseOrganizations } from '../../../context-providers/EnterpriseOrganizations';
import { transferProject } from '../../../redux/actions/enterprise/transferProject';

export const TransferModal = ({ project, open, setOpen }) => {
    const dispatch = useDispatch();

    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const [loading, setLoading] = useState(false);
    const [toEnterpriseOrganizationId, setToEnterpriseOrganizationId] =
        useState(
            enterpriseOrganizations.find(
                org => org._id !== enterpriseOrganization._id
            )?._id || ''
        );

    const handleTransferProject = async () => {
        if (!toEnterpriseOrganizationId) {
            alert('Please select an organization');
            return;
        }
        if (!project) return;

        setLoading(true);
        let result = await dispatch(
            transferProject({
                projectId: project?._id.toString(),
                fromEnterpriseOrganizationId: enterpriseOrganization?._id,
                toEnterpriseOrganizationId
            })
        );
        setLoading(false);
        if (result) {
            setOpen(false);
        }
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Transfer Project: {project?.name}
                </h2>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        Select an enterprise organization to transfer this
                        project to.
                    </p>
                </div>
                <div className="mt-5">
                    <select
                        id="colleague"
                        name="colleague"
                        value={toEnterpriseOrganizationId}
                        onChange={e =>
                            setToEnterpriseOrganizationId(e.target.value)
                        }
                        onSelect={e =>
                            setToEnterpriseOrganizationId(e.target.value)
                        }
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                        {enterpriseOrganizations
                            .filter(
                                org => org._id !== enterpriseOrganization?._id
                            )
                            .map(org => (
                                <option key={org._id} value={org._id}>
                                    {org.name}
                                </option>
                            ))}
                    </select>
                </div>

                <div className="mt-5">
                    <button
                        disabled={loading}
                        onClick={handleTransferProject}
                        className="inline-flex items-center gap-x-4 justify-center rounded-md border border-transparent bg-teal-700 py-2 px-4 text-sm font-medium text-white hover:bg-teal-800 focus:ring-4 focus:ring-gray-200 focus:outline-none"
                    >
                        Transfer Project
                        {loading && <Spinner w="4" color="white" />}
                    </button>
                </div>
            </>
        </Modal>
    );
};
