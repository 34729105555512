import { createSlice } from '@reduxjs/toolkit';
import { updateHourEstimates } from './update_hour_estimates';
import { set } from 'zod';

const initialState = {
    data: {
        type: 'mvp'
    },
    loadingSoW: {
        hackathon: null,
        mvp: null,
        full: null,
        custom: null
    },
    hideHours: true,
    editMode: false,
    skillLevel: 'junior',
    loadingHackathon: null,
    loadingMVP: null,
    loadingFull: null,
    loadingCustom: null,
    modal: false
};

export const sowSlice = createSlice({
    name: 'sow',
    initialState,
    reducers: {
        setSow: (state, action) => {
            let sow = JSON.parse(JSON.stringify(action.payload));
            if (sow?.milestones) {
                sow.og_milestones = JSON.parse(JSON.stringify(sow.milestones));
                sow.milestones = updateHourEstimates(
                    JSON.parse(JSON.stringify(sow.og_milestones)),
                    state.skillLevel
                );
            }
            state.data = sow;
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
        },
        setSkillLevel: (state, action) => {
            state.skillLevel = action.payload;
            if (state.data?.milestones) {
                state.data.milestones = updateHourEstimates(
                    JSON.parse(JSON.stringify(state.data.og_milestones)),
                    action.payload
                );
            }
        },
        setHideHours: (state, action) => {
            state.hideHours = action.payload;
        },
        waitingForSowHackathon: (state, action) => {
            state.loadingHackathon = action.payload;
        },
        waitingForSowMVP: (state, action) => {
            state.loadingMVP = action.payload;
        },
        waitingForSowFull: (state, action) => {
            state.loadingFull = action.payload;
        },
        waitingForSowCustom: (state, action) => {
            state.loadingCustom = action.payload;
        },
        setShowSowModal: (state, action) => {
            state.modal = action.payload;
        }
    }
});

export const {
    setEditMode,
    setHideHours,
    setSow,
    setShowSowModal,
    waitingForSowHackathon,
    waitingForSowMVP,
    waitingForSowFull,
    waitingForSowCustom,
    setSkillLevel
} = sowSlice.actions;

export const selectSow = state => state.sow.data;
export const selectShowSowModal = state => state.sow.modal;
export const selectLoadingSowHackathon = state => state.sow.loadingHackathon;
export const selectLoadingSowMVP = state => state.sow.loadingMVP;
export const selectLoadingSowFull = state => state.sow.loadingFull;
export const selectLoadingSowCustom = state => state.sow.loadingCustom;
export const selectSkillLevel = state => state.sow.skillLevel;
export const selectHideHours = state => state.sow.hideHours;
export const selectEditMode = state => state.sow.editMode;

export default sowSlice.reducer;
