import { useDispatch, useSelector } from 'react-redux';
import { useProject } from '../../../context-providers/Project';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { Spinner } from '../../Components/Spinner';
import { chatEndingIndex, getChatEndingIndex } from '../../../constants';

export const UpdatingArchitectureFloater = ({ isShare }) => {
    const { project, chatHistory } = useProject();

    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    let currentIndex = getChatEndingIndex(chatHistory.data);
    let showPlaceholder = false;

    if (currentIndex < chatEndingIndex) showPlaceholder = true;
    if (project?.skip) showPlaceholder = false;

    return (
        architectureLoading &&
        !showPlaceholder && (
            <div
                className={`absolute ${
                    isShare ? '-mt-4' : 'sm:mt-0'
                } -mt-4 right-0 z-50`}
            >
                <div className="dark flex h-full items-center bg-black border border-gray-300 px-2 py-1 rounded-md m-2">
                    <Spinner w="3" />
                    <h2 className="ml-1 text-2xs text-gray-300 text-center">
                        Updating Architecture...
                    </h2>
                </div>
            </div>
        )
    );
};
