import { Spinner } from '../../Components/Spinner';
import { useSelector } from 'react-redux';
import { selectCurrentNode } from '../../../redux/reducers/generic/projectArchitecture';
import { useProject } from '../../../context-providers/Project';
import { capitalize } from '../../../constants';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import html2canvas from 'html2canvas';
import { toast } from 'sonner';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export default function Explainer({ currentEdge, isSolo, fgRef }) {
    const currentNode = useSelector(selectCurrentNode);
    let { graphType } = useProject();
    let { project } = useProject();

    const { isSubbed } = useSubscriptionPayment();
    const noGo = !isSubbed;
    const noShowExplainer = noGo;

    let { title, details } = getTitleAndDetails(graphType);

    const downloadGraph = async () => {
        if (noShowExplainer) {
            toast.error(
                'Please subscribe to Premium to download the architecture.'
            );
            return;
        }
        try {
            fgRef?.current?.zoomToFit(0, 40);
            await new Promise(resolve => setTimeout(resolve, 10));

            html2canvas(document.getElementById('graphContainer')).then(
                canvas => {
                    let height =
                        document.getElementById('graphContainer').clientHeight;
                    let width =
                        document.getElementById('graphContainer').clientWidth;
                    canvas
                        .getContext('2d')
                        .drawImage(
                            canvas,
                            1,
                            0,
                            width - 1,
                            height,
                            0,
                            0,
                            width - 1,
                            height
                        );
                    var croppedCanvas = document.createElement('canvas');
                    croppedCanvas.width = canvas.width - 1;
                    croppedCanvas.height = canvas.height;
                    var ctx = croppedCanvas.getContext('2d');
                    ctx.drawImage(canvas, 0, 0);
                    var imgData = croppedCanvas.toDataURL('image/png');
                    var a = document.createElement('a');
                    a.href = imgData;
                    a.download = `${project?.name}-${title.toLowerCase()}.png`;
                    a.click();
                }
            );
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };

    return (
        <div
            className={`rounded-md  flex flex-col max-w-[300px] bg-gray-50 py-4 mx-6 justify-start`}
            id="explainer"
        >
            <div className="flex flex-row justify-between items-center px-4">
                {
                    <button
                        onClick={() => {
                            downloadGraph();
                        }}
                        className={`flex text-xs shrink-0 text-left "text-blue-500 hover:text-blue-600 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-xs px-4 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700`}
                    >
                        <ArrowDownTrayIcon className="h-4 w-4 mr-1" />
                        Download Graph
                    </button>
                }
            </div>

            <div className="bg-gray-50 rounded-lg px-4">
                {currentNode && currentNodeDetails(currentNode)}
                {currentEdge && currentEdgeDetails(currentEdge)}
                {!currentNode && !currentEdge && (
                    <div className="flex flex-col h-full border-t border-gray-200 mt-2 pt-2">
                        <h3 className="text-sm left font-semibold text-black">
                            Explorer
                        </h3>
                        <p className="text-xs text-gray-600 mt-1">
                            Hover over elements in the graph to view further
                            information.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

const currentNodeDetails = currentNode => {
    let { description, label } = currentNode;
    return (
        <div className="flex flex-col h-full mt-2">
            <h3 className="text-sm left font-semibold text-black">{label}</h3>
            <p className="text-xs text-gray-600 mt-1">{description}</p>
        </div>
    );
};

const currentEdgeDetails = currentEdge => {
    let { description, source, target } = currentEdge;
    return (
        <div className="flex flex-col h-full border-t border-gray-200 mt-2 pt-2">
            <h3 className="text-sm left font-semibold text-black">
                {source.label} - {target.label}
            </h3>
            <p className="text-xs text-gray-600 mt-1 white">{description}</p>
        </div>
    );
};

const getTitleAndDetails = graphType => {
    let title = capitalize(graphType);
    let details = '';

    if (graphType == 'frontend') {
        details = `This graph represents the journey your users will take. It
			contains the specific pages and features that they will encounter
			along the way.`;
    }
    if (graphType == 'backend') {
        details = `This graph represents the backend  of your
		application. It contains the modules that dictate how data will move through your system.`;
    }
    if (graphType == 'software_design') {
        details = `This graph represents the general architecture of the software design discussed.`;
    }

    return {
        title,
        details
    };
};
