import { BookOpenIcon, UserGroupIcon, WindowIcon, DocumentTextIcon, PuzzlePieceIcon, ClockIcon, MapIcon } from '@heroicons/react/24/solid';
import { Icons } from '../../../ProjectDashboard/DockBar/icons'

export const architecture = {
    step_title: '3. Technical Architecture',
    icon: <Icons.architectureGraph className="h-12 text-white" />,
    description:
        "In this phase, a visual representation of the project's technical architecture is automatically generated. This includes mapping out both frontend and backend components, APIs, and database structures. Users can interact with the architecture and update it to fit their requirements.",
    features: [
        {
            feature_title: 'Dynamic Architecture Graph',
            description:
                'A real-time, interactive graph that visually represents the technical architecture of the project, including components such as frontend modules, backend services, and APIs.',
            icon: <MapIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Interactive Graph Editing',
            description:
                "Users can modify the architecture graph by adding, removing, or rearranging components. This feature allows for real-time adjustments to the project structure, enabling users to refine and optimize their technical architecture based on evolving requirements.",
            icon: <PuzzlePieceIcon className="h-12 text-white" />
        },
    ],
    user_story:
        'Watching the graph build out, Sarah sees how the app will be structured. She notices the integration of social media APIs and decides to explore the backend component for user data storage, ensuring it can scale as the app grows.',
    screenshots: [
        {
            image_url:
                '/architecture_1.png'
        },
        {
            image_url:
                '/architecture_2.png'
        },
    ],
    enterprise_exclusive: false
};