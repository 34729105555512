import { useEffect, useLayoutEffect, useRef } from 'react';
import { useProject } from '../../../context-providers/Project';
import { ChatItem } from './ChatItem';
import { useLoading } from '../../../context-providers/Loading';
import { StreamingChat } from './StreaminingChat';
import { useDispatch } from 'react-redux';
import { toggleSubscribeBanner } from '../../../redux/reducers/generic/billing';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

export const ChatHistory = ({ isSolo }) => {
    const { chatHistory, project } = useProject();
    const dispatch = useDispatch();
    const waitingForChatResponse = chatHistory.waitingForChatResponse;
    const chat = chatHistory.data;

    const { loadingStatus } = useLoading();
    const { isSubbed } = useSubscriptionPayment();

    const chatContainer = useRef(null);
    // various effects for the chat including scroll to bottom, refocus, progress, and autosize textarea
    useEffect(() => {
        try {
            if (chatContainer.current && chat && chat.length > 2) {
                chatContainer?.current?.scrollTo({
                    top: chatContainer?.current?.scrollHeight
                });
            }
            if ((chat.length > 10 || project.skip) && !isSubbed) {
                dispatch(toggleSubscribeBanner(true));
            } else {
                dispatch(toggleSubscribeBanner(false));
            }
        } catch (error) {
            console.log(error);
        }
    }, [chat, waitingForChatResponse, chatContainer]);

    useEffect(() => {
        //scroll to bottom of the chat
        try {
            setTimeout(() => {
                if (chatContainer.current && chat && chat.length > 1) {
                    chatContainer?.current?.scrollTo({
                        top: chatContainer?.current?.scrollHeight
                    });
                }
            }, 50);
        } catch (error) {
            console.log(error);
        }
    }, [loadingStatus]);

    let height = 230;

    return (
        <div
            className="rounded-xl max-w-3xl flex justify-end mx-auto bg-slate-900 p-4 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7 md:flex md:flex-col justify-end md:justify-start h-full lg:pb-0 overflow-y-auto dark"
            ref={chatContainer}
        >
            <div
                id="chatHistory"
                style={{
                    height: `calc(100vh - ${height}px)`
                }}
            >
                {chat &&
                    chat.map((message, i) => (
                        <ChatItem
                            isSolo={isSolo}
                            message={message}
                            i={i}
                            key={i}
                            length={chat.length}
                        />
                    ))}
                <StreamingChat chatContainer={chatContainer} />
                <div class="flex px-2 py-2"></div>
            </div>
        </div>
    );
};
