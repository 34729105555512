import { client, clientWithAPIKey } from '../../../api/client';
import {
    fetchProjectSuccess,
    fetchProjectFailure,
    setMeetingRequested,
    setProjectId
} from '../../reducers/generic/project';
import { setProjectArchitecture } from '../../reducers/generic/projectArchitecture';

import { setChatHistory } from '../../reducers/generic/chatHistory';
import { GET_PROJECT_BY_UNIQUE_ID } from '../../../api/graphQL/enterprise/query';

export const getProjectByUniqueId =
    ({ apiKey, uniqueId }) =>
    async dispatch => {
        try {
            const { data } = await clientWithAPIKey(apiKey).query({
                query: GET_PROJECT_BY_UNIQUE_ID,
                variables: { apiKey, uniqueId },
                fetchPolicy: 'network-only'
            });

            dispatch(setProjectId(data.projectByUniqueId._id.toString()));

            //Set up Project
            dispatch(fetchProjectSuccess(data.projectByUniqueId));

            //Set up Chat History
            dispatch(setChatHistory(data.projectByUniqueId.chatHistory));

            //Set up Project Architecture
            dispatch(
                setProjectArchitecture({
                    graphs: data.projectByUniqueId.graphs
                })
            );

            //Set up request meeting status
            dispatch(
                setMeetingRequested(data.projectByUniqueId.meetingRequested)
            );
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectFailure(error.message));
        }
    };
