import { UploadWireframe } from './UploadWireframe';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUIView } from '../../../../redux/actions/solo/getUIView';
import { selectGettingUIView } from '../../../../redux/reducers/generic/projectArchitecture';
import { Spinner } from '../../../Components/Spinner';

export const UiCta = ({ node, projectId, loading }) => {
    const dispatch = useDispatch();
    const gettingUIView = useSelector(selectGettingUIView);
    const generateUI = () => {
        // alert(node._id)
        dispatch(
            getUIView({
                nodeId: node._id,
                projectId: projectId,
                forceNew: true
            })
        );
    };
    return (
        <div className="pt-4 px-4 mx-auto flex flex-col items-center justify-center">
            <div className="mx-auto max-w-screen-sm text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900">
                    Let's start building your UI for the{' '}
                    <span className="text-blue-600">{node?.label}</span> node.
                </h2>
                <p className="font-light text-gray-500 dark:text-gray-400 text-lg mb-4">
                    First, either upload an existing wireframe design or click
                    "Generate UI" to start creating the first version of your
                    view.
                </p>

                <button
                    onClick={generateUI}
                    type="button"
                    disabled={gettingUIView}
                    className={`${
                        gettingUIView
                            ? 'bg-gray-500'
                            : 'bg-blue-700 hover:bg-blue-800'
                    } mt-4 mr-8 flex-none text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 text-center md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                    {gettingUIView || loading ? (
                        <>
                            Generating UI
                            <div className="inline-block mr-2 ml-2 h-5">
                                <Spinner w="5" h="5" />
                            </div>
                        </>
                    ) : (
                        <>
                            Generate UI <span aria-hidden="true">&rarr;</span>
                        </>
                    )}
                </button>

                <div className="text-lg my-4 text-bold mx-auto">or</div>
                <div className="mx-auto flex justify-center">
                    <UploadWireframe node={node} projectId={projectId} />
                </div>
            </div>
        </div>
    );
};
