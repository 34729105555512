import { UPDATE_ENTERPRISE_ORGANIZATION_BOOLEAN_FIELD } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';

export const updateEnterpriseOrganizationBooleanField =
    (enterpriseOrganizationId, field, value) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_ENTERPRISE_ORGANIZATION_BOOLEAN_FIELD,
                variables: {
                    enterpriseOrganizationId,
                    field,
                    value
                },
                fetchPolicy: 'network-only'
            });

            return 'Updated ' + field + ' successfully.';
        } catch (error) {
            return error.message;
        }
    };
