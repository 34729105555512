import gql from 'graphql-tag';
import {
    existingProjectContext,
    graph,
    message,
    sow,
    thumbsRating
} from '../helper';

export const CREATE_PROJECT = gql`
    mutation CreateProject(
        $name: String!
        $type: String!
        $fileKeyS3: String
        $hasExistingContext: Boolean
        $subType: String
    ) {
        createProject(
            name: $name
            type: $type
            fileKeyS3: $fileKeyS3
            subType: $subType
            hasExistingContext: $hasExistingContext
        ) {
            _id
            name
            type
            created
            fileKeyS3
            meetingRequested
            hasExistingContext
            updated
            messageCount
            skip
            creationMethod
        }
    }
`;

export const SAVE_SOW = gql`
    mutation SaveSow(
        $projectId: ID!
        $type: String!
        $version: Int!
        $content: String!
    ) {
        saveSow(
            projectId: $projectId
            type: $type
            version: $version
            content: $content
        ) {
            ${sow}
        }
    }
`;

export const SAVE_UIVIEW = gql`
    mutation SaveUIView(
        $projectId: ID!
        $nodeId: String!
        $version: Int!
        $codefiles: [CodeFileInput]!
    ) {
        saveUIView(
            projectId: $projectId
            nodeId: $nodeId
            version: $version
            codefiles: $codefiles
        )
    }
`;

export const UPDATE_SCREENSHOT_UIVIEW = gql`
    mutation SaveSow(
        $projectId: ID!
        $type: String!
        $version: Int!
        $content: String!
    ) {
        saveSow(
            projectId: $projectId
            type: $type
            version: $version
            content: $content
        )
    }
`;

export const CORRECT_UI_VIEW_ERRORS = gql`
    mutation correctUIViewErrors(
        $projectId: ID!
        $nodeId: ID!
        $version: Int!
        $errors: [ErrorInput]
    ) {
        correctUIViewErrors(
            projectId: $projectId
            nodeId: $nodeId
            version: $version
            errors: $errors
        ) {
            _id
        }
    }
`;

export const SKIP_QUESTIONS = gql`
    mutation SkipQuestions($projectId: ID!) {
        skipQuestions(projectId: $projectId) {
            _id
        }
    }
`;

export const GET_SIGNED_UPLOAD_URL = gql`
    mutation GetSignedUploadURL($filename: String!, $isLogo: Boolean) {
        getSignedUploadURL(filename: $filename, isLogo: $isLogo)
    }
`;

export const GET_SIGNED_DOWNLOAD_URL = gql`
    mutation GetSignedDownloadURL($filename: String!) {
        getSignedDownloadURL(filename: $filename)
    }
`;

export const INITIATE_FOLLOW_UP_QUESTION = gql`
	mutation initiateFollowUpQuestionStream(
		$answer: MessageInput!
		$projectId: ID!
	) {
		initiateFollowUpQuestionStream(answer: $answer, projectId: $projectId) {
			${message}
		}
	}
`;

export const INITIATE_FOLLOW_UP_QUESTION_UIVIEW = gql`
    mutation initiateFollowUpQuestionUIViewStream(
        $answer: MessageInput!
        $projectId: ID!
        $nodeId: ID!
    ) {
        initiateFollowUpQuestionUIViewStream(answer: $answer, projectId: $projectId, nodeId: $nodeId) {
            ${message}
        }
    }
`;

export const INITIATE_SUGGESTION = gql`
    mutation initiateSuggestionStream($projectId: ID!, $randId: ID!) {
        initiateSuggestionStream(projectId: $projectId, randId: $randId) {
            suggestion
            completed
        }
    }
`;

export const REQUEST_MEETING = gql`
    mutation requestMeeting($projectId: ID!) {
        requestMeeting(projectId: $projectId) {
            _id
            name
            created
            meetingRequested
        }
    }
`;

export const DELETE_PROJECT = gql`
    mutation deleteProject($projectId: ID!) {
        deleteProject(projectId: $projectId)
    }
`;

export const EDIT_PROJECT_NAME = gql`
    mutation editProjectName($projectId: ID!, $name: String!) {
        editProjectName(projectId: $projectId, name: $name)
    }
`;

export const MODIFY_SOW = gql`
    mutation modifySow(
        $projectId: ID!
        $type: String!
        $version: Int!
        $comment: String
        $increaseDetail: Boolean
        $decreaseDetail: Boolean
    ) {
        modifySow(
            projectId: $projectId
            type: $type
            version: $version
            comment: $comment
            increaseDetail: $increaseDetail
            decreaseDetail: $decreaseDetail
        ) {
            content
            version
        }
    }
`;

export const REMOVE_TECH_STACK_ITEM_FROM_PROJECT = gql`
    mutation removeTechStackItemFromProject($projectId: ID!, $index: Int!) {
        removeTechStackItemFromProject(projectId: $projectId, index: $index)
    }
`;

export const ADD_TECH_STACK_ITEM_TO_PROJECT = gql`
    mutation addTechStackItemToProject($projectId: ID!, $name: String!) {
        addTechStackItemToProject(projectId: $projectId, name: $name)
    }
`;

export const ADD_NODES_EDGES_TO_PROJECT_GRAPH = gql`
    mutation addNodesEdgesToProjectGraph(
        $projectId: ID!
        $side: String!
        $nodesEdges: InputGraph
    ) {
        addNodesEdgesToProjectGraph(
            projectId: $projectId
            side: $side
            nodesEdges: $nodesEdges
        ) {
            ${graph}
        }
    }
`;

export const ADD_EXISTING_PROJECT_CONTEXT = gql`
    mutation addExistingProjectContext(
        $fileKeyS3: String
        $text: String
        $image_url: String
        $type: String!
        $projectId: ID!
    ) {
        addExistingProjectContext(
            fileKeyS3: $fileKeyS3
            text: $text
            image_url: $image_url
            type: $type
            projectId: $projectId
        ) {
            ${existingProjectContext}
        }
    }
`;

export const REMOVE_EXISTING_PROJECT_CONTEXT = gql`
    mutation removeExistingProjectContext($existingProjectContextId: ID!) {
        removeExistingProjectContext(
            existingProjectContextId: $existingProjectContextId
        )
    }
`;

export const GENERATE_SOW = gql`
    mutation generateSow($projectId: ID!, $type: String!) {
        generateSow(projectId: $projectId, type: $type) {
            ${sow}
        }
    }
`;

export const GENERATE_SOW_CUSTOM = gql`
    mutation generateSowCustom($projectId: ID!, $type: String!) {
        generateSowCustom(projectId: $projectId, type: $type) {
            ${sow}
        }
    }
`;

export const UPDATE_THUMBS_RATING = gql`
    mutation updateThumbsRating(
        $id: String!
        $rating: String!
        $metadata: [String]!
    ) {
        updateThumbsRating(id: $id, rating: $rating, metadata: $metadata) {
            ${thumbsRating}
        }
    }
`;

export const UPGRADE_TO_SOLO_PREMIUM = gql`
    mutation upgradeToSoloPremium {
        upgradeToSoloPremium
    }
`;
