import { Icon } from '../TipTap/components/ui/Icon';
import { Toolbar } from '../TipTap/components/ui/Toolbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSow } from '../../../../redux/reducers/generic/sow';
import { saveSow } from '../../../../redux/actions/solo/saveSow';

export const Save = ({ editor, isEditMode }) => {
    const [hasChanges, setHasChanges] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    let sow = useSelector(selectSow);
    const [currentSowId, setCurrentSowId] = useState(null);
    const [oldMode, setOldMode] = useState(null);
    const dispatch = useDispatch();

    const handleSave = async () => {
        setIsSaving(true);
        console.log(editor.getJSON());
        console.log(sow);
        if (sow?.projectId) {
            try {
                await dispatch(
                    saveSow({
                        projectId: sow.projectId,
                        type: sow.type,
                        version: sow.version,
                        content: JSON.stringify(editor.getJSON())
                    })
                );
                setHasChanges(false);
            } catch (error) {
                console.error('Error saving SOW:', error);
            } finally {
                setIsSaving(false);
            }
        }
    };

    useEffect(() => {
        if (editor) {
            editor.on('update', () => {
                setHasChanges(true);
            });
            setTimeout(() => {
                if (sow?._id) {
                    if (currentSowId !== sow?._id) {
                        setCurrentSowId(sow._id);
                        setHasChanges(false);
                    }
                }
            }, 50);
            setTimeout(() => {
                if (oldMode !== isEditMode) {
                    setOldMode(isEditMode);
                    setHasChanges(false);
                }
            }, 50);
            return () => {
                editor.off('update', () => {
                    setHasChanges(false);
                });
            };
        } else {
            setHasChanges(false);
        }
    }, [editor, isEditMode, sow]);

    return (
        isEditMode && (
            <Toolbar.Button
                tooltip="Save changes"
                onClick={handleSave}
                disabled={!hasChanges || isSaving}
                className={`${
                    hasChanges
                        ? 'transition-colors duration-200 border border-gray-500'
                        : 'text-gray-400 cursor-not-allowed border border-gray-300'
                } rounded-md ml-2`}
            >
                <Icon name="Save" className="mr-2" />
                {isSaving ? 'Saving...' : hasChanges ? 'Save' : 'Saved'}
            </Toolbar.Button>
        )
    );
};
