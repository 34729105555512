import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef } from 'react';
import { useProject } from '../../../context-providers/Project';
import { useProjects } from '../../../context-providers/Projects';
import ProjectItem from './ProjectItem';
import Settings from './Settings';
import CreateNewProject from './createNewProject';
import { Link, useLocation } from 'react-router-dom';
import UpgradeToPremium from './UpgradeToPremium';
import { StarIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { FeedBackButton } from '../../Components/FeedbackButton';
import LoadMoreButton from './LoadMoreButton';
import {
    paginateProjects,
    selectSearchLoading
} from '../../../redux/reducers/generic/projects';
import { useDispatch, useSelector } from 'react-redux';
import { SearchProjects } from '../../EnterpriseHome/ClientProjects/Search';
import {
    toggleSidebar,
    setSidebarOpen,
    selectSidebarOpen
} from '../../../redux/reducers/generic/sidebar';

export default function MobileSidebar() {
    const dispatch = useDispatch();
    const sidebarOpen = useSelector(selectSidebarOpen);
    let { projects } = useProjects();
    let { project, pageType } = useProject();
    const divRef = useRef(null);
    const { pathname } = useLocation();
    const { isSubbed } = useSubscriptionPayment();
    const searchLoading = useSelector(selectSearchLoading);

    let colors = {
        web2: 'green',
        web3: 'blue'
    };

    useEffect(() => {
        const handleScroll = () => {
            const div = divRef.current;

            const isBottom =
                div?.scrollTop + div?.clientHeight >= div?.scrollHeight;
            if (isBottom) {
                dispatch(paginateProjects());
            }
        };
        setTimeout(() => {
            const div = divRef.current;
            if (div) divRef.current.addEventListener('scroll', handleScroll);
        }, 100);

        return () => {
            const div = divRef.current;

            if (div) div.removeEventListener('scroll', handleScroll);
        };
    }, [divRef, sidebarOpen]);

    // Update the max-height of projectsList based on window height
    useEffect(() => {
        const resizeHandler = () => {
            setTimeout(() => {
                if (divRef.current) {
                    const windowHeight = window.innerHeight;
                    const topOffset = divRef.current.offsetTop;
                    const liElementHeight = !isSubbed ? 150 : 160; // Set the height of your li element here
                    const availableHeight =
                        windowHeight - topOffset - liElementHeight;
                    divRef.current.style.maxHeight = `${availableHeight}px`;
                }
            }, 200);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler(); // Call it initially to set the initial height
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [isSubbed, divRef, sidebarOpen]);

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleCloseSidebar = () => {
        dispatch(setSidebarOpen(false));
    };

    let title =
        pageType === 'chat'
            ? 'Project Discovery'
            : pageType === 'graph'
              ? 'Architecture'
              : pageType === 'sow'
                ? 'Documentation'
                : pageType === 'roadmap'
                  ? 'Roadmap'
                  : pageType === 'code'
                    ? 'Code Gen'
                    : null;

    return (
        <>
            <div
                id="topNav"
                className="h-[46px] justify-between flex bg-black px-4 shadow-sm sm:px-6 lg:hidden"
            >
                <button
                    type="button"
                    className="text-gray-400"
                    onClick={handleToggleSidebar}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {title && (
                    <div className="md:hidden w-full h-full flex items-center justify-center">
                        <span className="text-white font-semibold text-md tracking-wide">
                            {title}
                        </span>
                    </div>
                )}
            </div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="z-50 lg:hidden"
                    onClose={handleCloseSidebar}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 left-0 flex w-16 pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={handleCloseSidebar}
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div class="max-w-xl w-[300px] h-full rounded-lg border border-slate-300 border-t-0 border-l-0 bg-slate-50 pb-8 dark:border-slate-200/10 dark:bg-slate-900">
                                    <div className="flex justify-between">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <Link to="/">
                                                <img
                                                    className="ml-2 h-8 w-auto rounded-full p-[3px]"
                                                    src="/predevblack.png"
                                                />
                                            </Link>
                                            <Link to="/" className="flex">
                                                <span className="ml-1 font-semibold self-center">
                                                    pre.dev
                                                </span>
                                                <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-blue-600">
                                                    Solo
                                                </span>
                                            </Link>
                                        </div>
                                        <Link
                                            to="/enterprise/dashboard"
                                            className="flex items-center"
                                        >
                                            <a class="ml-2 inline-flex text-green-500 items-center justify-center px-2 py-1 me-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                <UserGroupIcon className="w-3 h-3 mr-2" />
                                                Enterprise
                                            </a>
                                        </Link>
                                    </div>

                                    <div class="flex items-start">
                                        <h2 class="inline px-5 text-lg font-medium text-slate-800 dark:text-slate-200">
                                            Projects
                                        </h2>
                                        <span class="rounded-full bg-blue-600 px-2 py-1 text-xs text-slate-200">
                                            {projects?.length}
                                        </span>
                                    </div>

                                    {isSubbed ? <SearchProjects /> : null}

                                    <div
                                        ref={divRef}
                                        class="my-4 h-full space-y-4 overflow-y-auto px-2"
                                    >
                                        {!searchLoading &&
                                            projects.map((project, i) => (
                                                <ProjectItem
                                                    closeSidebar={
                                                        handleCloseSidebar
                                                    }
                                                    project={project}
                                                    key={i}
                                                />
                                            ))}
                                        {!isSubbed && <UpgradeToPremium />}
                                        {!searchLoading && (
                                            <div className="flex items-cente justify-center">
                                                <LoadMoreButton />
                                            </div>
                                        )}
                                    </div>
                                    <div class="mx-2">
                                        <CreateNewProject />
                                        <div className="mt-2">
                                            <FeedBackButton textColor="black" />
                                            <Settings />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
