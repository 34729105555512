import { GET_SOW } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { setSow } from '../../reducers/generic/sow';
import { getSowStatuses } from './getSowStatuses';
import {
    addToSowVersions,
    setSowVersion,
    setSowVersionLoading
} from '../../reducers/generic/project';
import { getJira } from '../enterprise/getJira';
import { generateSow } from './generateSow';

export const getSow =
    (projectId, type, version) => async (dispatch, getState) => {
        try {
            let oldSow = getState().sow.data;
            if (
                type == 'mvp' &&
                !version &&
                oldSow &&
                oldSow.projectId == projectId &&
                oldSow.type == type
            ) {
                return;
            }
            if (getState().projectArchitecture.loading) return;

            dispatch(
                setSow({
                    type
                })
            );
            dispatch(setSowVersionLoading({ type, loading: true }));

            dispatch(
                getJira({
                    projectId,
                    type
                })
            );

            const { data } = await client.query({
                query: GET_SOW,
                variables: { projectId, type, version },
                fetchPolicy: 'network-only'
            });

            if (type == 'mvp') {
                if (data?.getSow) {
                    if (data?.getSow?.internal_version !== 3) {
                        dispatch(generateSow(projectId, type));
                        return;
                    }
                }
            }

            if (data?.getSow) {
                dispatch(setSow(data.getSow));
                dispatch(setSowVersion({ type, version: data.getSow.version }));
                dispatch(
                    addToSowVersions({ type, version: data.getSow.version })
                );
            }

            dispatch(getSowStatuses(projectId));
            dispatch(setSowVersionLoading({ type, loading: false }));

            return data?.getSow;
        } catch (error) {
            if (error && error.graphQLErrors) {
                if (
                    error.graphQLErrors.some(err =>
                        err.message.includes(
                            'Cannot return null for non-nullable field'
                        )
                    )
                ) {
                    dispatch(generateSow(projectId, type));
                }
            } else if (
                error.message.includes(`API is temporarily unavailable.`)
            ) {
                alert(
                    `API is temporarily unavailable. Please try again later.`
                );
            } else {
                dispatch(setSowVersionLoading({ type, loading: false }));
            }
        }
    };
