import {
    ClockIcon,
    RocketLaunchIcon,
    WindowIcon,
    LightBulbIcon,
    CalendarIcon,
    ChartBarIcon,
    CurrencyDollarIcon,
    SparklesIcon,
    UserGroupIcon,
    DocumentTextIcon,
    PresentationChartLineIcon,
    ChatBubbleBottomCenterTextIcon,
    ClipboardDocumentCheckIcon,
    UserIcon,
    CogIcon,
    PuzzlePieceIcon,
    MagnifyingGlassIcon,
    ArrowTrendingUpIcon,
    ShieldCheckIcon,
    BriefcaseIcon
} from '@heroicons/react/24/solid';
import { Code2Icon } from 'lucide-react';

const HighlightedText = ({ children, color }) => (
    <span className={`font-extrabold text-blue-200`}>{children}</span>
);

export const useCases = [
    {
        name: 'Product Managers',
        description: (
            <>
                Turn product and feature ideas into{' '}
                <HighlightedText color="blue">PRDs</HighlightedText>,{' '}
                <HighlightedText color="green">Jira Tickets</HighlightedText>,
                and <HighlightedText color="purple">Prototypes</HighlightedText>
                .
            </>
        ),
        tagline: 'Save 10+ hours / week',
        href: '#',
        icon: ClockIcon,
        advantages: [
            {
                text: 'Turn RFPs into full project requirements',
                icon: DocumentTextIcon
            },
            {
                text: 'Automatically assign tasks to team members',
                icon: ChartBarIcon
            },
            {
                text: 'Instant creation of detailed user stories',
                icon: UserIcon
            },
            {
                text: 'Rapid prototyping without coding skills',
                icon: Code2Icon
            }
        ]
    },
    {
        name: 'Venture Studios',
        description: (
            <>
                Automate{' '}
                <HighlightedText color="red">idea vetting</HighlightedText>,{' '}
                <HighlightedText color="yellow">
                    project scoping
                </HighlightedText>
                , and{' '}
                <HighlightedText color="indigo">prototyping</HighlightedText>.
            </>
        ),
        tagline: 'Save 4+ weeks / project',
        href: '#',
        icon: LightBulbIcon,
        advantages: [
            {
                text: 'Whitelabel pre.dev to instantly outsource external ideas',
                icon: MagnifyingGlassIcon
            },
            {
                text: 'Automated technical feasibility assessments',
                icon: ClipboardDocumentCheckIcon
            },
            {
                text: 'Rapid MVP scoping and cost estimation',
                icon: CurrencyDollarIcon
            },
            {
                text: 'Parallel prototyping of multiple concepts',
                icon: PuzzlePieceIcon
            }
        ]
    },
    {
        name: 'Founders',
        description: (
            <>
                Generate{' '}
                <HighlightedText color="pink">
                    pricing estimates
                </HighlightedText>
                , convert{' '}
                <HighlightedText color="orange">
                    mockups to prototypes
                </HighlightedText>
                , and connect with{' '}
                <HighlightedText color="teal">
                    skilled developers
                </HighlightedText>
                .
            </>
        ),
        tagline: 'Cut costs by over 50%',
        href: '#',
        icon: RocketLaunchIcon,
        advantages: [
            {
                text: 'AI-assisted project discovery and scoping',
                icon: PresentationChartLineIcon
            },
            {
                text: 'Automated conversion of mockups to working prototypes',
                icon: SparklesIcon
            },
            {
                text: 'Intelligent developer matching based on project needs',
                icon: UserGroupIcon
            },
            {
                text: 'Continuous iteration and evaluation of projects',
                icon: ArrowTrendingUpIcon
            }
        ]
    },
    {
        name: 'Developer Agencies',
        description: (
            <>
                Whitelabel pre.dev to automate{' '}
                <HighlightedText color="cyan">
                    client onboarding
                </HighlightedText>
                ,{' '}
                <HighlightedText color="lime">project scoping</HighlightedText>,
                and{' '}
                <HighlightedText color="amber">
                    frontend code generation
                </HighlightedText>
                .
            </>
        ),
        tagline: 'Save 10+ hours / client',
        href: '#',
        icon: WindowIcon,
        advantages: [
            {
                text: 'Embed pre.dev on your website to automate client onboarding',
                icon: ChatBubbleBottomCenterTextIcon
            },
            {
                text: 'Automated project timeline and milestone generation',
                icon: CalendarIcon
            },
            {
                text: 'Instant frontend code generation for projects',
                icon: Code2Icon
            },
            {
                text: 'Seamless integration existing project management tools',
                icon: CogIcon
            }
        ]
    }
];
