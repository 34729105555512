import { useEffect, useRef, useState } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import { useProject } from '../../../context-providers/Project';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectPanelResized,
    selectProjectArchitectureLoading,
    setHighlightNodes
} from '../../../redux/reducers/generic/projectArchitecture';
import { selectEnterpriseOrganization } from '../../../redux/reducers/enterprise/enterpriseOrganization';
import { setShowNodeDetailModal } from '../../../redux/reducers/generic/projectArchitecture';
import { useLocation } from 'react-router-dom';

import {
    addEdgeToAdd,
    addEdgeToDelete,
    addNodeToDelete,
    selectAddEdgeMode,
    selectAddNodeMode,
    selectEdgesToAdd,
    selectEdgesToDelete,
    selectEditMode,
    selectFirstNode,
    selectGraphEditLoading,
    selectNodesToAdd,
    selectNodesToDelete,
    setFirstNode
} from '../../../redux/reducers/generic/graphEdits';
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from 'framer-motion';

const Tooltip = ({ x, y, content, isVisible, width }) => (
    <AnimatePresence>
        {isVisible && (
            <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className="absolute z-50 p-2 text-white rounded"
                style={{ top: y > 0 ? 0 : 300, left: width }}
            >
                {content}
            </motion.div>
        )}
    </AnimatePresence>
);

export const Graph = ({
    setCreateNodeOpen,
    fgRef,
    setCurrentEdge,
    isSolo,
    isShare,
    navRef,
    graphHeight,
    setGraphHeight,
    setOriginalHeight,
    tooltip
}) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [engineStopped, setEngineStopped] = useState(false);
    const panelResized = useSelector(selectPanelResized);
    let editMode = useSelector(selectEditMode);
    const addEdgeMode = useSelector(selectAddEdgeMode);
    const addNodeMode = useSelector(selectAddNodeMode);
    const firstNode = useSelector(selectFirstNode);
    const graphEditLoading = useSelector(selectGraphEditLoading);
    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    const [localHightlightLinks, setLocalHighlightLinks] = useState([]);
    const { projectArchitecture, graphType } = useProject();
    let graph = projectArchitecture.graphs.find(g => g.side === graphType);
    const highlightNodes = projectArchitecture.highlightNodes;
    const highlightLinks =
        projectArchitecture.highlightLinks.concat(localHightlightLinks);
    const [graphData, setGraphData] = useState(null);
    const nodesToAdd = useSelector(selectNodesToAdd);
    const edgesToAdd = useSelector(selectEdgesToAdd);
    const nodesToDelete = useSelector(selectNodesToDelete);
    const edgesToDelete = useSelector(selectEdgesToDelete);

    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const entOrgName = enterpriseOrganization?.name;

    if (architectureLoading) editMode = false;

    useEffect(() => {
        if (graph) {
            const graphDataClone = JSON.parse(JSON.stringify(graph));
            setGraphData(graphDataClone);
        } else {
            setGraphData(null);
        }
    }, [graph]);

    useEffect(() => {
        try {
            const graphDataClone = JSON.parse(JSON.stringify(graph));
            let edgeDeleteIds = edgesToDelete.map(edge => edge.id);
            let nodeDeleteIds = nodesToDelete.map(node => node.id);

            if (graphDataClone?.nodes) {
                graphDataClone.nodes = graphDataClone.nodes.concat(nodesToAdd);
                graphDataClone.links = graphDataClone.links.concat(edgesToAdd);
                graphDataClone.nodes = graphDataClone.nodes.filter(
                    node => !nodeDeleteIds.includes(node.id)
                );
                graphDataClone.links = graphDataClone.links.filter(
                    link => !edgeDeleteIds.includes(link.id)
                );

                setGraphData(JSON.parse(JSON.stringify(graphDataClone)));
            }
        } catch (e) {
            // console.log(e);
        }
    }, [nodesToAdd, edgesToAdd, nodesToDelete, edgesToDelete, editMode]);

    useEffect(() => {
        if (!fgRef.current) return;
        fgRef.current.d3Force('link').distance(link => {
            return Math.max(graph.nodes.length * 3, 30);
        });
    }, [graphData, fgRef, engineStopped]);

    const handleMouseMove = event => {
        setMousePosition({ x: event.clientX, y: event.clientY });
    };

    const graphSideElement = document.getElementById('graph_side');
    const graphSideWidth = graphSideElement ? graphSideElement.clientWidth : 0;
    const [pageWidth, setPageWidth] = useState(graphSideWidth);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const getDiffHeight = () => {
        let extraHeight = 0;
        if (window.innerWidth < 1024) extraHeight = 46;
        if (window.innerWidth < 768) extraHeight += 48;
        let finalHeight =
            (navRef ? navRef?.current?.clientHeight : 0) + extraHeight;
        return finalHeight;
    };

    useEffect(() => {
        if (navRef?.current) {
            setOriginalHeight(getDiffHeight());
            setGraphHeight(window.innerHeight - getDiffHeight());
        }
    }, [navRef]);

    useEffect(() => {
        const resizeHandler = () => {
            const graphSideElement = document.getElementById('graph_side');
            const graphSideWidth = graphSideElement
                ? graphSideElement.clientWidth
                : 0;
            setPageWidth(graphSideWidth);
            setGraphHeight(window.innerHeight - getDiffHeight());
            setTimeout(() => {
                fgRef?.current?.zoomToFit(500, 40);
            }, 200);
            setOriginalHeight(getDiffHeight());
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [document.getElementById('graph_side')]);

    useEffect(() => {
        const graphSideElement = document.getElementById('graph_side');
        const graphSideWidth = graphSideElement
            ? graphSideElement.clientWidth
            : 0;
        setPageWidth(graphSideWidth);
    }, [panelResized]);

    const highlightLink = link => {
        if (link) setLocalHighlightLinks([link.id]);
        else setLocalHighlightLinks([]);
        setCurrentEdge(link);
    };

    const canAddEdge = (sourceId, targetId) => {
        return !graph.links.some(
            link =>
                (link.source === sourceId && link.target === targetId) ||
                (link.source === targetId && link.target === sourceId)
        );
    };

    const canDeleteEdge = (sourceId, targetId) => {
        if (!graphData || !graphData.nodes || !graphData.links) {
            return false;
        }

        const visited = new Set();
        const queue = [sourceId];

        while (queue.length > 0) {
            const nodeId = queue.shift();
            if (nodeId === targetId) {
                return true;
            }

            const linksToConsider = graphData.links.filter(
                link =>
                    (link.source.id === nodeId || link.target.id === nodeId) &&
                    !(
                        link.source.id === sourceId &&
                        link.target.id === targetId
                    )
            );

            linksToConsider.forEach(link => {
                const nextNodeId =
                    link.source.id === nodeId ? link.target.id : link.source.id;
                if (!visited.has(nextNodeId)) {
                    visited.add(nextNodeId);
                    queue.push(nextNodeId);
                }
            });
        }

        return false;
    };

    const canDeleteNode = node => {
        if (node.id === 'core') return false;
        const edges = graphData.links.filter(
            link => link.source.id === node.id || link.target.id === node.id
        );
        return edges.length === 1;
    };

    const handleNodeHover = node => {
        if (graphEditLoading) return;

        if (node) {
            dispatch(setHighlightNodes({ ...node, is_hover: true }));
            if (node.x && node.y) {
                setTooltipContent(node.label);
                setTooltipPosition({ x: node.x, y: node.y });
                setIsTooltipVisible(true);
                setShowTooltip(true);
            }
        } else {
            dispatch(setHighlightNodes([]));
            setIsTooltipVisible(false);
            setTimeout(() => setShowTooltip(false), 2000); // 2 seconds fade-out delay
        }
    };

    const handleNodeClick = (node, event) => {
        if (graphEditLoading) return;
        if (editMode) {
            if (addNodeMode) {
                dispatch(setFirstNode({ id: node.id, label: node.label }));
                setCreateNodeOpen(true);
                return;
            }

            if (addEdgeMode && !firstNode) {
                dispatch(setFirstNode({ id: node.id, label: node.label }));
                return;
            }
            if (addEdgeMode && firstNode?.id === node.id) {
                dispatch(setFirstNode(null));
                return;
            }
            if (
                addEdgeMode &&
                firstNode?.id !== node.id &&
                canAddEdge(firstNode.id, node.id)
            ) {
                dispatch(
                    addEdgeToAdd({
                        source: firstNode.id,
                        target: node.id,
                        id: uuidv4()
                    })
                );
                return;
            }

            if (canDeleteNode(node)) {
                if (node.id === 'core') return;
                graphData.links
                    .filter(
                        link =>
                            link.source.id === node.id ||
                            link.target.id === node.id
                    )
                    .forEach(edge => {
                        dispatch(
                            addEdgeToDelete({
                                source: edge.source.id,
                                target: edge.target.id,
                                id: edge.id
                            })
                        );
                    });
                dispatch(addNodeToDelete({ id: node.id, label: node.label }));
            }
        } else {
            if (
                (entOrgName === 'pre.dev' || entOrgName === "Jeremy's Org") &&
                !pathname.includes('demo') &&
                !pathname.includes('share')
            ) {
                if (node && graphType === 'frontend') {
                    const nd = {
                        label: node.label,
                        description: node.description,
                        id: node.id,
                        _id: node._id
                    };
                    dispatch(setShowNodeDetailModal(nd));
                }
            }
        }
    };

    return (
        <div>
            <Tooltip
                x={tooltipPosition.x}
                y={tooltipPosition.y}
                content={tooltip}
                isVisible={isTooltipVisible}
                width={pageWidth / 2}
            />
            {graphData ? (
                <ForceGraph2D
                    id="graph"
                    ref={fgRef}
                    graphData={graphData}
                    width={pageWidth}
                    enableZoomInteraction={!isShare}
                    height={graphHeight}
                    linkColor={link => {
                        if (editMode) {
                            return highlightLinks.includes(link.id) &&
                                canDeleteEdge(link.source.id, link.target.id)
                                ? '#FF6347'
                                : 'grey';
                        }
                        return 'white';
                    }}
                    nodeCanvasObject={(node, ctx, globalScale) => {
                        try {
                            let hours = node.hours || 0;
                            let NODE_R = Math.max(4, (hours / 40) * 50);
                            if (NODE_R > 10) {
                                NODE_R = 10;
                            }
                            if (graphType == 'backend') {
                                NODE_R *= 1.5;
                            }

                            const baseColor = '#888888';
                            let fillColor =
                                hours > 0
                                    ? `hsl(${
                                          (Math.pow(hours % 14, 2) * 10) % 1000
                                      }, 60%, 60%)`
                                    : baseColor;

                            if (editMode) {
                                fillColor = 'grey';
                            }
                            if (addEdgeMode || addNodeMode) {
                                fillColor = '#9c9c9c';
                            }
                            if (addEdgeMode && firstNode?.id === node.id) {
                                fillColor = '#f0e7b1';
                            }

                            if (
                                highlightNodes &&
                                highlightNodes.includes(node.id)
                            ) {
                                if (editMode) {
                                    if (addEdgeMode || addNodeMode) {
                                        fillColor = 'lightblue';
                                    } else if (canDeleteNode(node)) {
                                        fillColor = '#FF6347';
                                    }
                                } else {
                                    fillColor = 'white';
                                }
                            }

                            // Adding box shadow
                            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                            ctx.shadowBlur = 10;

                            // Setting node opacity
                            ctx.globalAlpha = 0.9;

                            const nodeCircle = new Path2D();
                            nodeCircle.arc(
                                node.x,
                                node.y,
                                NODE_R,
                                0,
                                2 * Math.PI,
                                false
                            );
                            ctx.fillStyle = fillColor;
                            ctx.fill(nodeCircle);

                            // Resetting shadow and opacity for text rendering
                            ctx.shadowBlur = 0;
                            ctx.globalAlpha = 1.0;

                            let label = node.label;
                            label =
                                label.length > 15
                                    ? label.substring(0, 15) + '...'
                                    : label;
                            const fontSize = 10 / globalScale;
                            ctx.font = `${fontSize}px Sans-Serif`;
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillStyle = 'white';
                            ctx.fillText(
                                label,
                                node.x,
                                node.y - NODE_R - fontSize
                            );
                        } catch (err) {
                            // console.log(err);
                        }
                    }}
                    onNodeHover={handleNodeHover}
                    onNodeClick={handleNodeClick}
                    onLinkClick={(link, event) => {
                        if (graphEditLoading) return;
                        if (editMode && !addEdgeMode) {
                            if (canDeleteEdge(link.source.id, link.target.id)) {
                                dispatch(
                                    addEdgeToDelete({
                                        source: link.source.id,
                                        target: link.target.id,
                                        id: link.id
                                    })
                                );
                            }
                        }
                    }}
                    onLinkHover={(link, prevLink) => {
                        if (graphEditLoading) return;
                        if (!addEdgeMode) highlightLink(link);
                    }}
                    nodeRelSize={10}
                    nodeId="id"
                    nodeLabel="path"
                    nodeAutoColorBy="module"
                    linkCurvature="curvature"
                    d3VelocityDecay={0.1}
                    cooldownTicks={100}
                    onEngineStop={async () => {
                        await new Promise(resolve => setTimeout(resolve, 50));
                        setGraphHeight(window.innerHeight - getDiffHeight());
                        setOriginalHeight(getDiffHeight());
                        fgRef?.current?.zoomToFit(500, 40);
                        setEngineStopped(true);
                    }}
                    autoPauseRedraw={false}
                    linkWidth={link =>
                        highlightLinks && highlightLinks.includes(link.id)
                            ? 4
                            : 1
                    }
                    linkDirectionalParticles={4}
                    linkDirectionalParticleWidth={link =>
                        highlightLinks && highlightLinks.includes(link.id)
                            ? 7
                            : 2
                    }
                    nodeCanvasObjectMode={node =>
                        highlightNodes && highlightNodes.includes(node.id)
                            ? 'before'
                            : 'replace'
                    }
                />
            ) : (
                <div />
            )}
        </div>
    );
};
