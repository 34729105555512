import { useDispatch } from 'react-redux';
import { client } from '../../../../api/client';
import {
    INITIATE_SUGGESTION,
    SUGGESTION_SUBSCRIPTION
} from '../../../../api/graphQL';
import { waitingForSuggestion } from '../../../../redux/reducers/generic/chatHistory';
import { Spinner } from '../../../Components/Spinner';
import { useSubscription } from '@apollo/client';
import { useProject } from '../../../../context-providers/Project';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'sonner';
import { useLocation } from 'react-router-dom';

export const Suggest = ({ textareaRef, setAnswer, answer, isShare }) => {
    let { chatHistory, projectId } = useProject();
    let chat = chatHistory.data;
    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;

    let [randId, setRandId] = useState('');
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const soloNoGo = isShare && chat.length > 10;

    useSubscription(SUGGESTION_SUBSCRIPTION, {
        variables: { projectId, randId },
        shouldResubscribe: true,
        onData: data => {
            try {
                const { suggestion, completed } =
                    data.data.data.suggestionStream;
                setAnswer(suggestion);
                textareaRef.current.scrollTop =
                    textareaRef.current.scrollHeight + 20;
                textareaRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                });

                if (completed) {
                    document.getElementById('answer').focus();
                    textareaRef.current.value += ' ';
                    textareaRef.current.style.overflowY = 'scroll';
                    dispatch(waitingForSuggestion(false));
                }
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
        // client: clientWithAPIKey(apiKey),
    });

    const handleSuggest = async () => {
        if (waiting) return;
        if (isShare) {
            //setShowDemoBanner(true);

            if (pathname.includes('share')) {
                toast.error(
                    'Log in and upgrade to premium for unlimited access.'
                );
                return;
            }
            if (pathname.includes('demo')) {
                if (chat.length > 10) {
                    toast.error('Log in to continue chatting.');
                    return;
                }
            }
        }

        if (soloNoGo) {
            toast.warning(
                'Log in and upgrade to premium for unlimited access.'
            );
            return;
        }

        dispatch(waitingForSuggestion(true));
        try {
            setAnswer('');
            let randId = uuid();
            setRandId(randId);
            let data = await client.mutate({
                mutation: INITIATE_SUGGESTION,
                variables: { projectId, randId }
            });
            let suggestion = data.data.initiateSuggestionStream.suggestion;

            if (answer !== suggestion) setAnswer(suggestion);
            dispatch(waitingForSuggestion(false));
        } catch (error) {
            console.log(error);
            dispatch(waitingForSuggestion(false));
        }
        return;
    };

    return (
        <button
            onClick={!waiting ? handleSuggest : null}
            className={`${
                waiting
                    ? 'text-gray-500'
                    : 'text-green-500 hover:text-green-600'
            }
                 rounded-lg bg-slate-200 p-2 hover:bg-blue-600 hover:text-slate-200 dark:bg-slate-800 dark:hover:bg-blue-600 dark:hover:text-slate-50
                 `}
        >
            {chatHistory.waitingForSuggestion ? (
                <span className="flex items-center">
                    <Spinner w="4" mr="2" />
                    Answering...
                </span>
            ) : (
                'Answer for me'
            )}
        </button>
    );
};
