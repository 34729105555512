import {
    INITIATE_FOLLOW_UP_QUESTION_UIVIEW
} from '../../../api/graphQL';
import { client, clientWithAPIKey } from '../../../api/client';
import { addToChatHistoryUIView } from './addToChatHistoryUIView';
import {
    fetchChatRequest,
    fetchChatFailure,
    waitingForChatResponse
} from '../../reducers/generic/uiview';

// Initializes the chathistory directly and then disables text input
export const handleChatSubmitUIView =
    ({
        answer,
        image_url,
        fileKeyS3,
        nodeId,
        projectId,
        userEmail,
        username,
        isWidget,
        isEnterprise
    }) =>
    async (dispatch, getState) => {
        let index = getState().uiview.data.length;
        let answerObj = createAnswer({
            answer,
            username,
            image_url,
            fileKeyS3
        });

        dispatch(waitingForChatResponse(true));

        const userTitle = getState()?.enterpriseUsers?.data?.find(
            user => user.userEmail === userEmail
        )?.title;

        // add latest answer to chat immediately
        dispatch(
            addToChatHistoryUIView({ ...answerObj, userTitle, index, userEmail })
        );

        // then we are going to make the api request with the updated chathistory
        dispatch(fetchChatRequest());

        try {
            let chat;
  
            let { data } = await client.mutate({
                mutation: INITIATE_FOLLOW_UP_QUESTION_UIVIEW,
                variables: { answer: answerObj, projectId: projectId, nodeId: nodeId }
            });
            chat = data.initiateFollowUpQuestionUIViewStream;

            if (getState().project.projectId != projectId) return;

            dispatch(addToChatHistoryUIView(chat, true));
            dispatch(waitingForChatResponse(false));

            return true;
        } catch (error) {
            if (getState().project.projectId != projectId) return;
            console.log({ error });
            if (error.message.includes(`API is temporarily unavailable.`)) {
                alert(
                    `API is temporarily unavailable. Please try again later.`
                );
            } else alert(error.message);
            dispatch(fetchChatFailure(error));
            dispatch(waitingForChatResponse(false));

            return false;
        }
    };

function createAnswer({ answer, username, image_url, fileKeyS3 }) {
    return {
        content: answer,
        role: 'user',
        datetime: new Date().toISOString(),
        username,
        image_url,
        fileKeyS3
    };
}
