import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    jiraProjectURL: null,
    jiraVersion: null,
    jiraPercentage: 0,
    gettingJira: false,
    callingJira: false,
    loadingJiraHackathon: null,
    loadingJiraMVP: null,
    loadingJiraFull: null
};

export const jiraSlice = createSlice({
    name: 'jira',
    initialState,
    reducers: {
        setJira: (state, action) => {
            if (!action.payload) {
                state.jiraVersion = null;
                state.jiraPercentage = 0;
                state.jiraProjectURL = null;
            } else {
                state.jiraVersion = action.payload.version;
                state.jiraProjectURL = action.payload.projectURL;
                state.jiraPercentage = action.payload.percentage;
            }
        },
        gettingJira: (state, action) => {
            state.gettingJira = action.payload;
        },
        callingJira: (state, action) => {
            state.callingJira = action.payload;
        },
        setJiraPercentage: (state, action) => {
            state.jiraPercentage = action.payload;
        },
        waitingForJiraHackathon: (state, action) => {
            state.loadingJiraHackathon = action.payload;
        },
        waitingForJiraMVP: (state, action) => {
            state.loadingJiraMVP = action.payload;
        },
        waitingForJiraFull: (state, action) => {
            state.loadingJiraFull = action.payload;
        }
    }
});

export const {
    setJira,
    setJiraPercentage,
    gettingJira,
    callingJira,
    waitingForJiraHackathon,
    waitingForJiraMVP,
    waitingForJiraFull
} = jiraSlice.actions;

export const selectJiraPercentage = state => state.jira.jiraPercentage;
export const selectJiraVersion = state => state.jira.jiraVersion;
export const selectGettingJira = state => state.jira.gettingJira;
export const selectCallingJira = state => state.jira.callingJira;
export const selectJiraProjectURL = state => state.jira.jiraProjectURL;
export const selectLoadingJiraHackathon = state =>
    state.jira.loadingJiraHackathon;
export const selectLoadingJiraMVP = state => state.jira.loadingJiraMVP;
export const selectLoadingJiraFull = state => state.jira.loadingJiraFull;

export default jiraSlice.reducer;
