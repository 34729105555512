import {
    selectSubscribeModal,
    toggleSubscribeModal
} from '../../../redux/reducers/generic/billing';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../Components/Modal';
import PricingSection from '../../Pricing/pricing-section';
import { getSoloSubscriptionLinks } from '../../../redux/actions/solo/getSoloStripeLink';

export const SubscribeModal = () => {
    const open = useSelector(selectSubscribeModal);
    const dispatch = useDispatch();
    const [subscriptionLinks, setSubscriptionLinks] = useState(null);
    async function getSubscriptionLinks() {
        let links = await dispatch(getSoloSubscriptionLinks());
        const filteredLinks = links.filter(link => link.trial === false);
        setSubscriptionLinks(filteredLinks);
    }
    useEffect(() => {
        getSubscriptionLinks();
    }, []);

    return (
        <Modal
            open={open}
            onClose={() => dispatch(toggleSubscribeModal())}
            maxWidth="max-w-6xl dark"
            bgColor="bg-black"
        >
            <PricingSection
                subscriptionLinks={subscriptionLinks}
                type={'solo'}
            />
        </Modal>
    );
};
