import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../context-providers/Project';
import {
    clearNodesLinks,
    highlightNodesLinks
} from '../../../redux/actions/solo/highlightNodesLinks';
import { CSVLink } from 'react-csv'; // Import CSVLink for CSV export
import { capitalize } from '../../../constants';
import React from 'react';

export const ArchitectureTable = () => {
    const { allSearchParams, setSearchParams } = useProject();
    const dispatch = useDispatch();
    let { projectArchitecture } = useProject();
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (projectArchitecture?.graphs) {
            let tableData = [];

            // Add a row for each group
            for (let graph of projectArchitecture.graphs) {
                tableData.push({
                    component: capitalize(graph.side),
                    group: capitalize(graph.side)
                });

                graph.nodes.forEach(node => {
                    tableData.push({
                        component:
                            node.label ||
                            node.id.toUpperCase() ||
                            'Unnamed Component',
                        description: node.description,
                        group: capitalize(graph.side)
                    });
                });
            }

            // Add a row for the totals
            tableData.push({
                component: 'Total',
                group: ''
            });

            setTableData(tableData);
        }
    }, [projectArchitecture]);

    // CSV export functionality
    const headers = [
        { label: 'Work Component', key: 'component' },
        { label: 'Group', key: 'group' },
        { label: 'Description', key: 'description' }
    ];

    const csvReport = {
        filename: 'predev_work.csv',
        headers: headers,
        data: Array.isArray(tableData) ? tableData : []
    };

    const handleHover = (node, side) => {
        try {
            setSearchParams({
                ...allSearchParams,
                graphType: side
            });
            if (node) dispatch(highlightNodesLinks(node));
        } catch (err) {
            console.log(err);
        }
    };

    const handleHoverClear = () => {
        try {
            dispatch(clearNodesLinks());
        } catch (err) {
            console.log(err);
        }
    };

    const hasNodes = projectArchitecture?.graphs?.some(
        graph => graph.nodes && graph.nodes.length > 0
    );

    return hasNodes ? (
        <div className="md:mx-4 h-full mt-8 flex-grow mb-8 max-w-lg">
            {/* Add CSV export button */}
            <div className="rounded-md border border-gray-100">
                <table className="w-full text-xs md:text-sm text-left text-gray-500 h-full">
                    <thead className="rounded-md text-xs sticky top-0 pt-1 text-gray-700 bg-gray-200">
                        <tr className="uppercase">
                            <th
                                scope="col"
                                className="px-6 py-3 w-full flex gap-x-2 justify-between items-center font-medium text-gray-900 whitespace-nowrap"
                            >
                                Work Component
                                <button className="text-white text-sm md:text-sm bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center">
                                    <CSVLink {...csvReport}>Export CSV</CSVLink>{' '}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="sm:rounded-lg dark mx-auto my-4">
                        {projectArchitecture?.graphs?.map(
                            (graph, graphIndex) => (
                                <React.Fragment key={`graph-${graphIndex}`}>
                                    <tr className="bg-slate-50">
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                        >
                                            {capitalize(graph.side)}
                                        </th>
                                    </tr>

                                    {graph?.nodes?.map((node, nodeIndex) => (
                                        <tr
                                            key={`node-${graphIndex}-${nodeIndex}`}
                                            onMouseOver={() =>
                                                handleHover(node, graph.side)
                                            }
                                            onMouseLeave={handleHoverClear}
                                            id={node.id}
                                            className="bg-white hover:bg-gray-50"
                                        >
                                            <td
                                                scope="row"
                                                className="px-6 py-4 flex flex-col"
                                            >
                                                <span>{node.label}</span>
                                                <span className="text-2xs">
                                                    {node.description}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    ) : (
        <></>
    );
};
