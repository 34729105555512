import React from 'react';
import { Button } from '../Components/magicui/ui/button';
import { useDispatch } from 'react-redux';
import { toggleSubscribeModal } from '../../redux/reducers/generic/billing';
import { getWebsiteDomain } from '../../auth_config';
import { useNavigate } from 'react-router-dom';
import PricingSection from '../Pricing/pricing-section';
import { getSoloSubscriptionLinks } from '../../redux/actions/solo/getSoloStripeLink';
import { useEffect, useState } from 'react';
import { useEnterpriseOrganization } from '../../context-providers/EnterpriseOrganization';

const LockDock = ({ name, param, isEnterprise, isShare, isSolo }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toggleSubscriptionModal = () => {
        if (isShare) {
            navigate('/auth');
        } else {
            dispatch(toggleSubscribeModal(true));
        }
    };

    const { enterpriseOrganization } = useEnterpriseOrganization();
    let enterpriseSubscriptionLinks = enterpriseOrganization?.subscriptionLinks;

    const baseURL = getWebsiteDomain().includes('localhost')
        ? 'http://localhost:3000/share/66a00335ff2e588d2d830aa1'
        : 'https://pre.dev/share/6e4b9523-bb22-4762-b1fd-1e414f1c27f2/668ee8853309a805d766de43';

    const URL = `${baseURL}?pageType=${param}`;

    const [subscriptionLinks, setSubscriptionLinks] = useState(null);
    async function getSubscriptionLinks() {
        let links = await dispatch(getSoloSubscriptionLinks());
        const filteredLinks = links.filter(link => link.trial === false);
        setSubscriptionLinks(filteredLinks);
    }
    useEffect(() => {
        if (isSolo) getSubscriptionLinks();
        if (isEnterprise) setSubscriptionLinks(enterpriseSubscriptionLinks);
    }, [isSolo, enterpriseSubscriptionLinks]);

    return (
        <div className="h-full overflow-y-auto">
            <div className="overflow-y bg-gradient-to-r from-indigo-500 to-purple-600 text-white p-8 rounded-lg shadow-lg max-w-xl mx-auto my-2">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold">
                        Upgrade To Access Your {name}
                    </h2>
                    <img
                        src="/predev.png"
                        alt="pre.dev logo"
                        className="h-10 w-auto"
                    />
                </div>
                <p className="text-md mb-4">
                    To view your {name}, you'll need to upgrade your pre.dev
                    account.
                </p>
                <div className="flex justify-between items-center mb-4">
                    <p className="text-xl font-extrabold">
                        {isEnterprise ? (
                            <span>
                                Upgrade starting at just{' '}
                                <span className="text-xl text-yellow-300 inline-block transform hover:scale-110 transition-transform duration-200">
                                    $99/month
                                </span>
                            </span>
                        ) : (
                            <span>
                                Upgrade starting at just{' '}
                                <span className="text-xl text-yellow-300 inline-block transform hover:scale-110 transition-transform duration-200">
                                    $14/month
                                </span>
                            </span>
                        )}
                    </p>
                    <Button
                        onClick={toggleSubscriptionModal}
                        className="bg-white text-purple-600 hover:bg-gray-100 font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                    >
                        Upgrade Now
                    </Button>
                </div>
                <div className="pt-2 border-t border-purple-400">
                    <p className="text-lg mb-4">
                        Here's an example of what you'll get with pre.dev:
                    </p>
                    <div className="w-full bg-white rounded-lg overflow-hidden">
                        <a
                            href={URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-full py-4 px-6 text-center text-purple-600 hover:bg-purple-100 transition duration-300 ease-in-out"
                        >
                            View {name} Example on pre.dev
                        </a>
                    </div>
                </div>
            </div>
            <div className="dark">
                <PricingSection
                    type={isSolo ? 'solo' : isEnterprise ? 'enterprise' : null}
                    onlyCards={true}
                    halfPage={true}
                    subscriptionLinks={subscriptionLinks}
                />
            </div>
        </div>
    );
};

export default LockDock;
