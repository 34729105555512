import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { ArrowDownIcon } from '@heroicons/react/24/outline';
import Widget from '../WidgetPages/ChatWidgetPage/Widget';
import { GlobeAltIcon, LightBulbIcon } from '@heroicons/react/24/solid';
import { salesCalendlyLink } from '../../constants';
let apiKey = process.env.REACT_APP_LANDING_PAGE_WIDGET_API_KEY;

const particleOptions = {
    particles: {
        number: {
            value: 80,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: ['#ffffff', '#ff9900', '#00ff00']
        },

        shape: {
            type: 'circle'
        },
        opacity: {
            value: 0.5,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.1,
                sync: false
            }
        },
        size: {
            value: 3,
            random: true,
            animation: {
                enable: false,
                speed: 20,
                minimumValue: 0.1,
                sync: false
            }
        },
        lineLinked: {
            enable: true,
            distance: 150,
            color: '#ffffff',
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            speed: 0.5,
            direction: 'none',
            random: false,
            straight: false,
            outModes: {
                default: 'out'
            },
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    detectRetina: true
};

export const HeroSection = ({ onGetStarted }) => {
    const navigate = useNavigate();
    const particlesInit = useCallback(async engine => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    const particlesLoaded = useCallback(async container => {
        // await console.log(container);
    }, []);
    return (
        <div className="hero-section">
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={particleOptions}
            />
            <main className="max-w-screen-xl min-w-0 flex-auto divide-y dark:divide-gray-700 lg:static lg:max-h-full lg:overflow-visible">
                <section className="mb-8 pb-16 mx-auto flex max-w-8xl flex-col overflow-hidden sm:pb-10 lg:px-20 lg:pb-24 lg:pt-16">
                    <div className="flex flex-col lg:flex-row mt-32 lg:mt-0 gap-10 items-center justify-center">
                        <div className="flex items-center gap-10 px-10">
                            <div className="flex max-w-4xl flex-col justify-start gap-4">
                                <div className="flex flex-col gap-4 text-left lg:gap-6">
                                    <a
                                        href="https://www.producthunt.com/posts/pre-dev?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-pre&#0045;dev"
                                        target="_blank"
                                        className="mt-2 md:mt-0 w-fit"
                                    >
                                        <img
                                            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=427973&theme=light&period=daily"
                                            alt="pre&#0046;dev - Instant&#0032;software&#0032;planning&#0032;and&#0032;lightspeed&#0032;development | Product Hunt"
                                            style={{
                                                width: '250px',
                                                height: '54px'
                                            }}
                                            width="250"
                                            height="54"
                                        />
                                    </a>
                                    <h1 className="max-w-xl text-5xl xl:max-w-4xl xl:text-7xl font-extrabold leading-none text-white lg:text-6xl">
                                        <span className="xl:inline w-full">
                                            <span className="text-blue-400 xl:inline">
                                                Copilot{' '}
                                            </span>
                                            for{' '}
                                            <span className="text-blue-400 xl:inline">
                                                Product
                                            </span>{' '}
                                            <span className="text-green-400 xl:inline">
                                                Teams
                                            </span>
                                        </span>
                                        {/*	<span className="xl:inline"> to </span>
										<span className="ml-2 text-blue-500 xl:inline">reality</span>*/}
                                    </h1>
                                    <p className="font-light max-w-xl text-lg leading-normal text-gray-300 lg:text-xl xl:text-2xl">
                                        <LightBulbIcon
                                            className="h-5 w-5 text-yellow-400 inline mr-2"
                                            aria-hidden="true"
                                        />
                                        Allow your team to collaborate with the
                                        pre.dev AI to turn ideas into production
                                        scale project plans in real-time.
                                        <hr className="my-2" />
                                        <GlobeAltIcon
                                            className="h-5 w-5 text-teal-400 inline mr-2"
                                            aria-hidden="true"
                                        />
                                        Embed the pre.dev widget on your site
                                        and save weeks on client product
                                        requirement discovery.{' '}
                                    </p>

                                    <div className="mt-3 cursor-pointer max-w-xl text-lg leading-normal text-gray-400 lg:text-xl">
                                        <a
                                            onClick={onGetStarted}
                                            className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-900"
                                        >
                                            Get started
                                            <svg
                                                className="w-5 h-5 ml-2 -mr-1"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={salesCalendlyLink}
                                            className="mt-4 inline-flex items-center justify-center px-5 py-3 font-medium text-center border rounded-lg focus:ring-4 text-white border-gray-700 hover:bg-gray-700 focus:ring-gray-800"
                                        >
                                            Request Demo
                                        </a>
                                        <div className="testimonial-section bg-gray-800 p-6 rounded-lg shadow-lg mt-4">
                                            <p className="text-lg italic text-gray-300">
                                                "I was quoted 120 hours to scope
                                                out a million dollar project and
                                                instead{' '}
                                                <b>
                                                    pre.dev gave it to me
                                                    instantly.
                                                </b>
                                                "
                                            </p>
                                            <p className="mt-1 text-sm text-right text-gray-400">
                                                - Bryce Kaiser of City
                                                Innovations - Venture Studio
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[420px] w-full flex flex-col items-center mt-16">
                            <h1 className="text-2xl font-extrabold text-center text-white">
                                Try our Copilot for Product.
                            </h1>
                            <ArrowDownIcon className="h-8 w-5 text-white mb-2" />
                            <div className="h-[590px] max-w-[420px] w-full rounded-lg shadow-xl">
                                <Widget apiKeySub={apiKey} />
                            </div>
                            <h1 className="text-md mt-4 font-extrabold text-center text-white">
                                Embed this widget in your website today,{' '}
                                <span
                                    onClick={onGetStarted}
                                    className="text-blue-500 underline cursor-pointer"
                                >
                                    sign up for free.
                                </span>
                            </h1>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};
