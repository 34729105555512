import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Spinner } from '../Components/Spinner';
import { sendPasswordResetEmail } from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { toast } from 'sonner';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const Forgot = () => {
    // State variables for form fields
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        if (email.length < 1) {
            toast('Please enter your email.');
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        try {
            let response = await sendPasswordResetEmail({
                formFields: [
                    {
                        id: 'email',
                        value: email
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
                // one of the input formFields failed validaiton
                response.formFields.forEach(formField => {
                    if (formField.id === 'email') {
                        // Email validation failed (for example incorrect email syntax).
                        toast(formField.error);
                    }
                });
            } else {
                // reset password email sent.
                setIsSent(true);
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                toast(err.message);
            } else {
                toast('Oops! Something went wrong.');
            }
            console.log({ err });
        }
        setIsLoading(false);
    };
    return (
        <div className="light">
            <section className="pt-8 pb-8 bg-gray-50 dark:bg-gray-900 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="ml-4 mt-4">
                            <Link to="/auth/">
                                <ArrowLeftIcon className="" width={20} />
                            </Link>
                        </div>
                        <div className="px-4 space-y-4 md:space-y-6 pb-4">
                            <a
                                href="#"
                                className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
                            >
                                <img
                                    className="h-8 rounded p-1 mr-2 bg-black"
                                    src="/predev.png"
                                    alt="logo"
                                />
                                pre.dev
                            </a>

                            <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Copilot for Product
                            </h1>

                            <h2 className="text-center text-xl font-semibold tracking-tight text-gray-600 dark:text-gray-400">
                                Reset your password
                            </h2>

                            <form
                                onSubmit={handleSubmit}
                                className="items-center space-y-4 md:space-y-6"
                                action="#"
                            >
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Your email
                                    </label>
                                    <input
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        name="email"
                                        id="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="name@company.com"
                                        required=""
                                    />
                                </div>
                                {isSent ? (
                                    <p className="text-sm text-black">
                                        Please check your email for the password
                                        reset link!
                                    </p>
                                ) : (
                                    <button
                                        type="submit"
                                        className={`w-full text-white ${
                                            isLoading
                                                ? 'bg-gray-400'
                                                : 'bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300'
                                        } font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <Spinner w={'4'} />
                                        ) : (
                                            'Receive reset link'
                                        )}
                                    </button>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
