import { ChevronLeftIcon } from '@heroicons/react/24/outline';

export const FullScreenButton = ({ fullScreen, setFullScreen }) => {
    return (
        <div className="flex justify-end -mt-[30px]">
            <div className="w-fit mt-1 mr-1 z-10">
                {fullScreen ? (
                    <ChevronLeftIcon
                        onClick={() => setFullScreen(false)}
                        style={{ zIndex: 10 }}
                        className="z-10 hidden -mt-8 md:block cursor-pointer h-6 w-6 z-10 text-slate-50 hover:text-gray-300"
                    />
                ) : (
                    <div
                        onClick={() => setFullScreen(true)}
                        style={{ zIndex: 10 }}
                        className="z-10 text-white hidden md:block cursor-pointer h-6 w-6 z-10 text-slate-50 hover:text-gray-300"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#fff"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};
