import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { UserAuthForm } from './components/Auth/user-auth-form';
import { ProjectDashboard } from './components/ProjectDashboard';
import ChatWidgetPage from './components/WidgetPages/ChatWidgetPage';

import Home from './components/Home';
import { LandingPage } from './components/Landing';
import { EnterpriseLanding } from './components/EnterpriseLanding';
import { ApolloProvider } from '@apollo/client';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { ThirdpartyEmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { client } from './api/client';
import { EmailPasswordHeadersOverride, SuperTokensConfig } from './auth_config';
import { Forgot } from './components/Auth/forgot';
import { Reset } from './components/Auth/reset';
import { ProjectProvider } from './context-providers/Project';
import { ProjectsProvider } from './context-providers/Projects';
import { LoadingProvider } from './context-providers/Loading';
import { SubscriptionPaymentProvider } from './context-providers/SubscriptionPayment';
import { NewProject } from './components/Home/NewProject';
import { GraphProvider } from './context-providers/Graph';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
// import { Passport } from "./components/Passport";
import EnterpriseHome from './components/EnterpriseHome';
import { EnterpriseOrganizationsProvider } from './context-providers/EnterpriseOrganizations';
import { EnterpriseOrganizationProvider } from './context-providers/EnterpriseOrganization';
import NewOrganization from './components/EnterpriseHome/NewOrganization';
import { ClientProjects } from './components/EnterpriseHome/ClientProjects';
import { WidgetManagement } from './components/EnterpriseHome/WidgetManagement';
import { EnterpriseUsers } from './components/EnterpriseHome/EnterpriseUsers';
import { Footer } from './components/Footer';
import Widget from './components/WidgetPages/ChatWidgetPage/Widget';
import FullWidget from './components/WidgetPages/FullWidgetPage';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { PreBuiltUIList } from './auth_config';
import CreateNewProject from './components/CreateNewProject';
import { v4 as uuidv4 } from 'uuid';
import MainHeader from './components/WidgetPages/ChatWidgetPage/Widget/MainHeader';
import { useEnterpriseOrganization } from './context-providers/EnterpriseOrganization';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import posthog from 'posthog-js';
import { Toaster } from 'sonner';
import { Loading } from './components/Components/Loading';
import { KnowledgeManagement } from './components/EnterpriseHome/KnowledgeManagement';
import { Header } from './components/WidgetPages/Header';

SuperTokens.init(SuperTokensConfig);

export const SessionWrapper = ({ children }) => {
    const sessionContext = useSessionContext();
    useEffect(() => {
        posthog.init('phc_8ayngDr5zFcIOTbulDvje0PNMDwRrDHvKW2Patg4hmJ', {
            api_host: 'https://app.posthog.com'
        });
    }, []);

    useEffect(() => {
        if (sessionContext?.accessTokenPayload?.email) {
            posthog.identify(
                sessionContext.userId,
                { email: sessionContext.accessTokenPayload.email } // optional: set additional user properties
            );
        }
    }, [sessionContext.accessTokenPayload]);

    return sessionContext.loading ? <Loading text="" /> : <>{children}</>;
};

const EnterpriseWrapper = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    return !enterpriseOrganization ? (
        <Loading text="Organization" />
    ) : (
        <Outlet />
    );
};

function App() {
    return (
        <SuperTokensWrapper>
            <Toaster position="top-center" />
            <ThirdpartyEmailPasswordComponentsOverrideProvider
                components={{
                    EmailPasswordSignInHeader_Override:
                        EmailPasswordHeadersOverride,
                    EmailPasswordSignUpHeader_Override:
                        EmailPasswordHeadersOverride
                }}
            >
                <ApolloProvider client={client}>
                    <Router>
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(
                                require('react-router-dom'),
                                PreBuiltUIList
                            )}
                            <Route path="/auth/*" element={<UserAuthForm />} />
                            <Route
                                path="/auth/reset-password"
                                element={<Reset />}
                            />
                            <Route
                                path="/auth/forgot-password"
                                element={<Forgot />}
                            />

                            <Route
                                path="/"
                                element={
                                    <EnterpriseOrganizationsProvider>
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isShare={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <LandingPage />
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    </EnterpriseOrganizationsProvider>
                                }
                            >
                                <Route path="demo/:projectId" />
                                <Route path="how-it-works" />
                            </Route>

                            <Route
                                path="/enterprise"
                                element={
                                    <SessionWrapper>
                                        <SubscriptionPaymentProvider>
                                            <ProjectProvider
                                                isChatWidget={true}
                                            >
                                                <LoadingProvider>
                                                    <EnterpriseLanding />
                                                </LoadingProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </SessionWrapper>
                                }
                            />

                            <Route
                                path="/iframe/enterprise/chat/:apiKey"
                                element={
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider>
                                            <ProjectProvider
                                                isChatWidget={true}
                                            >
                                                <LoadingProvider>
                                                    <Widget />
                                                </LoadingProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                }
                            />

                            <Route
                                path="/iframe/enterprise/full/:apiKey"
                                element={
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider>
                                            <ProjectProvider
                                                isFullWidget={true}
                                            >
                                                <GraphProvider>
                                                    <LoadingProvider>
                                                        <FullWidget />
                                                    </LoadingProvider>
                                                </GraphProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                }
                            >
                                <Route path=":uniqueId" />
                            </Route>

                            <Route
                                path="/enterprise/chat/:apiKey"
                                element={
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider>
                                            <ProjectProvider
                                                isChatWidget={true}
                                            >
                                                <LoadingProvider>
                                                    <ChatWidgetPage />
                                                </LoadingProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                }
                            />

                            <Route
                                path="/share/enterprise/:enterpriseOrganizationId/:projectId"
                                element={
                                    <SessionWrapper>
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isEnterprise={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <div className="flex flex-col h-[100vh] w-full">
                                                                <ProjectDashboard />
                                                            </div>
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    </SessionWrapper>
                                }
                            />

                            <Route
                                path="/share/:apiKey/:projectId"
                                element={
                                    <SessionWrapper>
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isShare={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <div className="flex flex-col h-[100vh] w-full">
                                                                <ProjectDashboard />
                                                            </div>
                                                            <Footer />
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    </SessionWrapper>
                                }
                            />

                            <Route
                                path="/share/:projectId"
                                element={
                                    <SessionWrapper>
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isShare={true}
                                            >
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <ProjectDashboard />
                                                            <Footer />
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    </SessionWrapper>
                                }
                            />

                            <Route
                                path="/enterprise/dashboard"
                                element={
                                    <SessionAuth>
                                        <EnterpriseOrganizationsProvider>
                                            <EnterpriseOrganizationProvider>
                                                <SubscriptionPaymentProvider
                                                    isEnterprise={true}
                                                >
                                                    <ProjectsProvider>
                                                        <ProjectProvider>
                                                            <GraphProvider>
                                                                <LoadingProvider>
                                                                    <EnterpriseHome />
                                                                </LoadingProvider>
                                                            </GraphProvider>
                                                        </ProjectProvider>
                                                    </ProjectsProvider>
                                                </SubscriptionPaymentProvider>
                                            </EnterpriseOrganizationProvider>
                                        </EnterpriseOrganizationsProvider>
                                    </SessionAuth>
                                }
                            >
                                <Route
                                    index
                                    path="new"
                                    element={<NewOrganization />}
                                />

                                <Route
                                    path=":enterpriseOrganizationId"
                                    element={<EnterpriseWrapper />}
                                >
                                    <Route
                                        path="new-project/:apiKey"
                                        element={
                                            <div
                                                className={`h-full flex flex-col justify-between items-center w-full`}
                                            >
                                                <div className="flex justify-center items-center h-full w-full">
                                                    <section
                                                        className={`h-[590px] md:border-gray-200 md:border rounded-lg md:shadow-lg object-cover mx-auto max-w-[440px] bg-white flex-grow flex flex-col justify-between`}
                                                    >
                                                        <CreateNewProject
                                                            uniqueId={uuidv4()}
                                                            createNewId={true}
                                                            isEnterprise={true}
                                                        />
                                                        <MainHeader />
                                                    </section>
                                                </div>
                                            </div>
                                        }
                                    ></Route>

                                    <Route
                                        path="widgetManagement"
                                        element={<WidgetManagement />}
                                    />

                                    <Route
                                        path="knowledgeManagement"
                                        element={<KnowledgeManagement />}
                                    />

                                    <Route path="clientProjects">
                                        <Route
                                            index
                                            element={<ClientProjects />}
                                        />

                                        <Route
                                            path=":projectId"
                                            element={
                                                <ProjectDashboard
                                                    isEnterprise={true}
                                                />
                                            }
                                        />
                                    </Route>

                                    <Route
                                        path="enterpriseUsers"
                                        element={<EnterpriseUsers />}
                                    />
                                </Route>
                            </Route>

                            <Route
                                path="/projects"
                                element={
                                    <SessionAuth>
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isSolo={true}
                                            >
                                                <ProjectsProvider>
                                                    <ProjectProvider>
                                                        <GraphProvider>
                                                            <LoadingProvider>
                                                                <Home />
                                                            </LoadingProvider>
                                                        </GraphProvider>
                                                    </ProjectProvider>
                                                </ProjectsProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    </SessionAuth>
                                }
                            >
                                <Route
                                    index
                                    path="new"
                                    element={<NewProject />}
                                />

                                <Route
                                    path=":projectId"
                                    element={<ProjectDashboard isSolo={true} />}
                                />
                            </Route>
                        </Routes>
                    </Router>
                </ApolloProvider>
            </ThirdpartyEmailPasswordComponentsOverrideProvider>
        </SuperTokensWrapper>
    );
}

export default App;
