import { Switch } from '@headlessui/react';

export const SwitchDefault = ({ enabled, setEnabled }) => {
    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? 'bg-green-600' : 'bg-red-500'}
          relative inline-flex h-[30px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
            <span
                aria-hidden="true"
                className={`${enabled ? 'translate-x-6' : 'translate-x-1'}
            translate-y-[3px] pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
        </Switch>
    );
};
