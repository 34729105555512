"use client";
import { cn } from "./lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { motion, useMotionValue, useSpring, useTransform, useAnimation } from "framer-motion";
import React, { PropsWithChildren, useRef, useState } from "react";
import ShineBorder from "./shine-border";
 
export interface DockProps extends VariantProps<typeof dockVariants> {
  className?: string;
  magnification?: number;
  distance?: number;
  children: React.ReactNode;
}
 
const DEFAULT_MAGNIFICATION = 50;
const DEFAULT_DISTANCE = 140;
 
const dockVariants = cva(
  "mx-auto w-max h-[58px] lg:mt-4 p-2 flex items-end gap-2 rounded-2xl border dark:border-[#707070]",
);
 
const Dock = React.forwardRef<HTMLDivElement, DockProps>(
  (
    {
      className,
      children,
      magnification = DEFAULT_MAGNIFICATION,
      distance = DEFAULT_DISTANCE,
      ...props
    },
    ref,
  ) => {
    const mouseX = useMotionValue(Infinity);
 
    const renderChildren = () => {
      return React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          mouseX: mouseX,
          magnification: magnification,
          distance: distance,
        });
      });
    };
 
    return (
      <motion.div
        ref={ref}
        onMouseMove={(e) => mouseX.set(e.pageX)}
        onMouseLeave={() => mouseX.set(Infinity)}
        {...props}
        className={cn(dockVariants({ className }), className)}
      >
        {renderChildren()}
      </motion.div>
    );
  },
);
 
Dock.displayName = "Dock";
 
export interface DockIconProps {
  size?: number;
  magnification?: number;
  distance?: number;
  mouseX?: any;
  className?: string;
  label?: string;
  selected?: boolean;
  iconSelect?:any;
  children?: React.ReactNode;
  props?: PropsWithChildren;
  loading?: boolean;
  description?: string;
}
const DockIcon = ({
  size,
  magnification = DEFAULT_MAGNIFICATION,
  distance = DEFAULT_DISTANCE,
  mouseX,
  iconSelect,
  className,
  selected,
  loading,
  label,
  children,
  description,
  ...props
}: DockIconProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const controls = useAnimation();
  
  const distanceCalc = useTransform(mouseX, (val: number) => {
    const bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };
    return val - bounds.x - bounds.width / 2;
  });

  let widthSync = useTransform(
    distanceCalc,
    [-distance, 0, distance],
    [40, magnification, 40],
  );

  let width = useSpring(widthSync, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  const [hover, setHover] = useState(false);

  const handleBounce = () => {
    controls.start({
      y: [0, -30, 0, -15, 0],
      transition: { duration: 0.5 }
    });
    iconSelect()
  };

  return (

    <div>

    <motion.div
      ref={ref}
      style={{ width }}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      onClick={handleBounce}
      className={cn(
        "flex aspect-square cursor-pointer items-center justify-center rounded-full",
        className,
       selected && "bg-neutral-400/40",
       !selected && "hover:bg-gray-800",
      )}
      {...props}
    >
      {hover && 
        <div className="absolute mt-2 top-[77px] z-10 w-fit max-w-[250px] bg-black text-white rounded border border-white">
          <div className="px-3 py-2">
            <div className="text-md font-bold">{label}</div>
            {description && (
              <>
                <hr className="my-2 border-gray-600" />
                <div className="text-sm overflow-hidden text-ellipsis">{description}</div>
              </>
            )}
          </div>
        </div>
      }
    <motion.div
      ref={ref}
      style={{ width }}
      onClick={handleBounce}
      animate={controls}
      {...props}
      className={cn(
        "flex aspect-square cursor-pointer items-center justify-center rounded-full",
        className,
      )}      
    >

      {children}
      </motion.div>
      {/* </ShineBorder> */}

    </motion.div>

    </div>
  );
};
 
DockIcon.displayName = "DockIcon";
 
export { Dock, DockIcon, dockVariants };