import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'sonner';
import {
    ArrowRightOnRectangleIcon,
    ArrowsUpDownIcon,
    ClipboardIcon,
    ShareIcon,
    TrashIcon
} from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import React, { useEffect } from 'react';
import moment from 'moment';
import { getWebsiteDomain } from '../../../auth_config';
import { useProjects } from '../../../context-providers/Projects';
import { deleteProject } from '../../../redux/actions/solo/deleteProject';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../Components/Spinner';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import {
    selectSearchLoading,
    setSort
} from '../../../redux/reducers/generic/projects';
import { TransferModal } from './TransferModal';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

const colors = [
    'bg-blue-500',
    'bg-green-500',
    'bg-yellow-300',
    'bg-orange-500',
    'bg-purple-600',
    'bg-red-500'
];

function getRandomColor(i) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const ProjectsList = ({}) => {
    const navigate = useNavigate();
    const { projects } = useProjects();
    const dispatch = useDispatch();
    const { enterpriseOrganization } = useEnterpriseOrganization();

    const { enterpriseOrganizationId } = useParams();
    const { isSubbed } = useSubscriptionPayment();

    let apiKey = enterpriseOrganization?.apiKey;

    if (!projects) projects = [];

    const [bgColors, setBgColors] = useState([]);
    const [showShareLink, setShowShareLink] = useState([]);
    //projects = proyectos
    useEffect(() => {
        if (projects.length > 0) {
            setBgColors(projects.map(() => getRandomColor()));
            setShowShareLink(projects.map(() => false));
        }
    }, [projects]);

    const copyToClipboard = text => e => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        toast.info('Share Link Copied!');
    };

    const searchLoading = useSelector(selectSearchLoading);

    const onClickProject = project => {
        navigate(project._id);
    };

    let [deleting, setDeleting] = useState(false);

    const onDeleteProject = async id => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            setDeleting(true);
            await dispatch(deleteProject(id));
            setDeleting(false);
            setShowShareLink(prevShowShareLink =>
                prevShowShareLink.map(() => false)
            );
        }
    };
    const [sortOrder, setSortOrder] = useState(-1);
    const [lastUpdatedOrder, setLastUpdatedOrder] = useState(1);
    const [messageCountOrder, setMessageCountOrder] = useState(1);

    const onCreatedSortClick = () => {
        const newSortOrder = sortOrder * -1;
        setSortOrder(newSortOrder);
        dispatch(setSort({ field: 'created', order: newSortOrder }));
    };
    const onLastUpdatedSortClick = () => {
        const newSortOrder = lastUpdatedOrder * -1;
        setLastUpdatedOrder(newSortOrder);
        dispatch(setSort({ field: 'updated', order: newSortOrder }));
    };

    const onMessageCountSortClick = () => {
        const newSortOrder = messageCountOrder * -1;
        setMessageCountOrder(newSortOrder);
        dispatch(setSort({ field: 'messageCount', order: newSortOrder }));
    };

    const [open, setOpen] = useState(false);
    const [project, setProject] = useState(null);

    const onTransferClick = async id => {
        setProject(id);
        setOpen(true);
    };

    let shareLink = project =>
        isSubbed
            ? `${getWebsiteDomain()}/share/enterprise/${enterpriseOrganizationId}/${
                  project._id
              }`
            : `${getWebsiteDomain()}/share/${apiKey}/${project._id}`;

    return (
        <>
            <TransferModal project={project} open={open} setOpen={setOpen} />
            {/* Projects list (only on smallest breakpoint) */}
            <div className="sm:hidden mt-4">
                <ul
                    role="list"
                    className={`divide-y divide-gray-100 border-t border-gray-200 ${
                        searchLoading ? 'opacity-50' : ''
                    }`}
                >
                    {projects.map((project, i) => (
                        <li
                            key={project._id}
                            onClick={() => onClickProject(project)}
                        >
                            <a className="cursor-pointer group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-2">
                                <span className="flex items-center space-x-3 truncate">
                                    <span
                                        className={classNames(
                                            bgColors && bgColors[i],
                                            'h-2.5 w-2.5 flex-shrink-0 rounded-full'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span
                                        onClick={() => onClickProject(project)}
                                        className="truncate text-sm font-medium leading-6"
                                    >
                                        {project.name}
                                    </span>
                                    <div className="text-sm flex flex-shrink-0 -space-x-1">
                                        {project.type}
                                    </div>
                                    <div className="text-xs flex flex-shrink-0 -space-x-1">
                                        {project.userEmail}
                                    </div>
                                </span>
                                <ChevronRightIcon
                                    className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Projects table (small breakpoint and up) */}
            <div className="mt-4 hidden sm:block">
                <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                    <table className="min-w-full">
                        <thead>
                            <tr className="border-t border-gray-200">
                                <th
                                    className="border-b border-gray-200 bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900"
                                    scope="col"
                                >
                                    <span className="lg:pl-2">Name</span>
                                </th>
                                <th
                                    className="border-b border-gray-200 bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900"
                                    scope="col"
                                >
                                    Email
                                </th>
                                <th
                                    className="cursor-pointer hidden border-b border-gray-200 bg-gray-50 px-2 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                                    scope="col"
                                    onClick={onCreatedSortClick}
                                >
                                    <div className="flex items-center justify-end gap-x-2 w-full">
                                        Created
                                        <ArrowsUpDownIcon
                                            className="h-5 w-5 hover:text-gray-500 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </th>
                                <th
                                    className="cursor-pointer hidden border-b border-gray-200 bg-gray-50 px-2 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                                    scope="col"
                                    onClick={onLastUpdatedSortClick}
                                >
                                    <div className="flex items-center justify-end gap-x-2 w-full">
                                        Updated
                                        <ArrowsUpDownIcon
                                            className="h-5 w-5 hover:text-gray-500 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </th>
                                <th
                                    className="cursor-pointer hidden border-b border-gray-200 bg-gray-50 px-2 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                                    scope="col"
                                    onClick={onMessageCountSortClick}
                                >
                                    <div className="flex items-center justify-end gap-x-2 w-full">
                                        Responses
                                        <ArrowsUpDownIcon
                                            className="h-5 w-5 hover:text-gray-500 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </th>

                                <th
                                    className="border-b border-gray-200 bg-gray-50 px-2 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                                    scope="col"
                                >
                                    Type
                                </th>
                                <th
                                    className="hidden border-b border-gray-200 bg-gray-50 px-2 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                                    scope="col"
                                >
                                    Creation
                                </th>
                                <th
                                    className="border-b border-gray-200 bg-gray-50 py-3 px-3 text-right text-sm font-semibold text-gray-900"
                                    scope="col"
                                />
                            </tr>
                        </thead>
                        <tbody
                            className={`divide-y divide-gray-100 bg-white ${
                                searchLoading ? 'opacity-50' : ''
                            }`}
                        >
                            {projects.map((project, i) => (
                                <tr
                                    className="cursor-pointer hover:bg-gray-50"
                                    key={project._id}
                                    onMouseEnter={() =>
                                        setShowShareLink(prevShowShareLink => {
                                            const newShowShareLink = [
                                                ...prevShowShareLink
                                            ];
                                            newShowShareLink[i] = true;
                                            return newShowShareLink;
                                        })
                                    }
                                    onMouseLeave={() =>
                                        setShowShareLink(prevShowShareLink => {
                                            const newShowShareLink = [
                                                ...prevShowShareLink
                                            ];
                                            newShowShareLink[i] = false;
                                            return newShowShareLink;
                                        })
                                    }
                                >
                                    <td
                                        className="whitespace-nowrap px-2 py-3 text-sm font-medium text-gray-900"
                                        onClick={() => onClickProject(project)}
                                    >
                                        <div className="flex items-center space-x-3 lg:pl-2">
                                            <div
                                                className={classNames(
                                                    bgColors && bgColors[i],
                                                    'h-2.5 w-2.5 flex-shrink-0 rounded-full'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <a
                                                style={{
                                                    maxWidth: '100px'
                                                }}
                                                className="truncate hover:text-gray-600
																			"
                                            >
                                                <span>{project.name}</span>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="px-2 py-3 text-sm font-medium text-gray-500">
                                        <div className="flex items-center space-x-2">
                                            <div
                                                className="flex gap-x-2"
                                                onClick={e => {
                                                    navigator.clipboard
                                                        .writeText(
                                                            project.userEmail
                                                        )
                                                        .then(() => {
                                                            // Assuming there's a toast function available
                                                            toast(
                                                                'User email copied to clipboard!',
                                                                {
                                                                    type: 'success'
                                                                }
                                                            );
                                                        });
                                                }}
                                            >
                                                <span>{project.userEmail}</span>
                                                <ClipboardIcon
                                                    className={`h-5 transition-opacity duration-300 ${
                                                        !showShareLink[i]
                                                            ? 'opacity-0'
                                                            : ''
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        onClick={() => onClickProject(project)}
                                        className="hidden whitespace-nowrap px-2 py-3 text-right text-xs text-gray-500 md:table-cell"
                                    >
                                        {moment(project.created).format(
                                            'MMM Do, YYYY HH:mm'
                                        )}
                                    </td>
                                    <td
                                        onClick={() => onClickProject(project)}
                                        className="hidden whitespace-nowrap px-2 py-3 text-right text-xs text-gray-500 md:table-cell"
                                    >
                                        {moment(project.updated).format(
                                            'MMM Do, YYYY HH:mm'
                                        )}
                                    </td>

                                    <td
                                        onClick={() => onClickProject(project)}
                                        className="hidden whitespace-nowrap px-2 py-3 text-right text-sm text-gray-500 md:table-cell"
                                    >
                                        {Math.floor(project.messageCount / 2)}
                                    </td>

                                    <td
                                        onClick={() => onClickProject(project)}
                                        className="whitespace-nowrap px-2 py-3 text-right text-sm font-medium"
                                    >
                                        {project.type}
                                    </td>
                                    <td
                                        onClick={() => onClickProject(project)}
                                        className="hidden whitespace-nowrap px-2 py-3 text-right text-sm text-gray-500 md:table-cell"
                                    >
                                        {project.creationMethod}
                                        {project.metadata
                                            ? ` - ${project.metadata}`
                                            : ''}
                                    </td>

                                    <td className="whitespace-nowrap px-2 py-3 text-center flex items-center justify-center font-medium">
                                        <div
                                            className={`flex flex-row gap-x-4 transition-opacity duration-300 ${
                                                !showShareLink[i]
                                                    ? 'opacity-0'
                                                    : ''
                                            }`}
                                        >
                                            <button
                                                onClick={copyToClipboard(
                                                    shareLink(project)
                                                )}
                                                className="flex flex-row items-center cursor-pointer
                                    text-gray-600 text-left text-2xs hover:text-gray-900"
                                            >
                                                <ShareIcon className="h-6 mr-1" />
                                            </button>

                                            {enterpriseOrganization
                                                ?.subscriptionStatus?.status ===
                                                'active' && (
                                                <>
                                                    {!deleting ? (
                                                        <button
                                                            onClick={() =>
                                                                onDeleteProject(
                                                                    project._id
                                                                )
                                                            }
                                                            className="flex flex-row items-center cursor-pointer
									text-red-600 text-left text-2xs hover:text-red-900"
                                                        >
                                                            <TrashIcon className="h-6 mr-1" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="flex flex-row items-center cursor-pointer
									text-red-600 text-left text-2xs hover:text-red-900"
                                                        >
                                                            <Spinner w="5" />
                                                        </button>
                                                    )}

                                                    <button
                                                        onClick={() =>
                                                            onTransferClick(
                                                                project
                                                            )
                                                        }
                                                        className="flex flex-row items-center cursor-pointer
									text-blue-600 text-left text-2xs hover:text-blue-900"
                                                    >
                                                        <ArrowRightOnRectangleIcon className="h-6 mr-1" />
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
