import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    clearStateProject,
    selectHideArchitecture,
    selectProjectLoading,
    setHideArchitecture
} from '../../redux/reducers/generic/project';
import { Chat } from './Chat';
import { ProjectArchitecture } from './ArchitectureGraph';
import RequestModal from './RequestMeeting/Requestmodal';
import { useEffect, useState } from 'react';
import { useProject } from '../../context-providers/Project';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { setPanelResized } from '../../redux/reducers/generic/projectArchitecture';
import { Loading } from '../Components/Loading';
import { FullScreenButton } from './Chat/FullScreenButton';
import { UpdatingArchitectureFloater } from './UpdatingArchitectureFloater';
import { DemoBanner } from './DemoBanner';
import { DockBar } from './DockBar';
import { ArchitectureTable } from './ArchitectureTable';
import { Docs } from './Docs';
import { Roadmap } from './Roadmap';
import { CodeGen } from './CodeGen';
import { getSow } from '../../redux/actions/solo/getSow';
import { setAPIKey } from '../../redux/reducers/enterprise_widget/enterpriseWidget';
import { useSubscriptionPayment } from '../../context-providers/SubscriptionPayment';
import LockDock from './lockDock';
import { getWebsiteDomain } from '../../auth_config';
import { Header } from '../WidgetPages/Header';

export const ProjectDashboard = ({ isHomePage, isWidget = false }) => {
    const dispatch = useDispatch();
    let { apiKey } = useParams();
    const { isSubbed, isSolo, isShare, isEnterprise, showFullText } =
        useSubscriptionPayment();

    if (isShare) {
        dispatch(setAPIKey(apiKey));
    }

    // we can use this to show loading
    const projectLoading = useSelector(selectProjectLoading);
    const {
        project,
        pageType,
        graphType,
        allSearchParams,
        setSearchParams,
        projectId,
        projectArchitecture
    } = useProject();
    const [searchParams, setSearchParams2] = useSearchParams();
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        const validPageTypes = [
            'chat',
            'sow',
            'prd',
            'techstack',
            'roadmap',
            'graph',
            'code'
        ];
        const validGraphTypes = projectArchitecture.graphs.map(g => g.side);
        const newPageType = validPageTypes.includes(pageType)
            ? pageType
            : 'chat';
        const newGraphType = validGraphTypes.includes(graphType)
            ? graphType
            : validGraphTypes[0];

        if (newPageType !== pageType || newGraphType !== graphType) {
            setSearchParams(
                {
                    ...allSearchParams,
                    pageType: newPageType,
                    graphType: newGraphType
                },
                { replace: false }
            );
        }
    }, [pageType, graphType, projectArchitecture]);

    const [isMobile2, setIsMobile] = useState(window.innerWidth < 768);
    const [projectLoaded, setProjectLoaded] = useState(false);

    useEffect(() => {
        if (project && !projectLoaded) {
            setProjectLoaded(true);
        }
        return () => {
            setProjectLoaded(false);
            console.log('clearing state');
            // dispatch(clearStateProject());
        };
    }, [project]);

    const handleResize = () => {
        console.log('resize');
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (projectLoaded) {
            console.log('getting sow');
            dispatch(getSow(projectId, 'mvp'));
        }
    }, [projectLoaded]);

    let projectArchitectureNode = (
        <ProjectArchitecture isSolo={isSolo} isShare={isShare} />
    );

    useEffect(() => {
        if (searchParams) {
            let hide = searchParams.get('hideArchitecture');
            if (hide) {
                dispatch(setHideArchitecture(true));
            }
        }
    }, [searchParams]);

    let hideArchitecture = useSelector(selectHideArchitecture);
    const [timeout, settimeout] = useState(null);

    const onLayout = sizes => {
        document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
        )}`;
        if (timeout) {
            clearTimeout(timeout);
        }
        let t = setTimeout(() => {
            dispatch(setPanelResized());
        }, 100);
        settimeout(t);
    };
    let [showDemoBanner, setShowDemoBanner] = useState(false);

    let paramTOName = {
        sow: 'Documentation',
        roadmap: 'Roadmap',
        code: 'Code Gen + Pricing Estimate',
        techstack: 'Tech Stack'
    };

    let lockDock = !showFullText && !project?.isSubscribed;

    return projectLoading ? (
        <Loading text="Project" />
    ) : project ? (
        <div className={`bg-black w-full  h-full flex flex-col`}>
            {isShare && !isHomePage && <Header showLogin={true} />}
            <UpdatingArchitectureFloater isShare={isShare} />

            <PanelGroup
                direction="horizontal"
                autoSaveId="project-dashboard"
                onLayout={onLayout}
            >
                <Panel defaultSize={40} minSize={35} maxSize={80}>
                    <section
                        className={`px-4 ${
                            !(pageType == 'graph' && isMobile2) && 'h-full'
                        } flex-grow flex flex-col ${
                            pageType == 'chat' && 'justify-between'
                        }`}
                    >
                        <div className="z-2 pb-2 relative flex w-full flex-col items-center justify-center rounded-lg md:shadow-xl">
                            <DemoBanner
                                showDemoBanner={showDemoBanner}
                                setShowDemoBanner={setShowDemoBanner}
                            />

                            <DockBar
                                setFullScreen={setFullScreen}
                                isShare={isShare}
                                isEnterprise={isEnterprise}
                                isSolo={isSolo}
                            />
                        </div>
                        <div
                            className="md:overflow-y-scroll md:mx-auto mb-4"
                            hidden={pageType !== 'graph' || isMobile2}
                        >
                            <ArchitectureTable />
                        </div>
                        <div
                            className={`dark overflow-y-scroll md:mx-8 px-4 rounded-lg mt-4 ${
                                pageType !== 'code' ? 'hidden' : ''
                            }`}
                        >
                            <CodeGen isSolo={isSolo} isShare={isShare} />
                        </div>

                        <div
                            className={`bg-slate-50 overflow-y-scroll rounded-lg xl:max-w-4xl max-w-full mx-auto mb-4 ${
                                pageType !== 'sow' ? 'hidden' : ''
                            }`}
                        >
                            <Docs
                                isEnterprise={isEnterprise}
                                isSolo={isSolo}
                                isShare={isShare}
                            />
                        </div>

                        {!lockDock && (
                            <>
                                <div
                                    className={`bg-slate-50 overflow-y-scroll rounded-lg md:px-4 xl:max-w-4xl max-w-full mx-auto mb-4 ${
                                        pageType !== 'roadmap' ? 'hidden' : ''
                                    }`}
                                >
                                    <Roadmap
                                        isSolo={isSolo}
                                        isEnterprise={isEnterprise}
                                        isShare={isShare}
                                    />
                                </div>
                            </>
                        )}
                        {lockDock && ['roadmap'].includes(pageType) && (
                            <LockDock
                                isShare={isShare}
                                isSolo={isSolo}
                                isEnterprise={isEnterprise}
                                param={pageType}
                                name={paramTOName[pageType]}
                            />
                        )}
                        <Chat
                            isShare={isShare}
                            isEnterprise={isEnterprise}
                            isWidget={isWidget}
                            isSolo={isSolo}
                            setShowDemoBanner={setShowDemoBanner}
                        />
                    </section>

                    <div className="block md:hidden -mt-4 w-full h-full overflow-auto">
                        {pageType == 'graph' &&
                            isMobile2 &&
                            projectArchitectureNode}
                    </div>

                    {pageType == 'chat' && (
                        <FullScreenButton
                            fullScreen={fullScreen}
                            setFullScreen={setFullScreen}
                        />
                    )}
                </Panel>

                {!fullScreen &&
                    !isMobile2 &&
                    !hideArchitecture &&
                    pageType != 'roadmap' &&
                    pageType != 'code' &&
                    pageType != 'sow' && (
                        <>
                            <PanelResizeHandle className="w-4 bg-gray-900 opacity-50">
                                <div className="fixed top-1/2 h-6 w- z-10 text-slate-200 hover:text-gray-300">
                                    ↔️
                                </div>
                            </PanelResizeHandle>
                            <Panel minSize={20}>
                                <div className="hidden md:block w-full h-full overflow-auto">
                                    {projectArchitectureNode}
                                </div>
                            </Panel>
                        </>
                    )}
            </PanelGroup>
            <RequestModal />
        </div>
    ) : null;
};
