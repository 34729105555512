import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    apiKey: null,
    userEmail: null,
    widgetState: 'initial',
    projectId: null,
    uniqueId: null
};

export const enterpriseWidgetSlice = createSlice({
    name: 'enterpriseWidget',
    initialState,
    reducers: {
        setUniqueId: (state, action) => {
            state.uniqueId = action.payload;
        },
        setAPIKey: (state, action) => {
            state.apiKey = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setEnterpriseWidgetProjectId: (state, action) => {
            state.projectId = action.payload;
        },
        setWidgetState: (state, action) => {
            state.widgetState = action.payload;
        },
        clearWidgetState: (state, action) => {
            state.widgetState = 'initial';
            state.apiKey = null;
            state.userEmail = null;
            state.projectId = null;
            state.uniqueId = null;
        }
    }
});

export const {
    setAPIKey,
    setUserEmail,
    setWidgetState,
    setEnterpriseWidgetProjectId,
    clearWidgetState,
    setUniqueId
} = enterpriseWidgetSlice.actions;
export const selectAPIKey = state => state.enterpriseWidget.apiKey;
export const selectUserEmail = state => state.enterpriseWidget.userEmail;
export const selectWidgetState = state => state.enterpriseWidget.widgetState;
export const selectEnterpriseWidgetProjectId = state =>
    state.enterpriseWidget.projectId;
export const selectEnterpriseWidgetUniqueId = state =>
    state.enterpriseWidget.uniqueId;

export default enterpriseWidgetSlice.reducer;
