import { chatEndingIndex, getChatEndingIndex } from '../../../constants';
import { useProject } from '../../../context-providers/Project';
import { CreateNode } from './CreateNode';
import { EditMode } from './EditMode';
import Explainer from './Explainer';
import { Graph } from './Graph';
import { Placeholder } from './Placeholder';
import TabBar from './TabBar';
import { NodeDetailModal } from './UIBuilder';
import { useRef, useState, useEffect } from 'react';

export const ProjectArchitecture = ({ isSolo, isShare }) => {
    const [currentEdge, setCurrentEdge] = useState(null);
    // these are our stateful vars from the provider
    let { chatHistory, project } = useProject();
    let currentIndex = getChatEndingIndex(chatHistory.data);

    const navRef = useRef(null);

    let showPlaceholder = false;

    if (currentIndex < chatEndingIndex) showPlaceholder = true;
    if (project.skip) showPlaceholder = false;

    const [originalHeight, setOriginalHeight] = useState(null);
    const [graphHeight, setGraphHeight] = useState(window.innerHeight);
    const fgRef = useRef(null);
    const [createNodeOpen, setCreateNodeOpen] = useState(false);

    return (
        <div
            className="w-full h-full"
            id="graph_side"
            style={{ backgroundColor: 'transparent' }}
        >
            {!showPlaceholder && (
                <div ref={navRef}>
                    <TabBar isShare={isShare} />
                </div>
            )}
            <NodeDetailModal isSolo={isSolo} />
            {!showPlaceholder ? (
                <div className="w-full h-full overflow-auto flex flex-col items-center">
                    <div
                        className="flex  h-full z-0"
                        id="graphContainer"
                        style={{
                            height: graphHeight + 'px',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <Graph
                            isShare={isShare}
                            tooltip={
                                <div
                                    className="z-500"
                                    style={{ position: 'relative' }}
                                >
                                    <Explainer
                                        fgRef={fgRef}
                                        isSolo={isSolo}
                                        currentEdge={currentEdge}
                                    />
                                </div>
                            }
                            setCreateNodeOpen={setCreateNodeOpen}
                            fgRef={fgRef}
                            graphHeight={graphHeight}
                            setGraphHeight={setGraphHeight}
                            originalHeight={originalHeight}
                            setOriginalHeight={setOriginalHeight}
                            navRef={navRef}
                            isSolo={isSolo}
                            setCurrentEdge={setCurrentEdge}
                        />
                    </div>

                    <CreateNode
                        open={createNodeOpen}
                        setOpen={setCreateNodeOpen}
                    />
                    {!isShare && <EditMode />}
                </div>
            ) : (
                <Placeholder />
            )}
        </div>
    );
};
