import gql from 'graphql-tag';
import { project } from '../helper';

export const IS_ADMIN = gql`
    query isAdmin {
        isAdmin
    }
`;

export const GET_PROJECT_BY_ADMIN = gql`
	query getProjectByAdmin($projectId: ID!) {
		projectByAdmin(projectId: $projectId) {
			${project}
		}
	}
`;
