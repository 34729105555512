import React, { createContext, useState, useEffect, useContext } from 'react';
import { client } from '../api/client';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { GET_USER_SUBSCRIPTION } from '../api/graphQL';
import { useEnterpriseOrganization } from './EnterpriseOrganization';
import { getWebsiteDomain } from '../auth_config';

const SubscriptionPaymentContext = createContext();

export function SubscriptionPaymentProvider({
    children,
    isSolo,
    isShare,
    isEnterprise
}) {
    const { doesSessionExist, accessTokenPayload } = useSessionContext();
    const [soloSubscriptionPayment, setSubscriptionPayment] = useState({
        loading: true
    });
    let { enterpriseOrganization } = useEnterpriseOrganization();
    const [isSubbed, setIsSubbed] = useState(false);
    const [status, setStatus] = useState(null);
    const [tier, setTier] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [showFullText, setShowFullText] = useState(false);

    useEffect(() => {
        if (isShare) {
            const currentURL = window.location.href;
            const baseURL = getWebsiteDomain().includes('localhost')
                ? 'http://localhost:3000/share/66a00335ff2e588d2d830aa1'
                : 'https://pre.dev/share/6e4b9523-bb22-4762-b1fd-1e414f1c27f2/668ee8853309a805d766de43';

            setShowFullText(currentURL.includes(baseURL));
        } else {
            setShowFullText(isSubbed);
        }
    }, [isSubbed, isShare]);

    useEffect(() => {
        if (isShare) {
            setIsSubbed(false);
            setStatus(null);
            setTier(null);
            setSubscription(null);
        } else if (isSolo) {
            setIsSubbed(soloSubscriptionPayment?.status === 'active');
            setStatus(soloSubscriptionPayment?.status);
            setTier(soloSubscriptionPayment?.tier);
            setSubscription(soloSubscriptionPayment);
        } else {
            setIsSubbed(
                enterpriseOrganization?.subscriptionStatus.status === 'active'
            );
            setStatus(enterpriseOrganization?.subscriptionStatus.status);
            setTier(enterpriseOrganization?.subscriptionStatus.tier);
            setSubscription(enterpriseOrganization?.subscriptionStatus);
        }
    }, [isShare, isSolo, soloSubscriptionPayment, enterpriseOrganization]);

    useEffect(() => {
        async function getSubscriptionPayment() {
            try {
                const { data } = await client.query({
                    query: GET_USER_SUBSCRIPTION,
                    fetchPolicy: 'network-only'
                });
                setSubscriptionPayment(data.userSubscriptionStatus);
            } catch (error) {
                console.log({ error });
                setSubscriptionPayment(false);
            }
        }
        if (doesSessionExist) {
            getSubscriptionPayment();
        } else {
            setSubscriptionPayment(false);
        }
    }, [doesSessionExist, accessTokenPayload?.sessionHandle]);

    return (
        <SubscriptionPaymentContext.Provider
            value={{
                isSubbed,
                status,
                tier,
                subscription,
                isSolo,
                isShare,
                isEnterprise,
                showFullText
            }}
        >
            {!soloSubscriptionPayment.loading && children}
        </SubscriptionPaymentContext.Provider>
    );
}

export const useSubscriptionPayment = () =>
    useContext(SubscriptionPaymentContext);
