import { FaLayerGroup } from 'react-icons/fa';
import { ClockIcon } from '@heroicons/react/24/solid';
import { HiDatabase } from 'react-icons/hi';
import { DocumentChartBarIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';

export const knowledge_management = {
    step_title: 'Knowledge Management',
    icon: <DocumentChartBarIcon className="h-12 text-white" aria-hidden="true"/>,
    description:
        'For enterprise users, the Knowledge Management feature provides a centralized repository for storing and organizing project-related documents. This system leverages the uploaded documents to inform project responses and enhance the accuracy of hour estimations.',
    features: [
        {
            feature_title: 'Default Tech Stack',
            description:
                'Allows organizations to set predefined technology stacks for new projects, ensuring consistency and alignment with company standards when scoping out new initiatives.',
            icon: <FaLayerGroup className="h-12 text-white" />
        },
        {
            feature_title: 'Global Knowledge Base',
            description:
                'Creates a global knowledge base that informs project responses, ensuring that the entire team has access to relevant information and best practices.',
            icon: <HiDatabase className="h-12 text-white" />
        },
        {
            feature_title: 'Hour Estimations',
            description:
                'Leverages project data and historical information to provide accurate hour estimations for tasks, helping teams plan and allocate resources more effectively.',
            icon: <ClockIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Custom Documentation',
            description:
                'Allows users to upload their own documentation format, ensuring consistency across all project documentation.',
            icon: <DocumentDuplicateIcon className="h-12 text-white" />
        }
    ],
    user_story:
        "Sarah uploads a data protection guide to ensure her team adheres to privacy regulations while building 'Down to Chill.' She also customizes the tech stack recommendations, prioritizing tools like React and Node.js. Additionally, Sarah uploads her company's custom documentation template, which is then used to automatically generate consistent documentation for new projects.",
    screenshots: [
        {
            title: 'Knowledge Management Interface',
            image_url: '/knowledge_management_1.png'
        },
        {
            title: 'Knowledge Management Interface',
            image_url: '/knowledge_management_2.png'
        },
    ],
    enterprise_exclusive: true
};