import React from "react";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ImageCarousel = ({ screenshots }) => {
  const settings = {
    className: "slick-slider",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="relative w-full overflow-hidden max-w-[500px]">
      <Slider {...settings}>
        {screenshots.map((screenshot, index) => (
          <div key={index} className="focus:outline-none">
            <img
              src={screenshot.image_url}
              alt={screenshot.title}
              className="w-full rounded-xl shadow-xl ring-1 ring-gray-400/10"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};