import { useRef, useState } from 'react';
import { Suggest } from './Suggest';
import { Input } from './Input';

export const ChatInput = ({ isSolo, isWidget, image_url }) => {
    const textareaRef = useRef(null);
    const [answer, setAnswer] = useState('');
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);

    return (
        <div className="px-4 left-0 w-full border-t bg-white" id="chatInput">
            <Input
                isSolo={isSolo}
                isWidget={isWidget}
                image_url={image_url}
                fileKeyS3={fileKeyS3}
                setFileKeyS3={setFileKeyS3}
                setFile={setFile}
                setProgress={setProgress}
                isUploading={isUploading}
                answer={answer}
                setAnswer={setAnswer}
                textareaRef={textareaRef}
            />

            <Suggest
                textareaRef={textareaRef}
                setAnswer={setAnswer}
                answer={answer}
            />
        </div>
    );
};
