import React, { useEffect, useRef } from 'react';
import ProjectItem from './ProjectItem';
import { useProjects } from '../../../context-providers/Projects';
import CreateNewProject from './createNewProject';
import Settings from './Settings';
import UpgradeToPremium from './UpgradeToPremium';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { StarIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { FeedBackButton } from '../../Components/FeedbackButton';
import { useDispatch, useSelector } from 'react-redux';
import LoadMoreButton from './LoadMoreButton';
import {
    paginateProjects,
    selectSearchLoading
} from '../../../redux/reducers/generic/projects';
import { SearchProjects } from '../../EnterpriseHome/ClientProjects/Search';

export default function DesktopSidebar() {
    let { projects } = useProjects();
    const divRef = useRef(null);
    const { isSubbed } = useSubscriptionPayment();
    const dispatch = useDispatch();
    const searchLoading = useSelector(selectSearchLoading);

    useEffect(() => {
        const div = divRef.current;

        const handleScroll = () => {
            const isBottom =
                div?.scrollTop + div?.clientHeight >= div?.scrollHeight;
            if (isBottom) {
                dispatch(paginateProjects());
            }
        };

        div.addEventListener('scroll', handleScroll);

        return () => {
            div.removeEventListener('scroll', handleScroll);
        };
    }, [divRef]);

    // Update the max-height of projectsList based on window height
    useEffect(() => {
        const resizeHandler = () => {
            if (divRef.current) {
                const windowHeight = window.innerHeight;
                const topOffset = divRef.current.offsetTop;

                const liElementHeight = !isSubbed ? 160 : 160; // Set the height of your li element here
                const availableHeight =
                    windowHeight - topOffset - liElementHeight;
                divRef.current.style.maxHeight = `${availableHeight}px`;
            }
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler(); // Call it initially to set the initial height
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [isSubbed]);

    return (
        <div className="hidden lg:block lg:inset-y-0 lg:flex w-[350px] lg:flex-col h-full">
            <div class="max-w-xl h-full rounded-lg border border-slate-300 border-t-0 border-l-0 bg-slate-50 pb-8 dark:border-slate-200/10 dark:bg-slate-900">
                <div className="flex justify-between">
                    <div className="flex h-16 shrink-0 items-center">
                        <Link to="/">
                            <img
                                className="ml-2 h-8 w-auto rounded-full p-[3px]"
                                src="/predevblack.png"
                            />
                        </Link>
                        <Link to="/" className="flex">
                            <span className="ml-1 font-semibold self-center">
                                pre.dev
                            </span>
                            <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-blue-600">
                                Solo
                            </span>
                        </Link>
                    </div>
                    <Link
                        to="/enterprise/dashboard"
                        className="flex items-center"
                    >
                        <a class="ml-4 inline-flex text-green-500 items-center justify-center px-2 py-1 me-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <UserGroupIcon className="w-3 h-3 mr-2" />
                            Enterprise
                        </a>
                    </Link>
                </div>

                <div class="flex items-start">
                    <h2 class="inline px-5 text-lg font-medium text-slate-800 dark:text-slate-200">
                        Projects
                    </h2>
                    <span class="rounded-full bg-blue-600 px-2 py-1 text-xs text-slate-200">
                        {projects?.length}
                    </span>
                </div>

                {isSubbed && <SearchProjects />}

                <div
                    ref={divRef}
                    class="my-4 h-full space-y-4 overflow-y-auto px-2"
                >
                    {!searchLoading &&
                        projects.map((project, i) => (
                            <ProjectItem project={project} key={i} />
                        ))}
                    {!isSubbed && <UpgradeToPremium />}
                    {!searchLoading && (
                        <div className="flex items-cente justify-center">
                            <LoadMoreButton />
                        </div>
                    )}
                </div>
                <div class="mx-2">
                    <CreateNewProject />
                    <div className="mt-2">
                        <FeedBackButton textColor="black" />
                        <Settings />
                    </div>
                </div>
            </div>
        </div>
    );
}
