import { useDispatch, useSelector } from 'react-redux';
import { toggleSubscribeModal } from '../../../redux/reducers/generic/billing';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import PricingCard from '../PricingCard';

const UpgradeToPremium = ({ closeSidebar }) => {
    const dispatch = useDispatch();
    const { isSubbed } = useSubscriptionPayment();

    const handleUpgrade = async e => {
        dispatch(toggleSubscribeModal(true));
        if (closeSidebar) {
            closeSidebar();
        }
    };

    return (
        !isSubbed && (
            <div className="dark">
                <PricingCard handleUpgrade={handleUpgrade} />
            </div>
        )
    );
};

export default UpgradeToPremium;
