import { DocumentIcon, ClockIcon, CodeBracketIcon } from '@heroicons/react/24/solid';
import { Icons } from '../../../ProjectDashboard/DockBar/icons'
import { FaLayerGroup } from 'react-icons/fa';

export const documentation = {
    step_title: '2. Product Documentation',
    icon: <Icons.documentation className="h-12 text-white" />,
    description:
        'Automatically generate detailed documentation for your project. This includes a Product Requirements Document (PRD), a Statement of Work (SOW) outlining the project phases and deliverables, hour estimations for each task, and a comprehensive tech stack overview. The documentation is tailored to the specifics of the project and is crucial for project planning and management.',
    features: [
        {
            feature_title: 'PRD and SOW Generation',
            description:
                'Comprehensive documents that outline the functional and non-functional requirements, project scope, timelines, and deliverables, providing a clear roadmap for the development process.',
            icon: <DocumentIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Task-Based Hour Estimations',
            description:
                'Provides accurate hour estimations for each task or feature within the project, helping to plan resources and timelines effectively.',
            icon: <ClockIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Tech Stack Overview',
            description:
                'A detailed breakdown of the technologies, frameworks, and tools to be used in the project, ensuring all team members are aligned on the technical aspects.',
            icon: <FaLayerGroup className="h-12 text-white" />
        }
    ],
    user_story:
        "Sarah reviews the PRD, which outlines user stories such as 'As a user, I want to find and RSVP to events that match my interests.' The SOW outlines the development of core features like event browsing and user verification, complete with time estimates. She also examines the tech stack overview to ensure it aligns with her team's expertise.",
    screenshots: [
        {
            title: 'Generated Documentation',
            image_url: '/documentation_1.png'
        },
        {
            title: 'Generated Documentation',
            image_url: '/documentation_2.png'
        },
        {
            title: 'Generated Documentation',
            image_url: '/documentation_3.png'
        }
    ],
    enterprise_exclusive: false
};