import { buttonVariants } from "../Components/magicui/ui/button";
import { cn } from "../Components/magicui/lib/utils";
import Marquee from "../Components/magicui/marquee";
import { ChevronRight, HeartHandshake } from "lucide-react";

const reviews = [

  {
    name: "James",
    username: "@james",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/jiracta1.png",
  },

  {
    name: "fred",
    username: "@fe",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/jiracta2.png",
  },  

  {
    name: "James",
    username: "@ge",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/jiracta3.png",
  },


  {
    name: "fred",
    username: "@fxv",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/jiracta4.png",
  },  
  {
    name: "otfdha",
    username: "@gdfsdsssssdf",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/sowcta3.png",
  },
  {
    name: "James",
    username: "@gdfsdf",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/sowcta1.png",
  },
  {
    name: "otha",
    username: "@gdfsssssdf",
    body: "I'm at a loss for words. This is amazing. I love it.",
    img: "/sowcta2.png",
  },


];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <img className="rounded-md h-[300px] w-auto" alt="" src={img} />
  );
};

export function CallToAction2() {
  return (
    <section id="cta">
      <div className="pt-4">
        <div className="container flex w-full flex-col items-center justify-center p-4">
          <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-[2rem] border p-10 py-14">
            <div className="absolute rotate-[35deg]">
              <Marquee pauseOnHover className="[--duration:20s]" repeat={3}>
                {firstRow.map((review) => (
                  <ReviewCard key={review.username} {...review} />
                ))}
              </Marquee>
              <Marquee
                reverse
                pauseOnHover
                className="[--duration:20s]"
                repeat={3}
              >
                {secondRow.map((review) => (
                  <ReviewCard key={review.username} {...review} />
                ))}
              </Marquee>
  
      
            </div>
            <div className="z-10 mx-auto size-24 rounded-[2rem] border bg-white/10 p-3 shadow-2xl backdrop-blur-md dark:bg-black/10 lg:size-32">

                <img
                    src="/jira.svg"
                    className="mx-auto text-black dark:text-white"
                    alt="Company Logo"
                />
            </div>
            <div className="z-10 mt-4 flex flex-col items-center text-center text-black dark:text-white">
              <h1 className="text-3xl font-bold lg:text-4xl">
                Generate detailed Jira Roadmaps in seconds. <span className='text-blue-600'>Start sprints faster.</span>
              </h1>
              <p className="mt-2">
                Jira giving you headaches? Automate your Jira workflows with pre.dev.
              </p>
              <a
                href="#pricing"
                className={cn(
                  buttonVariants({
                    size: "lg",
                    variant: "outline",
                  }),
                  "group mt-4 rounded-[2rem] px-6",
                )}
              >
                Start with<span className="ml-2 text-green-500">Enterprise</span>
                <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
              </a>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-70% dark:to-black" />
          </div>
        </div>
      </div>
    </section>
  );
}
