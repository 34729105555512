import { useDispatch, useSelector } from 'react-redux';
import {
    clearStateGraphEdits,
    selectAddEdgeMode,
    selectAddNodeMode,
    selectEditMode,
    selectFirstNode,
    selectGraphEditLoading,
    setGraphEditLoading,
    toggleAddEdgeMode,
    toggleEditMode,
    toggleAddNodeMode
} from '../../../redux/reducers/generic/graphEdits';
import { useParams, useSearchParams } from 'react-router-dom';
import { addNodesEdgesToGraph } from '../../../redux/actions/solo/addNodesEdgesToGraph';
import { Spinner } from '../../Components/Spinner';
import { useProject } from '../../../context-providers/Project';
import {
    selectGraphVersion,
    selectProjectArchitectureLoading
} from '../../../redux/reducers/generic/projectArchitecture';
import { useEffect } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const EditMode = ({}) => {
    const editMode = useSelector(selectEditMode);
    const dispatch = useDispatch();
    const loading = useSelector(selectGraphEditLoading);

    const [params] = useSearchParams();
    const { projectId } = useParams();
    const { project } = useProject();

    const graphVersion = useSelector(selectGraphVersion);
    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    const addEdgeMode = useSelector(selectAddEdgeMode);
    const addNodeMode = useSelector(selectAddNodeMode);
    const firstNode = useSelector(selectFirstNode);

    useEffect(() => {
        return () => {
            dispatch(clearStateGraphEdits());
        };
    }, []);

    const savingEdits = async () => {
        try {
            dispatch(setGraphEditLoading(true));
            await dispatch(
                addNodesEdgesToGraph({
                    projectId,
                    side: params.get('graphType')
                })
            );
            dispatch(clearStateGraphEdits());
            dispatch(setGraphEditLoading(false));
        } catch (err) {
            console.log(err);
            dispatch(setGraphEditLoading(false));
        }
    };

    return (
        graphVersion === project.graphVersions[0] &&
        !architectureLoading && (
            <>
                {editMode && (
                    <div
                        className={`fixed bottom-[50%] right-4`}
                        style={{ zIndex: 10 }}
                    >
                        <div className="flex flex-col gap-y-2 w-full">
                            {!addNodeMode && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        dispatch(toggleAddEdgeMode());
                                    }}
                                    className={`${
                                        addEdgeMode
                                            ? 'text-teal-600 ring-teal-600 hover:bg-teal-600 hover:text-teal-50'
                                            : 'text-blue-600 ring-blue-600 hover:bg-blue-600 hover:text-blue-50'
                                    } ring-1 ring-inset inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold `}
                                >
                                    {addEdgeMode
                                        ? 'Finish Adding Edges'
                                        : 'Add Edge'}
                                </button>
                            )}
                            {!addEdgeMode && !addNodeMode && (
                                <hr className="my-2" />
                            )}
                            {!addEdgeMode && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        dispatch(toggleAddNodeMode());
                                    }}
                                    className={`${
                                        addNodeMode
                                            ? 'text-teal-600 ring-teal-600 hover:bg-teal-600 hover:text-teal-50'
                                            : 'text-blue-600 ring-blue-600 hover:bg-blue-600 hover:text-blue-50'
                                    } ring-1 ring-inset  inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold `}
                                >
                                    {addNodeMode
                                        ? 'Finish Adding Nodes'
                                        : 'Add Node'}
                                </button>
                            )}
                        </div>
                    </div>
                )}
                <div className={`fixed bottom-4`} style={{ zIndex: 10 }}>
                    <div className="flex justify-center gap-x-32">
                        {!editMode && (
                            <button
                                type="button"
                                onClick={() => {
                                    dispatch(toggleEditMode());
                                }}
                                className="inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold text-blue-600 ring-1 ring-inset ring-blue-600 hover:bg-blue-600 hover:text-blue-50"
                            >
                                Edit Mode
                            </button>
                        )}
                        {editMode && (
                            <div className="flex flex-row items-center gap-x-4 px-2">
                                <button
                                    onClick={savingEdits}
                                    disabled={loading}
                                    type="button"
                                    className="inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold text-green-600 ring-1 ring-inset ring-green-600 hover:bg-green-600 hover:text-green-50"
                                >
                                    {loading ? (
                                        <>
                                            Saving...
                                            <Spinner w="4" />
                                        </>
                                    ) : (
                                        'Save Edits'
                                    )}
                                </button>

                                <div className="flex flex-col h-fit bg-blue-50 opacity-100 rounded-md px-4 py-3 items-center w-fit gap-x-2">
                                    <p className="flex items-center gap-x-2 text-md text-black w-fit">
                                        <InformationCircleIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                        {!addEdgeMode && !addNodeMode && (
                                            <span>
                                                <u>Click on a node/edge</u> to
                                                delete it.
                                            </span>
                                        )}

                                        {addEdgeMode && !firstNode && (
                                            <span>
                                                <u>Click on a node</u> to begin
                                                adding an edge.
                                            </span>
                                        )}
                                        {addEdgeMode && firstNode && (
                                            <span>
                                                <u>Click on another node</u> to
                                                connect the two.
                                            </span>
                                        )}

                                        {addNodeMode && (
                                            <span>
                                                <u>Click on an existing node</u>{' '}
                                                to add a new node connected to
                                                it.
                                            </span>
                                        )}
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    disabled={loading}
                                    onClick={() => {
                                        dispatch(clearStateGraphEdits());
                                    }}
                                    className="inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold text-red-600 ring-1 ring-inset ring-red-600 hover:bg-red-600 hover:text-red-50"
                                >
                                    Cancel Edits
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    );
};
