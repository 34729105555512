import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

export const formatGoalsAndObjectives = (sow, previewMode) => {
    const formatGoal = goal => {
        const formattedDescription = formatGoalDescription(goal, previewMode);
        const formattedObjectives = Array.isArray(goal?.objectives)
            ? goal?.objectives?.map(obj => formatObjective(obj, previewMode))
            : [];

        return formatBulletList([
            formatListItem([
                formattedDescription,
                formatBulletList(formattedObjectives)
            ])
        ]);
    };

    const formatGoalDescription = (goal, previewMode) => {
        try {
            return formatParagraph([
                formatBoldText(`${goal?.description}:`, previewMode)
            ]);
        } catch (error) {
            return formatParagraph([formatText('Loading...', false)]);
        }
    };

    const formatObjective = (obj, previewMode) => {
        try {
            return formatListItem([
                formatParagraph([formatText(obj, previewMode)])
            ]);
        } catch (error) {
            return formatListItem([
                formatParagraph([formatText('Loading...', false)])
            ]);
        }
    };

    let formattedContent = [];
    try {
        let heading = formatHeading(2, [
            formatText('Deliverables and Objectives', false)
        ]);
        let goals = sow?.goalsAndObjectives?.map(goal => formatGoal(goal));
        formattedContent = [heading, ...goals];
    } catch (error) {
        console.log('Error formatting goals and objectives:', false);
    }
    return formattedContent;
};
