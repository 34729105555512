import { useDispatch, useSelector } from 'react-redux';
import {
    selectLoadingSowCustom,
    selectLoadingSowMVP,
    selectSow
} from '../../../../redux/reducers/generic/sow';
import { RefreshCw } from 'react-feather';
import { useProject } from '../../../../context-providers/Project';
import { generateSow } from '../../../../redux/actions/solo/generateSow';
import { generateSowCustom } from '../../../../redux/actions/solo/generateSoWCustom';
import { selectProjectArchitectureLoading } from '../../../../redux/reducers/generic/projectArchitecture';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { getSow } from '../../../../redux/actions/solo/getSow';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toggleSubscribeModal } from '../../../../redux/reducers/generic/billing';

export const GenerateLatestSow = () => {
    const dispatch = useDispatch();
    const mvpLoading = useSelector(selectLoadingSowMVP);
    const customLoading = useSelector(selectLoadingSowCustom);
    let sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const { project, projectId } = useProject();
    const isLoadingArchitecture = useSelector(selectProjectArchitectureLoading);
    const { isSubbed } = useSubscriptionPayment();

    const up_to_date =
        type === 'mvp'
            ? project.sow_mvp_up_to_date
            : project.sow_custom_up_to_date;
    const isLoadingSow = type === 'mvp' ? mvpLoading : customLoading;

    if (up_to_date || isLoadingSow || isLoadingArchitecture) return null;
    const handleClick = async () => {
        console.log({ isSubbed });
        if (!isSubbed) {
            dispatch(toggleSubscribeModal(true));
        } else {
            const action = type === 'custom' ? generateSowCustom : generateSow;
            if (type === 'custom') {
                const success = await dispatch(action(projectId, type));
                if (!success) {
                    await dispatch(getSow(projectId, 'mvp'));
                }
            } else {
                dispatch(action(projectId, type));
            }
        }
    };

    return (
        sow?.completed && (
            <>
                <button
                    onClick={handleClick}
                    data-tooltip-id="generate-sow-tooltip"
                    data-tooltip-content="Generate Latest Docs"
                    className="p-2 text-blue-600 border border-blue-700 rounded-full hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors duration-200"
                >
                    <RefreshCw className="h-4 w-4" />
                </button>
                <Tooltip id="generate-sow-tooltip" place="top" />
            </>
        )
    );
};
