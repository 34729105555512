import { useState } from 'react';

export const useUIState = () => {
  const [activeTab, setActiveTab] = useState('Preview');
  const [screenshot, setScreenshot] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileKeyScreenshot, setFileKeyScreenshot] = useState(null);

  return {
    activeTab,
    setActiveTab,
    screenshot,
    setScreenshot,
    uploading,
    setUploading,
    imageUrl,
    setImageUrl,
    fileKeyScreenshot,
    setFileKeyScreenshot,
  };
};