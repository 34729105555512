import { useSubscription } from '@apollo/client'; // Apollo client for GraphQL subscription
import { UIVIEW_SUBSCRIPTION } from '../../../../api/graphQL'; // GraphQL subscription for UIView
import { useState, useEffect } from 'react'; // React hooks for state and lifecycle
import { useSelector, useDispatch } from 'react-redux'; // Redux hooks for state management

// Redux selectors and actions for project architecture
import {
    selectShowNodeDetailModal,
    setShowNodeDetailModal,
    selectSelectedNode,
    setUIView,
    selectUIView,
    selectUIViewLoading,
    selectUIViewEditing
} from '../../../../redux/reducers/generic/projectArchitecture';
import { getUIView } from '../../../../redux/actions/solo/getUIView';
import { getUIViews } from '../../../../redux/actions/solo/getUIViews';
import { saveUIView } from '../../../../redux/actions/solo/saveUIView';
import { toast, Toaster } from 'sonner';
import Modal from '../../../Components/Modal'; // Reusable modal component

// Custom hooks for managing UI state, file management, and screenshots
import { useProject } from '../../../../context-providers/Project';
import { useUIState } from './hooks/useUIState';
import { useScreenshot } from './hooks/useScreenshot';
import { useFileManagement } from './hooks/useFileManagement';

// UI components for the UIBuilder
import { UiCta } from './UiCta';
import { UIBuilder } from './UIBuilder';
const excludedFiles = ['/Container.tsx', '/index.tsx', '/styles.css'];

// NodeDetailModal component: Displays the modal with detailed information about a node
export const NodeDetailModal = ({ isSolo }) => {
    const dispatch = useDispatch(); // Hook to dispatch actions
    const { projectId, project, projectArchitecture } = useProject(); // Custom hook to get project data
    const open = useSelector(selectShowNodeDetailModal); // Selector to check if modal is open
    const node = useSelector(selectSelectedNode); // Selector to get the selected node
    const nodeId = node?._id;
    const loadingUIView = useSelector(selectUIViewLoading); // Selector to check if UIView is loading
    const editingPhase = useSelector(selectUIViewEditing); // Selector to check if in editing phase
    const uiView = useSelector(selectUIView); // Selector to get the current UIView

    // State management for UI state
    const {
        activeTab,
        setActiveTab,
        screenshot,
        setScreenshot,
        uploading,
        setUploading,
        imageUrl,
        setImageUrl,
        fileKeyScreenshot,
        setFileKeyScreenshot
    } = useUIState();

    // State management for file management
    const {
        files,
        testFiles,
        filesWithTests,
        regenerateUI,
        onTestComplete,
        testingPhase,
        setFiles,
        setTestingPhase,
        setTestFiles,
        setFilesWithTests
    } = useFileManagement({
        dispatch,
        projectId,
        nodeId,
        node,
        uiView,
        setUIView,
        setImageUrl,
        setFileKeyScreenshot,
        projectArchitecture
    });

    // Custom hook to handle screenshot events
    const { handleScreenshotEvent } = useScreenshot({
        setImageUrl,
        setUploading,
        setFileKeyScreenshot,
        dispatch
    });

    // Subscription to UIView updates
    useSubscription(UIVIEW_SUBSCRIPTION, {
        variables: { projectId, nodeId },
        shouldResubscribe: true,
        onData: data => {
            try {
                // Reset state upon receiving new data
                setFilesWithTests(null);
                setTestingPhase(false);
                // Dispatch action to update UIView with new data
                dispatch(setUIView(data.data.data.uiviewStream));
            } catch (error) {
                console.error(error);
            }
        },
        onError: error => {
            console.error(JSON.stringify(error, null, 2));
        }
    });

    // Effect to fetch UIView when node changes
    useEffect(() => {
        if (node) {
            if (node.id !== 'core')
                dispatch(
                    getUIView({
                        nodeId: node._id,
                        projectId: projectId,
                        forceNew: false
                    })
                );
            else {
                console.log('Core node so fetching all views');
                setTestingPhase(false);
                dispatch(
                    getUIViews({ nodeId: node._id, projectId: projectId })
                );
            }
        }
    }, [node]);

    // Effect to listen for screenshot messages from iframe
    useEffect(() => {
        function receiveMessage(event) {
            if (event.data.type === 'screenshot') {
                // Set screenshot data URL from the iframe
                setScreenshot(event.data.imgData);
                handleScreenshotEvent(event.data.imgData);
            }
        }

        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, [handleScreenshotEvent]);

    let [editLoading, setEditLoading] = useState(false);

    const handleSave = async () => {
        try {
            //setEditLoading(true);

            let updatedExcluded = excludedFiles;
            const isGlobal = Object.values(files).some(f => f.uiViewId);

            if (isGlobal) {
                updatedExcluded.push('/App.tsx');
            }

            const allFiles = Object.entries(files)
                .filter(([path, code]) => !updatedExcluded.includes(path))
                .map(([path, code]) => ({
                    // for global case we replace the /component/Landing for example
                    filepath: isGlobal
                        ? path.replace(/^\/components\/[^\/]+\//, '/')
                        : path,
                    code: code.code,
                    uiViewId: code.uiViewId
                }));
            console.log(allFiles);
            const message = await dispatch(
                saveUIView({
                    projectId,
                    nodeId,
                    version: 0,
                    codeFiles: allFiles
                })
            );
            toast(message);
        } catch (error) {
            console.log(error);
            toast('Error saving code');
        }
    };

    // Render function for NodeDetailModal
    return (
        <div>
            <Modal
                open={open}
                onClose={() => {
                    // Reset state and close modal
                    dispatch(setShowNodeDetailModal(null));
                    dispatch(setUIView(null));
                    setFiles(null);
                    setTestFiles(null);
                    setFilesWithTests(null);
                    setTestingPhase(true);
                }}
                maxWidth={`w-full md:mx-10`}
            >
                <div>
                    <div className="flex flex-col sm:grid sm:grid-cols-3 mb-2">
                        <div className="hidden sm:flex flex-col gap-y-2 text-center">
                            {/* Placeholder for future content */}
                        </div>
                        <div className="flex flex-row gap-x-2 items-center justify-center mx-auto max-w-screen-sm text-center">
                            <img
                                src="/predev.png"
                                className="justify-center w-14 bg-gray-900 rounded p-1"
                                alt="Company Logo"
                            />
                            <h2 className="text-2xl tracking-tight font-extrabold leading-tight text-gray-900">
                                UI/UX Designer
                            </h2>
                        </div>
                        <div className="flex justify-end mr-8 ">
                            {/* Placeholder for future content */}
                        </div>
                    </div>
                    {!uiView || uiView?.files?.length === 0 ? (
                        <UiCta
                            node={node}
                            projectId={projectId}
                            loading={loadingUIView}
                        />
                    ) : (
                        <>
                            {UIBuilder({
                                activeTab,
                                setActiveTab,
                                node,
                                regenerateUI,
                                uiView,
                                testingPhase,
                                editingPhase,
                                loadingUIView,
                                filesWithTests,
                                files,
                                setFiles,
                                isSolo,
                                imageUrl,
                                onTestComplete,
                                handleSave
                            })}
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
};
