import React, { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    fetchProjectRequest,
    selectProject,
    clearStateProject,
    setProjectId
} from '../redux/reducers/generic/project';
import {
    selectChatHistory,
    clearStateChatHistory
} from '../redux/reducers/generic/chatHistory';
import { clearStateChatHistory as clearStateChatHistoryUI } from '../redux/reducers/generic/chatHistory';
import {
    selectProjectArchitecture,
    clearStateProjectArchitecture,
    setUIView
} from '../redux/reducers/generic/projectArchitecture';
import { getProjectByUser } from '../redux/actions/solo/getProjectByUser';
import { getProjectByAdmin } from '../redux/actions/admin/getProjectByAdmin';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProjectByEnterpriseOrganization } from '../redux/actions/enterprise/getProjectByEnterpriseOrganization';
import { getProjectByLink } from '../redux/actions/solo/getProjectByLink';
import { getProjectByUniqueId } from '../redux/actions/enterprise_widget/getProjectByUniqueId';
import { getProjectByEnterpriseWidget } from '../redux/actions/enterprise_widget/getProjectByEnterpriseWidget';
import {
    selectAPIKey,
    selectWidgetState
} from '../redux/reducers/enterprise_widget/enterpriseWidget';
import { setSow } from '../redux/reducers/generic/sow';
import { setJira } from '../redux/reducers/generic/jira';

import { getEnterpriseOrganizationByApiKey } from '../redux/actions/enterprise_widget/getEnterpriseOrganizationByApiKey';
import { useSearchParams } from 'react-router-dom';
import { selectSkipClicked } from '../redux/reducers/generic/projects';
import { useState } from 'react';
import { useSubscriptionPayment } from './SubscriptionPayment';

const ProjectContext = createContext();

export const ProjectProvider = ({ children, isFullWidget, isChatWidget }) => {
    const { isSolo, isShare, isEnterprise } = useSubscriptionPayment();
    const dispatch = useDispatch();
    let project = useSelector(selectProject);
    let chatHistory = useSelector(selectChatHistory);
    let widgetState = useSelector(selectWidgetState);
    let projectArchitecture = useSelector(selectProjectArchitecture);
    let skipClicked = useSelector(selectSkipClicked);
    const apiKey = useSelector(selectAPIKey);
    const [searchParams, setSearchParams] = useSearchParams();
    const pageType = searchParams.get('pageType');
    const graphType = searchParams.get('graphType');
    let allSearchParams = Object.fromEntries(searchParams);

    let { projectId, enterpriseOrganizationId, uniqueId } = useParams();

    const [prevSkipClicked, setPrevSkipClicked] = useState(skipClicked);

    useEffect(() => {
        if (prevSkipClicked !== skipClicked) {
            setTimeout(() => {
                setPrevSkipClicked(skipClicked);
            }, 100);
        }
    }, [skipClicked]);

    let clearState = () => {
        dispatch(fetchProjectRequest());
        dispatch(clearStateProject());
        dispatch(clearStateChatHistory());
        dispatch(clearStateChatHistoryUI());
        dispatch(clearStateProjectArchitecture());
        dispatch(setSow(null));
        dispatch(setJira(null));
        dispatch(setUIView(null));
    };

    useEffect(() => {
        if (!projectId && !enterpriseOrganizationId && !uniqueId) {
            clearState();
        }
    }, [projectId, enterpriseOrganizationId, uniqueId]);

    //in Widget|Full Widget Interface
    useEffect(() => {
        if (apiKey && !enterpriseOrganizationId)
            dispatch(getEnterpriseOrganizationByApiKey(apiKey));
    }, [apiKey, enterpriseOrganizationId]);

    //in Full Widget Interface
    useEffect(() => {
        if (isFullWidget && uniqueId && apiKey) {
            if (prevSkipClicked === skipClicked) clearState();
            dispatch(getProjectByUniqueId({ apiKey, uniqueId }));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isFullWidget, apiKey, skipClicked]);

    //in Widget Interface
    useEffect(() => {
        if (isChatWidget && projectId && apiKey) {
            clearState();
            dispatch(getProjectByEnterpriseWidget({ apiKey, projectId }));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isChatWidget, apiKey]);

    //in Solo Interface
    useEffect(() => {
        if (isSolo && projectId) {
            if (prevSkipClicked === skipClicked) clearState();
            dispatch(getProjectByUser(projectId));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isSolo, skipClicked]);

    //in Share Interface
    useEffect(() => {
        if (isShare && projectId) {
            if (projectId != project?._id) clearState();
            dispatch(getProjectByLink(projectId));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isShare]);

    //in Enterprise Interface
    useEffect(() => {
        if (isEnterprise && enterpriseOrganizationId && projectId) {
            if (prevSkipClicked === skipClicked) clearState();
            dispatch(
                getProjectByEnterpriseOrganization({
                    projectId,
                    enterpriseOrganizationId
                })
            );
            dispatch(setProjectId(projectId));
        }
    }, [projectId, enterpriseOrganizationId, isEnterprise, skipClicked]);

    return (
        <ProjectContext.Provider
            value={{
                setSearchParams,
                allSearchParams,
                project,
                chatHistory,
                widgetState,
                projectArchitecture,
                projectId: projectId || project?._id,
                pageType,
                graphType,
                enterpriseOrganizationId
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export const useProject = () => useContext(ProjectContext);
