import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_ENTERPRISE_PROJECT } from '../../api/graphQL';
import ProjectTypes from './ProjectTypes';
import { Spinner } from '../Components/Spinner';
import {
    selectAPIKey,
    setUserEmail,
    setEnterpriseWidgetProjectId,
    setWidgetState
} from '../../redux/reducers/enterprise_widget/enterpriseWidget';
import { setProjectArchitecture } from '../../redux/reducers/generic/projectArchitecture';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    fetchProjectSuccess,
    setMeetingRequested,
    setShowRequestModal
} from '../../redux/reducers/generic/project';
import { setChatHistory } from '../../redux/reducers/generic/chatHistory';
import {
    selectEnterpriseOrganization,
    selectEnterpriseOrganizationError
} from '../../redux/reducers/enterprise/enterpriseOrganization';
import { addToProjects } from '../../redux/reducers/generic/projects';
import { projectTypes } from '../../constants';
import NewExistingProjectPicker from './NewExistingProjectPicker';
import UploadRFP from './UploadRFP';
import { v4 as uuidv4 } from 'uuid';
import { clientWithAPIKey } from '../../api/client';
import { Loading } from '../Components/Loading';

const CreateNewProject = ({
    id,
    uniqueId,
    generic,
    isEnterprise,
    isWidget,
    createNewId
}) => {
    const apiKey = useSelector(selectAPIKey);
    const error = useSelector(selectEnterpriseOrganizationError);
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [nda, setNda] = useState(false);

    const [option, setOption] = useState('new');
    const [allProjectTypes, setAllProjectTypes] = useState(
        searchParams.get('onlyGeneric') ? [projectTypes[2]] : projectTypes
    );

    const [selectedProjectType, setSelectedProjectType] = useState(
        allProjectTypes[0]
    );
    const [subType, setSubType] = useState(null);
    const [fileKey, setFileKey] = useState(null);
    const [email, setEmail] = useState('');

    const [newProjectName, setNewProjectName] = useState(
        searchParams.get('name')
    );

    let params = useParams();
    const metadata = searchParams.get('metadata');

    if (createNewId) uniqueId = uuidv4();

    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    let requireEmail = searchParams.get('noEmail') ? false : true;
    let allowRFPUpload = searchParams.get('noRFP') ? false : true;

    let typeParams = searchParams.get('projectTypes');

    useEffect(() => {
        if (typeParams) {
            let pjs = JSON.parse(JSON.stringify(projectTypes));
            let types = typeParams.split(',');
            let ps = [];
            types.forEach(type => {
                ps.push(pjs.find(pj => pj.type == type));
            });
            setSelectedProjectType(ps[0]);
            setAllProjectTypes(ps);
        }
    }, [typeParams]);

    const dispatch = useDispatch();

    let [createProject] = useMutation(CREATE_ENTERPRISE_PROJECT, {
        client: clientWithAPIKey(apiKey)
    });
    const navigate = useNavigate();

    //UPON Creation of Project, call
    const handleNewProjectSubmit = async e => {
        e.preventDefault();
        dispatch(setUserEmail(email));

        if (!newProjectName) return alert('Please enter a project name');
        if (requireEmail && !email) return alert('Please enter an email');

        //	e.preventDefault();
        if (newProjectName.trim() !== '') {
            setLoading(true);
            try {
                let type = getProjectType({
                    selectedProjectType,
                    option,
                    generic
                });

                let new_project = await createProject({
                    variables: {
                        name: newProjectName,
                        hasExistingContext: option === 'existing',
                        type: type,
                        userEmail: email,
                        metadata,
                        apiKey,
                        fileKeyS3: fileKey,
                        metadata,
                        uniqueId,
                        subType,
                        sendNda: nda,
                        isWidget
                    }
                });

                let allparams = Object.fromEntries(searchParams);

                let newParams = Object.keys(allparams).reduce((acc, key) => {
                    return acc + `${key}=${allparams[key]}&`;
                }, '');

                if (isEnterprise) {
                    dispatch(
                        addToProjects(new_project.data.createEnterpriseProject)
                    );
                    navigate(
                        `/enterprise/dashboard/${enterpriseOrganization._id}/clientProjects/${new_project.data.createEnterpriseProject._id}?${newParams}`
                    );
                } else if (uniqueId && !params.uniqueId) {
                    navigate(`${uniqueId}?${newParams}`, { relative: 'path' });
                    await new Promise(resolve => setTimeout(resolve, 200));
                }

                dispatch(
                    setEnterpriseWidgetProjectId(
                        new_project.data.createEnterpriseProject._id
                    )
                );

                //Set up Project
                dispatch(
                    fetchProjectSuccess(
                        new_project.data.createEnterpriseProject
                    )
                );

                //Set up Chat History
                dispatch(
                    setChatHistory(
                        new_project.data.createEnterpriseProject.chatHistory
                    )
                );

                try {
                    dispatch(
                        setProjectArchitecture({
                            graphs: new_project.data.createEnterpriseProject
                                .graphs
                        })
                    );
                } catch (err) {
                    console.log({ err });
                }

                dispatch(
                    setMeetingRequested(
                        new_project.data.createEnterpriseProject
                            .meetingRequested
                    )
                );

                if (fileKey) {
                    dispatch(setWidgetState('projectCompleted'));
                    // if (enterpriseOrganization?.calendlyLink)
                    //     dispatch(setShowRequestModal(true));
                } else dispatch(setWidgetState('chat'));

                setNewProjectName('');
                setLoading(false);
            } catch (err) {
                console.log({ err });
                alert(err.toString().replace('ApolloError: ', ''));
                setLoading(false);
            }
        }
    };

    if (allProjectTypes.length == 1 && allProjectTypes[0].type == 'other') {
        generic = true;
    }

    const handleCheckboxChange = event => {
        // Update the nda state to the new checkbox value
        setNda(event.target.checked);
    };

    return enterpriseOrganization ? (
        <form
            onSubmit={handleNewProjectSubmit}
            className="mb-4 flex flex-col items-center h-full justify-between sm:px-6 px-4"
        >
            <div>
                <p className="mb-4 mt-4 h-[20px] text-xl text-center tracking-tight font-extrabold leading-tight text-black">
                    {enterpriseOrganization?.projectCreationTitle}
                    {!enterpriseOrganization?.projectCreationTitle && (
                        <>
                            Start with an{' '}
                            <a
                                href="https://pre.dev"
                                className="font-bold text-blue-600"
                            >
                                idea
                            </a>
                        </>
                    )}
                </p>
                {!enterpriseOrganization?.onlyNewProject && !generic && (
                    <NewExistingProjectPicker
                        option={option}
                        setOption={setOption}
                    />
                )}
                {generic && (
                    <div className="mb-8 mt-4">
                        <p className="font-light text-gray-500 text-center dark:text-gray-400 text-md">
                            Get your project scope in under 5 minutes, by first
                            providing some initial information.
                        </p>
                    </div>
                )}
                {requireEmail && (
                    <input
                        id={'email'}
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className=" border-gray-400 px-4 py-2 w-full outline-none mb-2"
                    />
                )}
                <input
                    id={id || 'createNewProject'}
                    type="text"
                    placeholder="Project Name"
                    value={newProjectName}
                    onChange={e => setNewProjectName(e.target.value)}
                    className=" border-gray-400 px-4 py-2 w-full outline-none mb-4"
                />
                {!generic && (
                    <ProjectTypes
                        selectedProjectType={selectedProjectType}
                        setSelectedProjectType={setSelectedProjectType}
                        projectTypes={allProjectTypes}
                        subType={subType}
                        setSubType={setSubType}
                    />
                )}
                {allowRFPUpload && (
                    <UploadRFP
                        setFileKey={setFileKey}
                        isUploading={isUploading}
                        setUploading={setUploading}
                    />
                )}
                {!isEnterprise &&
                    enterpriseOrganization.apiKey ===
                        process.env.REACT_APP_LANDING_PAGE_WIDGET_API_KEY && (
                        <div class="flex items-center mt-4 mb-2 mx-auto">
                            <input
                                checked={nda}
                                onChange={handleCheckboxChange}
                                id="link-checkbox"
                                type="checkbox"
                                value=""
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                for="link-checkbox"
                                class="ms-2 text-sm font-light text-gray-900 dark:text-gray-300"
                            >
                                Send me an NDA for my project via email.
                            </label>
                        </div>
                    )}
            </div>
            <ContinueButton isLoading={isLoading} isUploading={isUploading} />
        </form>
    ) : error ? (
        <div className="h-full w-full w-[440px] flex justify-center items-center">
            <div className="text-center">
                <p className="mt-2 text-gray-500 mx-4">
                    There was an error loading the widget. Please contact the
                    site owner.
                </p>
            </div>
        </div>
    ) : (
        <div className="h-full w-full w-[440px] flex justify-center items-center">
            <Loading text="Widget" />
        </div>
    );
};

export default CreateNewProject;

export const getProjectType = ({
    selectedProjectType,
    option,
    generic = false
}) => {
    let type = selectedProjectType.type;
    if (generic) {
        type = 'other';
    }
    return type;
};

export const ContinueButton = ({ isLoading, isUploading }) => {
    return (
        <button
            type="submit"
            disabled={isLoading || isUploading}
            className={`mt-2 inline-flex items-center gap-x-2 rounded-md ${
                isLoading || isUploading ? 'bg-gray-400' : 'bg-blue-600'
            } px-6 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
        >
            {isLoading ? (
                <>
                    Creating Project
                    <Spinner w="5" />
                </>
            ) : (
                'Continue '
            )}
            {!isLoading && <>&rarr;</>}
        </button>
    );
};
