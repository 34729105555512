import React, { useState, useEffect, useRef } from 'react';
import { useProject } from '../../../context-providers/Project';
import GaugeCircle from '../../Components/magicui/gauge-circle';

export const Progress = ({
    chatLength = null,
    total = 5,
    width,
    height,
    textHeight
}) => {
    let { chatHistory } = useProject();
    let chat = chatHistory.data;

    // simple progress calculation we are just going to keep at local state for ease
    const [progress, setProgress] = useState(0); // Initial progress is 0%
    useEffect(() => {
        // Calculate progress percentage and update progress state
        if ((chat && chat.length > 1) || chatLength) {
            const length = chatLength ? chatLength : chat.length;
            let newProgress = chatLength ? length : Math.floor(length / 2);
            setProgress(Math.min(newProgress, total));
        } else {
            setProgress(0);
        }
    }, [chat, chatLength]);

    return (
        <div className="w-full">
            <div className="pb-4 w-full">
                <div className="flex mb-4 mt-2 gap-x-4 items-center">
                    <GaugeCircle
                        max={total}
                        className={`text-white text-${
                            textHeight ? textHeight : 'xs'
                        } ${height ? `h-[${height}px]` : 'h-[50px]'} ${
                            width ? `w-[${width}px]` : 'w-[50px]'
                        } px-0 `}
                        min={0}
                        value={progress}
                        gaugePrimaryColor="rgb(79 70 229)"
                        gaugeSecondaryColor="rgba(0, 0, 0, 0.1)"
                    />
                    <span className="text-sm font-medium text-blue-700 dark:text-white">
                        <span className="text-gray-500"> Responses </span>
                        {progress} / {total}
                    </span>
                </div>
            </div>
        </div>
    );
};
