import gql from 'graphql-tag';
import {
    jira,
    knowledgeBaseFile,
    loadingStatus,
    message,
    sow
} from '../helper';

export const PROJECT_LOADING_STATUS_SUBSCRIPTION = gql`
	subscription loadingStatusStream($projectId: ID!) {
		loadingStatusStream(projectId: $projectId) {
			${loadingStatus}
		}
	}
`;

export const SOW_SUBSCRIPTION = gql`
    subscription sowStream($projectId: ID!, $type: String!) {
        sowStream(projectId: $projectId, type: $type) {
            ${sow}
        }
    }
`;

export const messageUIView = `
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
userTitle
tagged
nodeId
`;

export const UIVIEW_SUBSCRIPTION = gql`
    subscription uiviewStream($projectId: ID!, $nodeId: ID!) {
        uiviewStream(projectId: $projectId, nodeId: $nodeId) {
           
            created
            projectId
            nodeId
            files {
          
                created
                projectId
                nodeId
                uiViewId
                code
                path
            }
            chatHistory {
                ${messageUIView}
            }            
            version       
            tested
            passed  
            fileKeyScreenshot
        }
    }
`;

export const JIRA_SUBSCRIPTION = gql`
    subscription jiraStream($projectId: ID!, $type: String!) {
        jiraStream(projectId: $projectId, type: $type) {
            ${jira}
        }
    }
`;

export const SUGGESTION_SUBSCRIPTION = gql`
    subscription suggestionStream($projectId: ID!, $randId: ID!) {
        suggestionStream(projectId: $projectId, randId: $randId) {
            suggestion
            completed
        }
    }
`;

export const RECOMMENDED_TECH_STACK_STREAM = gql`
    subscription recommendedTechStackStream($projectId: ID!) {
        recommendedTechStackStream(projectId: $projectId) {
            stack {
                name
                description
                reason
                link
                useFor
                helpfulLinks {
                    url
                    description
                }
            }
            version
        }
    }
`;

export const RECOMMENDED_JOB_ROLES_STREAM = gql`
    subscription recommendedJobRolesStream($projectId: ID!) {
        recommendedJobRolesStream(projectId: $projectId) {
            roles {
                title
                responsibilities
                qualifications
                requiredSkills
            }
            version
        }
    }
`;

export const GRAPH_SUBSCRIPTION = gql`
    subscription graphStream($projectId: ID!) {
        graphStream(projectId: $projectId) {
            _id
            nodes {
                _id
                id
                label
                hours
                description
            }
            edges {
                _id
                source
                target
                description
            }
            side
            projectId
            version
        }
    }
`;

export const FOLLOW_UP_QUESTION_SUBSCRIPTION = gql`
	subscription FollowUpQuestionStream($projectId: ID!, $nodeId: ID) {
		chatStream(projectId: $projectId, nodeId: $nodeId) {
			${message}
		}
	}
`;

export const ADD_EXISTING_PROJECT_CONTEXT_SUBSCRIPTION = gql`
    subscription existingProjectContextStream($existingProjectContextId: ID!) {
        existingProjectContextStream(
            existingProjectContextId: $existingProjectContextId
        )
    }
`;

export const KNOWLEDGE_BASE_FILE_SUBSCRIPTION = gql`
    subscription knowledgeBaseFileStream($fileId: ID!) {
        knowledgeBaseFileStream(fileId: $fileId) {
            ${knowledgeBaseFile}
        }
    }
`;

export const HERO_RANDOM_EXPERIENCE_SUBSCRIPTION = gql`
    subscription heroRandomExperienceStream($randId: String!) {
        heroRandomExperienceStream(randId: $randId) {
            suggestion
            completed
            randId
        }
    }
`;
