import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showWidgetIntegrationGuide: false,
    id: null,
    loading: true,
    data: null,
    error: null,
    milestones: null,
    milestonesLoading: null,
    milestonesError: null,
    showAdvancedConfiguration: false,
    defaultTechStackItems: [],
    knowledgeBaseFiles: []
};

export const enterpriseOrganizationSlice = createSlice({
    name: 'enterpriseOrganization',
    initialState,
    reducers: {
        toggleWidgetIntegrationGuide: (state, action) => {
            state.showWidgetIntegrationGuide =
                action.payload || !state.showWidgetIntegrationGuide;
        },
        toggleAdvancedConfiguration: (state, action) => {
            state.showAdvancedConfiguration =
                action.payload || !state.showAdvancedConfiguration;
        },
        clearStateEnterpriseOrganization: (state, action) => {
            state.loading = true;
            state.data = null;
            state.error = null;
            state.requestingMeeting = false;
            state.meetingRequested = false;
            state.showWidgetIntegrationGuide = false;
            state.milestones = null;
            state.milestonesLoading = null;
            state.milestonesError = null;
            state.showAdvancedConfiguration = false;
            state.enterpriseOrganizationId = null;
            state.knowledgeBaseFiles = [];
            state.defaultTechStackItems = [];
            state.id = null;
        },
        fetchEnterpriseOrganizationRequest: state => {
            state.loading = true;
            state.data = null;
        },
        setEnterpriseId: (state, action) => {
            state.id = action.payload;
        },
        fetchEnterpriseOrganizationSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
            if (action.payload.knowledgeBaseFiles) {
                state.knowledgeBaseFiles = action.payload.knowledgeBaseFiles;
            }
            if (action.payload.defaultTechStackItems) {
                state.defaultTechStackItems =
                    action.payload.defaultTechStackItems;
            }
        },
        addKnowledgeBaseFile: (state, action) => {
            state.knowledgeBaseFiles = [
                ...state.knowledgeBaseFiles,
                action.payload
            ];
        },
        removeKnowledgeBaseFile: (state, action) => {
            state.knowledgeBaseFiles = state.knowledgeBaseFiles.filter(
                file => file._id.toString() !== action.payload
            );
        },
        addDefaultTechStackItem: (state, action) => {
            state.defaultTechStackItems = [
                action.payload,
                ...state.defaultTechStackItems
            ];
        },
        removeDefaultTechStackItem: (state, action) => {
            state.defaultTechStackItems = state.defaultTechStackItems.filter(
                item => item._id !== action.payload
            );
        },
        updateDefaultTechStackItem: (state, action) => {
            state.defaultTechStackItems = state.defaultTechStackItems.map(
                file => {
                    if (file._id === action.payload.oldId) {
                        return action.payload.data;
                    }
                    return file;
                }
            );
        },
        updateKnowledgeBaseFile: (state, action) => {
            state.knowledgeBaseFiles = state.knowledgeBaseFiles.map(file => {
                if (file._id === action.payload._id) {
                    return action.payload;
                }
                return file;
            });
        },
        fetchEnterpriseOrganizationFailure: (state, action) => {
            state.loading = false;
            state.data = null;
            state.error = action.payload;
        }
    }
});
export const {
    clearStateEnterpriseOrganization,
    fetchEnterpriseOrganizationRequest,
    fetchEnterpriseOrganizationSuccess,
    fetchEnterpriseOrganizationFailure,
    toggleWidgetIntegrationGuide,
    toggleAdvancedConfiguration,
    addKnowledgeBaseFile,
    setEnterpriseId,
    updateKnowledgeBaseFile,
    removeKnowledgeBaseFile,
    addDefaultTechStackItem,
    removeDefaultTechStackItem,
    updateDefaultTechStackItem
} = enterpriseOrganizationSlice.actions;

export const selectEnterpriseOrganization = state => {
    return state.enterpriseOrganization.data;
};

export const selectEnterpriseOrganizationLoading = state =>
    state.enterpriseOrganization.loading;

export const selectShowWidgetIntegrationGuide = state =>
    state.enterpriseOrganization.showWidgetIntegrationGuide;

export const selectShowAdvancedConfiguration = state =>
    state.enterpriseOrganization.showAdvancedConfiguration;

export const selectEnterpriseOrganizationError = state =>
    state.enterpriseOrganization.error;

export const selectKnowledgeBaseFiles = state =>
    state.enterpriseOrganization.knowledgeBaseFiles;

export const selectDefaultTechStackItems = state =>
    state.enterpriseOrganization.defaultTechStackItems;

export default enterpriseOrganizationSlice.reducer;
