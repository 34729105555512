import { useSelector, useDispatch } from 'react-redux';
import {
    selectLoadingSowCustom,
    selectLoadingSowMVP,
    selectHideHours,
    setHideHours,
    selectSow
} from '../../../../redux/reducers/generic/sow';
import { TimerIcon, EyeClosedIcon } from '@radix-ui/react-icons';
import { selectEnterpriseOrganization } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { toast } from 'sonner';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useEffect, useState } from 'react';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const HideHours = ({ editor }) => {
    const dispatch = useDispatch();
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const mvpLoading = useSelector(selectLoadingSowMVP);
    const customLoading = useSelector(selectLoadingSowCustom);
    const hidden = useSelector(selectHideHours);
    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const isLoadingSow = type === 'mvp' ? mvpLoading : customLoading;
    const isShareUrl = window.location.pathname.includes('/share');
    const { isShare, showFullText } = useSubscriptionPayment();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobileAndScreenSize = () => {
            setIsMobile(
                window.navigator.userAgent.match(
                    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
                ) !== null || window.innerWidth <= 768
            );
        };

        checkMobileAndScreenSize();
        window.addEventListener('resize', checkMobileAndScreenSize);

        return () => {
            window.removeEventListener('resize', checkMobileAndScreenSize);
        };
    }, []);

    const handleClick = () => {
        if (isShare && !showFullText) {
            toast.info(
                'You need to login & upgrade your account to view the task hours'
            );
            return;
        }
        dispatch(setHideHours(!hidden));

        const headings = document.querySelectorAll(
            '.tiptap.ProseMirror.min-h-full h1, .tiptap.ProseMirror.min-h-full h2, .tiptap.ProseMirror.min-h-full h3'
        );
        let headingsArray = [];
        headings.forEach(heading => {
            headingsArray.push({
                tag: heading.tagName,
                id: heading.id,
                textContent: heading.textContent
            });
        });
        const projectTimelineHeading = headingsArray.find(heading =>
            heading.textContent.includes('Project Timeline')
        );
        if (projectTimelineHeading) {
            if (!isMobile) {
                window.location.hash = projectTimelineHeading.id;
            }
        }

        console.log(headingsArray);
        console.log(editor);
    };

    if (isShareUrl && enterpriseOrganization?.disableHoursInShareLink)
        return null;
    if (type !== 'mvp') return null;

    return (
        sow?.completed && (
            <>
                <button
                    onClick={handleClick}
                    data-tooltip-id="hide-hours-tooltip"
                    data-tooltip-content={hidden ? 'Show Hours' : 'Hide Hours'}
                    disabled={isLoadingSow}
                    className="p-2 text-purple-600 border border-purple-700 rounded-full hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-purple-300 transition-colors duration-200 relative"
                >
                    {hidden ? (
                        <TimerIcon className="h-4 w-4" />
                    ) : (
                        <EyeClosedIcon className="h-4 w-4" />
                    )}
                </button>
                <Tooltip id="hide-hours-tooltip" place="top" />
            </>
        )
    );
};
