import { Button } from "../../Components/magicui/ui/button";
import { cn } from "../../Components/magicui/lib/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import { toHumanPrice } from "../Docs/EditorHeader/PricingSection";
import { useState } from "react";
import { setShowRequestModal } from "../../../redux/reducers/generic/project";
import { useDispatch } from "react-redux";

const price = {
    type: 'hackathon',
    id: "beta",
    name: "UI/UX Accelerator",
    description: "Early access to the UI/UX Accelerator CodeGen to build your prototype.",
    features: [
      "Customized React Frontend Codebase",
      "12 months free of pre.dev Solo Premium",
      "24/7 Slack support channel",
    ],
    price: 150000,
};

export default function UiUxCard() {
  const dispatch = useDispatch();

  const onClick = () => {
    window.location.replace('https://buy.stripe.com/7sI17Jc4pe1gddSaDa');
  };

  const onRequestMeeting = () => {
    dispatch(setShowRequestModal(true));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      className={cn(
        "relative flex max-w-[400px] flex-col gap-6 rounded-xl border-2 p-6",
        "border-[var(--color-one)] text-black dark:text-white"
      )}
    >
      <div>
        <h2 className="text-xl font-semibold mb-2">{price.name}</h2>
        <p className="text-sm text-black/70 dark:text-white/70">{price.description}</p>
      </div>

      <div className="text-4xl font-bold">
        {toHumanPrice(price.price, 0)}
        <span className="text-sm font-normal"> total</span>
      </div>

      <Button
        className={cn(
          "w-full text-lg font-semibold",
          "transition-all duration-300 hover:ring-2 hover:ring-primary hover:ring-offset-2"
        )}
        onClick={onClick}
      >
        Get Started
      </Button>

      <Button
        className={cn(
          "w-full text-lg font-semibold",
          "transition-all duration-300 hover:ring-2 hover:ring-primary hover:ring-offset-2",
          "bg-secondary text-secondary-foreground hover:bg-secondary/80"
        )}
        onClick={onRequestMeeting}
      >
        Learn More
      </Button>

      <hr className="border-t border-neutral-200 dark:border-neutral-700" />

      {price.features && price.features.length > 0 && (
        <ul className="space-y-2">
          {price.features.map((feature, idx) => (
            <li key={idx} className="flex items-center gap-3 text-sm">
              <CheckIcon className="h-5 w-5 text-green-500" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      )}
    </motion.div>
  );
}
