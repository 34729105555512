const MainHeader = () => {
    return (
        <nav className="border-t border-gray-200">
            <div className="flex flex-wrap items-center justify-between mx-auto p-2">
                <div className="flex items-center"></div>
                <a href="https://pre.dev" className="flex items-center">
                    <span className="ml-2 mr-2 self-center text-xs font-thin whitespace-nowrap dark:text-white">
                        powered by
                    </span>
                    <img
                        src="/predev.png"
                        className="h-6 bg-gray-900 rounded p-1"
                        alt="Company Logo"
                    />
                    <span className="ml-2 self-center text-md font-semibold whitespace-nowrap dark:text-white">
                        pre.dev
                    </span>
                </a>
            </div>
        </nav>
    );
};

export default MainHeader;
