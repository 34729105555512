import { useSubscription } from '@apollo/client';
import { SOW_SUBSCRIPTION } from '../../../api/graphQL';
import { useSelector, useDispatch } from 'react-redux';
import { selectSow, setSow } from '../../../redux/reducers/generic/sow';
import { useProject } from '../../../context-providers/Project';
import {
    addToSowVersions,
    setSowVersion,
    setSowVersionLoading
} from '../../../redux/reducers/generic/project';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import BlockEditor from './BlockEditor';

export const Docs = ({ isEnterprise, isSolo, isShare }) => {
    const dispatch = useDispatch();
    let sow = useSelector(selectSow);
    const { projectId } = useProject();
    const type = sow?.type || 'mvp';

    useSubscription(SOW_SUBSCRIPTION, {
        variables: { projectId, type },
        shouldResubscribe: true,
        onData: data => {
            try {
                dispatch(setSow(data.data.data.sowStream));
                dispatch(
                    setSowVersion({
                        type,
                        version: data.data.data.sowStream.version
                    })
                );
                dispatch(
                    addToSowVersions({
                        type,
                        version: data.data.data.sowStream.version
                    })
                );
                dispatch(setSowVersionLoading({ type, loading: true }));

                if (data.data.data.sowStream.completed) {
                    dispatch(setSowVersionLoading({ type, loading: false }));
                    dispatch(getProjectByLink(projectId));
                }
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    return (
        <BlockEditor
            isEnterprise={isEnterprise}
            isShare={isShare}
            isSolo={isSolo}
        />
    );
};
