import { useState, useEffect, useCallback } from 'react';

// Assuming `generateTestFiles` and `extractErrorsWithPaths` are implemented elsewhere
import {
  indexTsx, 
  indexRouterTsx,
  stylesCss, 
  containerTsx,
  routerAppTsx,
  allTestsPassed,
  extractErrorsWithPaths
  } from '../SandpackFiles'
import {
    setUIView
} from '../../../../../redux/reducers/generic/projectArchitecture';
import {
    getUIView
} from '../../../../../redux/actions/solo/getUIView';
import {
    correctErrors
} from '../../../../../redux/actions/solo/correctErrors';
const compilePathsForUIViews = ({ uiViews, nodes }) => {
  // Adjust this function to correctly retrieve and format the node label.
  const getNodeLabelById = ({ nodeId, nodes }) => {
    const node = nodes.find(node => node._id === nodeId);
    return node ? node.label.replace(/\s+/g, '') : '';
  };

  const paths = {};

  uiViews.forEach(uiView => {
    const nodeLabel = getNodeLabelById({ nodeId: uiView.nodeId, nodes });

    // Ensure we are creating the correct path for the component files
    uiView.files.forEach(file => {
      const fileName = file.path === '/App.tsx' ? '/index.tsx' : file.path;
      const newPath = `/components/${nodeLabel}${fileName}`;
      paths[newPath] = { code: file.code, uiViewId: uiView._id };
    });
  });

  return paths;
};

export const useFileManagement = ({ 
  dispatch, 
  projectId, 
  nodeId, 
  node, 
  uiView, 
  setUIView, 
  setImageUrl, 
  setFileKeyScreenshot, 
  projectArchitecture 
}) => {

  const [files, setFiles] = useState(null);
  const [testFiles, setTestFiles] = useState(null);
  const [filesWithTests, setFilesWithTests] = useState(null);
  const [testingPhase, setTestingPhase] = useState(true);
  let graph = projectArchitecture?.graphs.find(g => g.side == 'frontend');
  let nodes = graph?.nodes

  // set files
  useEffect(()=>{
    if(uiView) {

      console.log(uiView)

      if(!uiView.routingTable) {
        setImageUrl(null);
        setFileKeyScreenshot(null);    

        const paths = 
            (uiView?.files || []).reduce((acc, file) => {
              acc[file.path] = { code: file.code };
              return acc;
            }, {});
        // Safely add container.tsx and index.tsx to the files object
        // This code assumes files is now always an object, never undefined


        paths['/Container.tsx'] = { code: containerTsx.trim() };
        paths['/index.tsx'] = { code: indexTsx.trim() };
        paths['/styles.css'] = { code: stylesCss.trim() };
        const tfiles = uiView?.files.reduce((testAcc, file) => {
            if (file.path.endsWith('.tsx')) { // Adjust as necessary for .js or .tsx files
              const testFilePath = file.path.replace(/\.tsx$/, '.test.tsx');
              const componentName = file.path.split('/').pop().replace(/\.tsx$/, '');

              const testFileContent = `
                import React from 'react';
                import { render } from '@testing-library/react';
                import { BrowserRouter as Router } from 'react-router-dom';
                import ${componentName} from '${file.path}';
                describe('${componentName}', () => {
                  it('renders without crashing', () => {
                    try {
                      render(<Router><${componentName} /></Router>);
                    }
                    catch(error) {
                      console.log(error)

                    }
                    // Add any additional assertions here
                  });
                });`;              
              testAcc[testFilePath] = { code: testFileContent };
            }
            return testAcc;
          }, {});

          const fwtests = {
            ...paths,
            ...tfiles
          };

        setFiles(paths)
        setTestFiles(tfiles)
        setFilesWithTests(fwtests)
      }
      else {

        const paths = compilePathsForUIViews({uiViews: uiView.views, nodes});

        // Safely add container.tsx and index.tsx to the files object
        // This code assumes files is now always an object, never undefined
        let routingTableArray = JSON.parse(uiView.routingTable);
        try {
          routingTableArray = JSON.parse(routingTableArray)
        }
        catch(e) {
          console.log(e)
        }
        
        const routesWithoutCore = routingTableArray.filter(route => route.component !== "Core");
        const routingFile = routerAppTsx({routes:JSON.stringify(routesWithoutCore)}).trim()
       
        paths['/App.tsx'] = { code: routingFile};
        paths['/index.tsx'] = { code: indexRouterTsx.trim() };
        paths['/styles.css'] = { code: stylesCss.trim() };
    
        setFiles(paths)
      }
    }

  },[uiView])

  useEffect(() => {

      if(uiView && uiView.passed && uiView.tested) {
        setTestingPhase(false)
      }     
      else {
        if(filesWithTests) {
          console.log(filesWithTests)
          setTestingPhase(true)
        }
      } 
  }, [filesWithTests]);   

  const regenerateUI = () => {
     // alert(node._id)
    dispatch(setUIView(null));
    dispatch(getUIView({nodeId: node._id, projectId: projectId, forceNew: true}))
  
  }

  const onTestComplete = (specs) => {
      const numberOfKeys = Object.keys(specs).length;
      if(numberOfKeys > 1) {
        // Extract errors and their paths into an array
        let errors = extractErrorsWithPaths(specs);     
        console.log(specs)   

        if (errors.length < 1) {
            setTestingPhase(false);
            dispatch(correctErrors({projectId,nodeId,version:0,errors}))
            setFilesWithTests(null)
        } else {
            if(errors.length > 1) {
              // don't include App.tsx unless its the only error
              errors = errors.filter(error => error.path !== '/App.tsx');
            }
         //   setTestingPhase(false);
            // todo versioning
            dispatch(correctErrors({projectId,nodeId,version:0,errors}))
            console.log('Not all tests pass or some tests have errors.');
        }
      }
      else {
        console.log(specs)
        console.log("didnt test right")
        console.log(filesWithTests)
      }
  };

  return { 
    files,
    testFiles,
    filesWithTests, 
    regenerateUI,
    onTestComplete,
    testingPhase,
    setFiles,
    setTestingPhase,
    setTestFiles,
    setFilesWithTests
  };
};
