import { SowVersions } from './SoWVersions';
import { CustomDocsToggle } from './CustomDocsToggle';
import {
    selectProjectId,
    selectSowVersion
} from '../../../../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import ThumbsUpDown from '../../../Components/ThumbsUpDown';
import { selectSow } from '../../../../redux/reducers/generic/sow';

export const TopHeader = ({ isEnterprise, isSidebarOpen }) => {
    const projectId = useSelector(selectProjectId);
    const sowVersion = useSelector(selectSowVersion);
    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';

    const thumbsId = `${projectId}-docs-${type}-${sowVersion[type]}`;
    return (
        <div className="w-full sticky top-0 z-2 py-2 bg-gray-50">
            <div className="flex flex-col grid grid-cols-3 mx-2 items-center">
                <div className="hidden sm:flex">
                    <CustomDocsToggle type={type} isEnterprise={isEnterprise} />
                </div>
                <div className="ml-4 sm:ml-0 flex flex-row gap-x-2 items-center justify-center mx-auto max-w-screen-sm text-center">
                    <img
                        src="/predevblack.png"
                        className="justify-center w-10 sm:w-14 rounded p-1"
                        alt="Company Logo"
                    />
                    <h2 className="text-lg sm:text-2xl tracking-tight font-extrabold leading-tight text-gray-900">
                        {isSidebarOpen ? 'Docs' : 'Documentation'}
                    </h2>
                </div>
                <div className="sm:hidden flex"></div>

                <div className="flex justify-between items-center">
                    <div className="flex justify-end w-full mr-0 gap-x-2">
                        {sow?.completed && !isSidebarOpen && (
                            <ThumbsUpDown
                                id={thumbsId}
                                metadata={[`${type}-docs`]}
                                baseColor="gray-500"
                            />
                        )}

                        {type && <SowVersions type={type} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
