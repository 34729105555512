import { useSelector, useDispatch } from 'react-redux';
import {
    selectLoadingJiraMVP,
    selectLoadingJiraHackathon,
    selectLoadingJiraFull,
    setJira,
    selectJiraPercentage,
    selectJiraVersion,
    setJiraPercentage,
    gettingJira,
    selectGettingJira,
    callingJira,
    selectCallingJira
} from '../../../../redux/reducers/generic/jira';
import { useSubscription } from '@apollo/client';
import { JIRA_SUBSCRIPTION } from '../../../../api/graphQL';
import { FaCheckCircle } from 'react-icons/fa';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { SignInButton } from './SignInButton';
import { SyncNewChanges } from './SyncNewChanges';
import { Syncing } from './Syncing';
import { selectSowVersion } from '../../../../redux/reducers/generic/project';
import { ProjectURL } from './ProjectURL';

export const JiraSync = ({ type, projectId }) => {
    // check if access token for atlassian is up to date
    const { accessTokenPayload, doesSessionExist } = useSessionContext();
    const expires = accessTokenPayload?.expires
        ? new Date(accessTokenPayload?.expires)
        : new Date();
    const now = new Date();
    // Calculate the threshold time (30 minutes before the token expires)
    const refreshThreshold = new Date(expires?.getTime() - 30 * 60000);
    const isSignedInWithAtlassian =
        accessTokenPayload?.isAtlassian &&
        now < refreshThreshold &&
        doesSessionExist;

    const sowVersion = useSelector(selectSowVersion);
    let jiraVersion = useSelector(selectJiraVersion);

    const dispatch = useDispatch();

    let loadingJiraFull = useSelector(selectLoadingJiraFull);
    let loadingJiraHackathon = useSelector(selectLoadingJiraHackathon);
    let loadingJiraMVP = useSelector(selectLoadingJiraMVP);

    let loadingPercent = useSelector(selectJiraPercentage);

    let retrievingJira = useSelector(selectGettingJira);
    let isCallingJira = useSelector(selectCallingJira);
    let isLoadingJira =
        retrievingJira ||
        isCallingJira ||
        (type == 'full'
            ? loadingJiraFull
            : type == 'mvp'
              ? loadingJiraMVP
              : loadingJiraHackathon);

    useSubscription(JIRA_SUBSCRIPTION, {
        variables: { projectId, type },
        shouldResubscribe: true,
        onData: data => {
            try {
                dispatch(
                    setJiraPercentage(data.data.data.jiraStream.percentage)
                );
                dispatch(setJira(data.data.data.jiraStream));
                dispatch(gettingJira(true));

                if (data.data.data.jiraStream.percentage >= 100) {
                    dispatch(gettingJira(false));
                    dispatch(setJiraPercentage(0));
                    dispatch(callingJira(false));
                }
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    const isSynced = jiraVersion == sowVersion[type];

    return (
        <div
            className={`w-full ${
                !jiraVersion ? 'justify-center' : 'justify-between'
            } items-center flex flex-col sm:flex-row px-2`}
        >
            {!isSignedInWithAtlassian && <SignInButton />}
            {isLoadingJira && (
                <Syncing
                    loadingPercent={loadingPercent}
                    type={type}
                    projectId={projectId}
                />
            )}
            {isSignedInWithAtlassian && !isLoadingJira && !isSynced && (
                <SyncNewChanges type={type} projectId={projectId} />
            )}
            {isSignedInWithAtlassian && !isLoadingJira && isSynced && (
                <JiraSynced />
            )}
            <ProjectURL />
            {jiraVersion && !isLoadingJira && (
                <span className="flex py-1 items-center text-sm font-medium text-gray-600 bg-gray-100 rounded-md px-3 shadow-sm border border-gray-200 sm:ml-2">
                    Synced to Docs v{jiraVersion}
                </span>
            )}
        </div>
    );
};

export const JiraSynced = () => {
    return (
        <button
            disabled={true}
            type="button"
            className={`flex gap-x-2 justify-center text-white bg-green-600 hover:bg-green-600 focus:ring-4 focus:outline-none focus:bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-blue-500 dark:hover:bg-green-600`}
        >
            <img src="/atlassian.svg" />
            Jira Synced
            <FaCheckCircle className="text-white" />
        </button>
    );
};
