import { useLocation } from 'react-router-dom';
import PricingSection from '../Docs/EditorHeader/PricingSection';
import UiUxCard from './ui_ux_beta';

export const CodeGen = ({ isSolo, isShare }) => {
    const { pathname } = useLocation();

    return (
        <>
            <h4 className="text-center text-3xl font-bold tracking-tight text-black dark:text-purple-600">
                CodeGen Access Coming Soon
            </h4>
            <div className="flex flex-col items-center justify-center max-w-screen-md mx-auto">
                <h2 className="text-md text-center tracking-tight text-black dark:text-white mt-2 mb-4">
                    Coming soon: Generate a full boilerplate codebase for any
                    project instantly. For now, get exclusive beta access where
                    our pre.dev team will personally craft your complete UI/UX,
                    giving you a head start on your project.
                </h2>

                {!pathname.includes('share') && <UiUxCard />}
            </div>
        </>
    );
};
