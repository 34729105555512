'use client';

import { cn } from '../Components/magicui/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { AlignJustify, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { UseCases } from './UseCases';
import { salesCalendlyLink } from '../../constants';

const menuItem = [
    {
        id: 1,
        label: 'How It Works',
        href: '/how-it-works'
    },
    {
        id: 1,
        label: 'Features',
        href: '/#features'
    },
    {
        id: 1,
        label: 'Pricing',
        href: '/#pricing'
    },
    {
        id: 1,
        label: 'Login'
    },
    {
        id: 2,
        label: 'Signup'
    },
    {
        id: 3,
        label: 'Contact Us',
        href: salesCalendlyLink
    }
];
export function SiteHeader({ onGetStarted }) {
    const sessionContext = useSessionContext();
    const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);

    useEffect(() => {
        const html = document.querySelector('html');
        if (html) html.classList.toggle('overflow-hidden', hamburgerMenuIsOpen);
    }, [hamburgerMenuIsOpen]);

    useEffect(() => {
        const closeHamburgerNavigation = () => setHamburgerMenuIsOpen(false);
        window.addEventListener('orientationchange', closeHamburgerNavigation);
        window.addEventListener('resize', closeHamburgerNavigation);

        return () => {
            window.removeEventListener(
                'orientationchange',
                closeHamburgerNavigation
            );
            window.removeEventListener('resize', closeHamburgerNavigation);
        };
    }, [setHamburgerMenuIsOpen]);

    return (
        <>
            <header className="bg-black fixed left-0 top-0 z-50 w-full border-b backdrop-blur-[12px]">
                <div className="container flex h-[3.5rem] items-center justify-between">
                    <div className="flex items-center gap-x-8">
                        <a href="/" className="flex items-center lg:pl-10">
                            <img
                                src="/predev.png"
                                className="h-6"
                                alt="Company Logo"
                            />
                            <span className="ml-2 self-center text-lg whitespace-nowrap dark:text-white">
                                pre.dev
                            </span>
                        </a>
                        <div className="hidden sm:flex">
                            <UseCases />
                        </div>
                    </div>

                    <div className="flex items-center ml-auto lg:gap-x-12">
                        <div className="hidden lg:flex gap-x-12 items-center">
                            <a
                                href="/how-it-works"
                                className="cursor-pointer text-white text-sm"
                            >
                                How It Works
                            </a>
                            <a
                                href="/#features"
                                className="cursor-pointer text-white  text-sm"
                            >
                                Features
                            </a>
                            <a
                                href="/#pricing"
                                className="cursor-pointer text-white  text-sm"
                            >
                                Pricing
                            </a>
                            <a
                                href={salesCalendlyLink}
                                className="cursor-pointer text-white  text-sm"
                            >
                                Contact
                            </a>
                        </div>
                        <LoginSection
                            onGetStarted={onGetStarted}
                            sessionContext={sessionContext}
                        />

                        <button
                            className="lg:hidden text-white"
                            onClick={() =>
                                setHamburgerMenuIsOpen(open => !open)
                            }
                        >
                            <span className="sr-only">Toggle menu</span>
                            {hamburgerMenuIsOpen ? (
                                <XIcon className="text-white" />
                            ) : (
                                <AlignJustify />
                            )}
                        </button>
                    </div>
                </div>
            </header>
            <AnimatePresence>
                <motion.nav
                    initial="initial"
                    exit="exit"
                    variants={{
                        initial: { opacity: 0, scale: 1 },
                        animate: {
                            scale: 1,
                            opacity: 1,
                            transition: { duration: 0.2, ease: 'easeOut' }
                        },
                        exit: {
                            opacity: 0,
                            transition: {
                                duration: 0.2,
                                delay: 0.2,
                                ease: 'easeOut'
                            }
                        }
                    }}
                    animate={hamburgerMenuIsOpen ? 'animate' : 'exit'}
                    className={cn(
                        'fixed left-0 top-0 z-50 h-screen w-full overflow-auto bg-background/70 backdrop-blur-[12px]',
                        {
                            'pointer-events-none': !hamburgerMenuIsOpen
                        }
                    )}
                >
                    <div className="container flex h-[3.5rem] items-center justify-between">
                        <a className="text-md flex items-center" href="/"></a>

                        <button
                            onClick={() =>
                                setHamburgerMenuIsOpen(open => !open)
                            }
                        >
                            <span className="sr-only">Toggle menu</span>
                            {hamburgerMenuIsOpen ? (
                                <XIcon className="text-white" />
                            ) : (
                                <AlignJustify />
                            )}
                        </button>
                    </div>
                    <div className="flex w-full justify-center pb-4 sm:hidden">
                        <UseCases />
                    </div>
                    <motion.ul
                        className="flex flex-col uppercase"
                        variants={{
                            open: { transition: { staggerChildren: 0.06 } }
                        }}
                        initial="initial"
                        animate={hamburgerMenuIsOpen ? 'open' : 'exit'}
                    >
                        {menuItem.map(item => (
                            <motion.li
                                key={item.id}
                                className="cursor-pointer border-grey-dark pl-6 py-0.5 border-b md:border-none"
                                variants={{
                                    initial: { y: '-20px', opacity: 0 },
                                    open: {
                                        y: 0,
                                        opacity: 1,
                                        transition: {
                                            duration: 0.3,
                                            ease: 'easeOut'
                                        }
                                    }
                                }}
                            >
                                <a
                                    className="text-white hover:text-grey flex h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300"
                                    href={item.href}
                                    onClick={
                                        !item.href
                                            ? onGetStarted
                                            : () =>
                                                  setHamburgerMenuIsOpen(
                                                      open => false
                                                  )
                                    }
                                >
                                    {item.label}
                                </a>
                            </motion.li>
                        ))}
                    </motion.ul>
                </motion.nav>
            </AnimatePresence>
        </>
    );
}

function LoginSection({ onGetStarted, sessionContext }) {
    if (sessionContext.loading) {
        return null;
    }

    return sessionContext?.doesSessionExist === true ? (
        <>
            <a
                onClick={onGetStarted}
                className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 h-9 px-4 py-2 mr-6 text-sm"
            >
                Dashboard
            </a>
        </>
    ) : (
        <div className="flex items-center gap-x-8">
            <a
                onClick={onGetStarted}
                className="cursor-pointer text-white text-sm"
            >
                Log in
            </a>
            <a
                onClick={onGetStarted}
                className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 h-9 px-4 py-2 mr-6 text-sm"
            >
                Sign up
            </a>
        </div>
    );
}
