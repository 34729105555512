import {
    MagnifyingGlassIcon,
    DocumentPlusIcon,
    LightBulbIcon,
    CurrencyDollarIcon,
    UserGroupIcon
} from '@heroicons/react/24/solid';
import { Icons } from '../../../ProjectDashboard/DockBar/icons';

export const project_discovery = {
    step_title: '1. Project Discovery',
    icon: <Icons.projectDiscovery className="h-12 text-white" />,
    description:
        "The Project Discovery phase allows users to define the scope and key details of their project. This phase also provides an option to upload any relevant documents like RFPs, and a 'Answer for me' feature is available to assist users who may be uncertain about specific questions. Additionally, users can receive a pricing estimate and connect with developers.",
    features: [
        {
            feature_title: 'Guided Questionnaire',
            description:
                'A structured set of questions that helps users clarify the essential details of their project, covering everything from target audience to core features.',
            icon: <MagnifyingGlassIcon className="h-12 text-white" />
        },
        {
            feature_title: 'RFP Analysis',
            description:
                'Allows users to upload documents such as RFPs or project briefs directly into the system, ensuring all critical information is captured in one place.',
            icon: <DocumentPlusIcon className="h-12 text-white" />
        },
        {
            feature_title: "'Answer for Me'",
            description:
                'An AI-powered tool that provides smart suggestions or automated answers when users are unsure how to respond to specific questions.',
            icon: <LightBulbIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Pricing Estimate & Developer Connection',
            description:
                'Based on the project details, receive an instant pricing estimate. Then, connect with skilled developers who match your project requirements.',
            icon: <CurrencyDollarIcon className="h-12 text-white" />
        }
    ],
    user_story:
        "Sarah uses the guided questionnaire to specify that 'Down to Chill' will focus on outdoor adventures, cultural events, and casual meetups. She's unsure about the best way to handle user profiles, so she uses 'Answer for me,' which suggests including social media integration for quick signups. After completing the discovery phase, Sarah receives a pricing estimate for her project and is connected with developers experienced in building social platforms.",
    screenshots: [
        {
            title: 'Project Discovery Interface',
            image_url: '/project_discovery_1.png'
        },
        {
            title: 'Pricing Estimate & Developer Connection',
            image_url: '/project_discovery_2.png'
        }
    ],
    enterprise_exclusive: false
};
