import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectId: null,
    loading: true,
    data: null,
    error: '',
    requestingMeeting: false,
    meetingRequested: false,
    showRequesModal: false,
    recommendedTechStack: [],
    recommendedJobRoles: [],
    recommendedTechStackLoading: false,
    recommendedJobRolesLoading: false,
    techStackVersion: null,
    jobRolesVersion: null,
    sowVersion: {
        hackathon: null,
        mvp: null,
        full: null,
        custom: null
    },
    sowVersionLoading: {
        hackathon: false,
        mvp: false,
        full: false,
        custom: false
    },
    techVersionLoading: false,
    jobRolesVersionLoading: false,
    hideArchitecture: false,
    existingProjectContexts: []
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        addToExistingProjectContexts: (state, action) => {
            state.existingProjectContexts.unshift(action.payload);
        },
        updateExistingProjectContext: (state, action) => {
            state.existingProjectContexts = state.existingProjectContexts.map(
                item =>
                    item._id === action.payload._id ? action.payload : item
            );
        },
        removeFromExistingProjectContexts: (state, action) => {
            state.existingProjectContexts =
                state.existingProjectContexts.filter(
                    item => item._id !== action.payload
                );
        },
        setHideArchitecture: (state, action) => {
            state.hideArchitecture = action.payload;
        },
        setShowRequestModal: (state, action) => {
            state.showRequesModal = action.payload;
        },
        setProjectId: (state, action) => {
            state.projectId = action.payload;
        },
        clearStateProject: state => {
            state.loading = true;
            state.data = null;
            state.error = null;
            state.requestingMeeting = false;
            state.meetingRequested = false;
            state.showRequesModal = false;
            state.recommendedTechStack = [];
            state.recommendedTechStackLoading = false;
            state.projectId = null;
            state.techStackVersion = null;
            state.sowVersion = {
                hackathon: null,
                mvp: null,
                full: null,
                custom: null
            };
            state.sowVersionLoading = {
                hackathon: false,
                mvp: false,
                full: false,
                custom: false
            };
            state.techVersionLoading = false;
            state.existingProjectContexts = [];
            state.recommendedJobRoles = [];
            state.recommendedJobRolesLoading = false;
            state.jobRolesVersion = null;
            state.jobRolesVersionLoading = false;
        },
        setRequestingMeeting: (state, action) => {
            state.requestingMeeting = action.payload;
        },
        setMeetingRequested: (state, action) => {
            state.meetingRequested = action.payload;
        },
        fetchProjectRequest: state => {
            state.loading = true;
            state.data = null;
        },
        addToTechStackVersions: (state, action) => {
            if (!state.data.techStackVersions.includes(action.payload))
                state.data.techStackVersions.unshift(action.payload);
        },
        addToJobRolesVersions: (state, action) => {
            if (!state.data.jobRolesVersions.includes(action.payload))
                state.data.jobRolesVersions.unshift(action.payload);
        },
        setTechStackVersion: (state, action) => {
            state.techStackVersion = action.payload;
        },
        setJobRolesVersion: (state, action) => {
            state.jobRolesVersion = action.payload;
        },
        setTechVersionLoading: (state, action) => {
            state.techVersionLoading = action.payload;
        },
        setJobRolesVersionLoading: (state, action) => {
            state.jobRolesVersionLoading = action.payload;
        },
        addToSowVersions: (state, action) => {
            const sowType = action.payload.type;
            const version = action.payload.version;
            const sowVersions = state?.data?.sowVersions?.[sowType];

            if (!sowVersions) return;

            if (!sowVersions.includes(version)) {
                sowVersions.unshift(version);
            }
        },
        setSowVersion: (state, action) => {
            state.sowVersion[action.payload.type] = action.payload.version;
        },
        setSowVersionLoading: (state, action) => {
            state.sowVersionLoading[action.payload.type] =
                action.payload.loading;
        },
        addToGraphVersions: (state, action) => {
            if (!state.data.graphVersions.includes(action.payload)) {
                state.data.graphVersions.unshift(action.payload);
            }
        },
        setRecommendedTechStack: (state, action) => {
            state.recommendedTechStack = action.payload;
        },
        setRecommendedJobRoles: (state, action) => {
            state.recommendedJobRoles = action.payload;
        },
        waitingForRecommendedTechStack: (state, action) => {
            state.recommendedTechStackLoading = action.payload;
        },
        waitingForRecommendedJobRoles: (state, action) => {
            state.recommendedJobRolesLoading = action.payload;
        },
        fetchSowStatusesSuccess: (state, action) => {
            state.data.sow_mvp_up_to_date = action.payload.sow_mvp_up_to_date;
            state.data.sow_custom_up_to_date =
                action.payload.sow_custom_up_to_date;
        },
        fetchProjectSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
            if (action.payload.recommendedTechStack) {
                state.recommendedTechStack =
                    action.payload.recommendedTechStack;
            }
            if (action.payload.latestTechStackVersion) {
                state.techStackVersion = action.payload.latestTechStackVersion;
            }
            if (action.payload.existingProjectContexts) {
                state.existingProjectContexts =
                    action.payload.existingProjectContexts;
            }
            if (action.payload.recommendedJobRoles) {
                state.recommendedJobRoles = action.payload.recommendedJobRoles;
            }
            if (action.payload.latestJobRolesVersion) {
                state.jobRolesVersion = action.payload.latestJobRolesVersion;
            }
            state.error = null;
        },
        fetchProjectFailure: (state, action) => {
            state.loading = false;
            state.data = null;
            state.error = action.payload;
        }
    }
});
export const {
    setHideArchitecture,
    setProjectId,
    clearStateProject,
    setMeetingRequested,
    fetchProjectRequest,
    fetchProjectSuccess,
    fetchProjectFailure,
    setRequestingMeeting,
    setRecommendedTechStack,
    waitingForRecommendedTechStack,
    setShowRequestModal,
    setTechStackVersion,
    fetchSowStatusesSuccess,
    addToTechStackVersions,
    addToJobRolesVersions,
    addToGraphVersions,
    setTechVersionLoading,
    setSowVersion,
    setSowVersionLoading,
    addToExistingProjectContexts,
    removeFromExistingProjectContexts,
    updateExistingProjectContext,
    addToSowVersions,
    setRecommendedJobRoles,
    waitingForRecommendedJobRoles,
    setJobRolesVersion,
    setJobRolesVersionLoading
} = projectSlice.actions;

export const selectShowRequestModal = state => state.project.showRequesModal;
export const selectProject = state => state.project.data;
export const selectProjectId = state => state.project.projectId;
export const selectRequestingMeeting = state => state.project.requestingMeeting;
export const selectMeetingRequested = state => state.project.meetingRequested;
export const selectProjectLoading = state => state.project.loading;
export const selectProjectError = state => state.project.error;
export const selectRecommendedTechStack = state =>
    state.project.recommendedTechStack;
export const selectRecommendedTechStackLoading = state =>
    state.project.recommendedTechStackLoading;
export const selectHideArchitecture = state => state.project.hideArchitecture;
export const selectTechStackVersion = state => state.project.techStackVersion;
export const selectTechVersionLoading = state =>
    state.project.techVersionLoading;
export const selectSowVersion = state => state.project.sowVersion;
export const selectSowVersionLoading = state => state.project.sowVersionLoading;
export const selectExistingProjectContexts = state =>
    state.project.existingProjectContexts;
export const selectRecommendedJobRoles = state =>
    state.project.recommendedJobRoles;
export const selectRecommendedJobRolesLoading = state =>
    state.project.recommendedJobRolesLoading;
export const selectJobRolesVersion = state => state.project.jobRolesVersion;
export const selectJobRolesVersionLoading = state =>
    state.project.jobRolesVersionLoading;

export default projectSlice.reducer;
