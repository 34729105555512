import { useCallback, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SparklesText from '../../Components/magicui/SparklesText';
import WordPullUp from '../../Components/magicui/WordPullUp';
import { submitHeroExperience } from '../../../redux/actions/solo/submitHeroExperience';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useInView } from 'framer-motion';
import { toast } from 'sonner';
import PricingSection from '../../Pricing/pricing-section';
import { Progress } from '../../ProjectDashboard/ArchitectureGraph/Progress';
import { Loader2 } from 'lucide-react';
import { ProjectDashboard } from '../../ProjectDashboard';
import { ParticlesEffect } from './ParticlesEffect';
import { ScrollIndicator } from './ScrollIndicator';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import { useSubscription } from '@apollo/client';
import { HERO_RANDOM_EXPERIENCE_SUBSCRIPTION } from '../../../api/graphQL';
import { v4 as uuidv4 } from 'uuid';
import { initiateHeroRandomExperience } from '../../../redux/actions/enterprise_widget/initiateHeroRandomExperience';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { FaDice } from 'react-icons/fa';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const HeroExperience = () => {
    const navigate = useNavigate();
    const [showInput, setShowInput] = useState(true);
    const [restartAnimation, setRestartAnimation] = useState(false);
    const [showTextarea, setShowTextarea] = useState(false);
    const [startTextUp, setStartTextUp] = useState(false);
    const [answer, setAnswer] = useState('');
    const [answers, setAnswers] = useState([]);
    const [countdown, setCountdown] = useState(null);
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;

    const [searchParams] = useSearchParams();
    const pricing = searchParams ? searchParams.get('pricing') == 'true' : null;

    const [loading, setLoading] = useState(null);
    const [message, setMessage] = useState(
        'What is a product or feature you would like to build today?'
    );
    const [placeholder, setPlaceholder] = useState(
        'Generate a development plan in seconds...'
    );
    const [questionIndex, setQuestionIndex] = useState(0);
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const [isRandomButtonDisabled, setIsRandomButtonDisabled] = useState(false);
    const [randId, setRandId] = useState(null);

    useSubscription(HERO_RANDOM_EXPERIENCE_SUBSCRIPTION, {
        variables: { randId },
        skip: !randId,
        onSubscriptionData: ({ subscriptionData }) => {
            console.log({ subscriptionData });
            if (subscriptionData?.data?.heroRandomExperienceStream) {
                const { suggestion, completed } =
                    subscriptionData.data.heroRandomExperienceStream;
                setAnswer(suggestion);
                if (completed) {
                    setIsRandomButtonDisabled(false);
                }
            }
        }
    });

    const randomIdea = async answer => {
        setIsRandomButtonDisabled(true);
        let rand = uuidv4();
        setRandId(rand);
        try {
            let { suggestion } = await dispatch(
                initiateHeroRandomExperience({
                    randId: rand,
                    answers,
                    inspiration: answer
                })
            );
            setAnswer(suggestion);
            setIsRandomButtonDisabled(false);
        } catch (error) {
            console.error('Error in randomIdea:', error.message);
            setIsRandomButtonDisabled(false);
        }
    };

    const processAnswer = async () => {
        if (answer.length < 1) {
            toast('Please enter an answer.');
            return;
        }
        if (questionIndex == 2) {
            const email = answer.trim();
            // Email validation regex pattern
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setAnswer('');
            if (!emailPattern.test(email)) {
                // Display toast message for invalid email
                toast('Please enter a valid email address.');
                return; // Prevent form submission
            }
            setShowInput(false);
            setLoading(true);
            setMessage(
                'Thank you for your responses.  Please wait while we generate your initial architecture...'
            );
            let countdownTime = 5;
            setCountdown(countdownTime);

            let project_id;

            if (window.innerWidth <= 768) {
                document.activeElement.blur(); // This will lower the keyboard on mobile
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 300); // Delay to ensure the scroll happens before lowering the keyboard
            }

            try {
                let countInterval = setInterval(() => {
                    if (countdownTime > 0) {
                        countdownTime--;
                        setCountdown(countdownTime);
                    } else {
                        if (project_id) {
                            navigate('/demo/' + project_id);
                            clearInterval(countInterval);
                        }
                    }
                }, 1000);

                try {
                    project_id = await dispatch(
                        submitHeroExperience({
                            answers: answers,
                            userEmail: email,
                            username: email
                        })
                    );
                    dispatch(getProjectByLink(project_id));
                } catch (e) {
                    navigate('/auth');
                }
            } catch (e) {
                navigate('/auth');
            }
        } else {
            setAnswer('');
            setShowInput(false);
            const newAnswers = answers;
            newAnswers.push(answer);
            if (questionIndex == 0) {
                newAnswers.push(
                    'Thank you.  What is the first thing a user can do or see?'
                );
                setPlaceholder(
                    'Ex: they see a customer analytics dashboard...'
                );
                setMessage(
                    'Thank you.  What is the first thing a user can do or see?'
                );
            } else {
                setPlaceholder('Email address');
                setMessage(
                    'Great!  What email should we send your project to?'
                );
                console.log({ userEmail });
                if (userEmail) {
                    setTimeout(() => {
                        setAnswer(userEmail);
                    }, 100);
                }
            }
            setQuestionIndex(questionIndex + 1);
            setAnswers(newAnswers);
        }
    };

    const handleEnterPress = async e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            await processAnswer();
        }
    };

    const handleEnterSubmit = async e => {
        await processAnswer();
    };

    useEffect(() => {
        if (!showInput) {
            //document.body.classList.add('no-scroll');
            const timeout = setTimeout(() => {
                setRestartAnimation(true);
                setShowInput(true);
                setShowTextarea(false);
                setStartTextUp(false);
                const restartTimeout = setTimeout(() => {
                    setRestartAnimation(false);
                }, 100); // Short duration to trigger the re-render
                return () => clearTimeout(restartTimeout);
            }, 500); // Duration of the exit animation
            return () => clearTimeout(timeout);
        }
    }, [showInput]);

    const handleWordPullUpAnimationEnd = () => {
        setStartTextUp(true);
        setTimeout(() => {
            setShowTextarea(true);
        }, 0.5);
    };

    return (
        <>
            {!projectId && (
                <div className="pt-16 absolute top-0 left-0">
                    <Progress chatLength={questionIndex} total={2} />
                </div>
            )}
            <div className="hero-section pb-10">
                <main className="max-w-screen-xl min-w-0 flex-auto lg:static lg:max-h-full lg:overflow-visible">
                    <ScrollIndicator />
                    {
                        <section
                            className="mb-8 pb-16 mx-auto flex max-w-8xl flex-col overflow-hidden sm:pb-10 lg:px-20 lg:pb-24"
                            style={{ marginTop: '57px' }}
                        >
                            {!projectId ? (
                                <div className="pl-8 pt-20 pr-8 mb-20">
                                    <AnimatePresence>
                                        {!restartAnimation && showInput && (
                                            <motion.div
                                                initial={{
                                                    opacity: 1,
                                                    y: 0
                                                }}
                                                animate={{
                                                    opacity: 1,
                                                    y: 0
                                                }}
                                                exit={{
                                                    opacity: 0,
                                                    y: '30vh'
                                                }}
                                                transition={{
                                                    duration: 0.5
                                                }}
                                                className="relative"
                                            >
                                                <motion.div
                                                    initial={{ y: 0 }}
                                                    animate={{
                                                        y: startTextUp
                                                            ? -20
                                                            : 50
                                                    }}
                                                    transition={{
                                                        duration: 0.25
                                                    }}
                                                    className="relative mb-4"
                                                >
                                                    <SparklesText
                                                        sparklesCount={8}
                                                    >
                                                        <WordPullUp
                                                            className="text-4xl mt-[120px] md:mt-[120px] font-medium tracking-[-0.02em] text-white dark:text-white md:text-6xl"
                                                            words={message}
                                                            onAnimationEnd={
                                                                handleWordPullUpAnimationEnd
                                                            }
                                                        />
                                                    </SparklesText>
                                                </motion.div>
                                                {showTextarea && (
                                                    <motion.div
                                                        initial={{
                                                            opacity: 0
                                                        }}
                                                        animate={{
                                                            opacity: 1
                                                        }}
                                                        transition={{
                                                            duration: 1
                                                        }}
                                                        className="relative"
                                                    >
                                                        {loading ? (
                                                            <div className="flex flex-col items-center">
                                                                <Loader2 className="h-16 w-16 mx-auto text-slate-50 animate-spin text-center" />
                                                                {countdown !==
                                                                    null && (
                                                                    <div className="text-slate-400 text-sm mt-4">
                                                                        Redirecting
                                                                        you in{' '}
                                                                        {
                                                                            countdown
                                                                        }
                                                                        ...
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <textarea
                                                                    type={
                                                                        questionIndex ==
                                                                        1
                                                                            ? 'email'
                                                                            : 'text'
                                                                    }
                                                                    rows="4"
                                                                    required
                                                                    autoFocus
                                                                    value={
                                                                        answer
                                                                    }
                                                                    onChange={e =>
                                                                        setAnswer(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className={`text-green-300 w-[calc(100%-2rem)] min-h-[200px] md:pl-8 md:mx-4 border-transparent focus:border-transparent focus:ring-0 font-light terminal-input text-left ${
                                                                        answer.length <
                                                                        50
                                                                            ? 'md:text-5xl text-3xl'
                                                                            : answer.length <
                                                                                100
                                                                              ? 'md:text-4xl text-2xl'
                                                                              : answer.length <
                                                                                  150
                                                                                ? 'md:text-3xl text-xl'
                                                                                : 'md:text-2xl text-lg'
                                                                    }`}
                                                                    placeholder={
                                                                        placeholder
                                                                    }
                                                                    onKeyDown={
                                                                        handleEnterPress
                                                                    }
                                                                />
                                                                <div className="flex flex-row items-center justify-between mt-4">
                                                                    <RandomButtons
                                                                        answer={
                                                                            answer
                                                                        }
                                                                        isRandomButtonDisabled={
                                                                            isRandomButtonDisabled
                                                                        }
                                                                        questionIndex={
                                                                            questionIndex
                                                                        }
                                                                        randomIdea={
                                                                            randomIdea
                                                                        }
                                                                    />
                                                                    <div
                                                                        onClick={
                                                                            handleEnterSubmit
                                                                        }
                                                                        className="flex gap-x-2 items-center text-center rounded-lg cursor-pointer right-0 text-slate-400 text-xs bg-gray-800 hover:bg-gray-700 rounded py-2 px-4 transition duration-200"
                                                                        title="Press Enter or click here to submit"
                                                                    >
                                                                        Press
                                                                        Enter to
                                                                        submit
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </motion.div>
                                                )}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ) : (
                                <div
                                    className="w-full flex flex-col "
                                    style={{ height: 'calc(100vh - 128px)' }}
                                >
                                    {!pricing ? (
                                        <ProjectDashboard
                                            isShare={true}
                                            isHomePage={true}
                                        />
                                    ) : (
                                        <PricingSection />
                                    )}
                                </div>
                            )}
                        </section>
                    }
                </main>
            </div>
        </>
    );
};

export const RandomButtons = ({
    answer,
    isRandomButtonDisabled,
    questionIndex,
    randomIdea
}) => {
    return (
        <div className="flex flex-col sm:flex-row text-sm items-center justify-between gap-x-4 gap-y-4">
            {questionIndex <= 1 && (
                <button
                    onClick={() => randomIdea(answer)}
                    className={`flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-3 rounded-full shadow-lg ${
                        isRandomButtonDisabled
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:shadow-xl hover:scale-105 transition duration-200'
                    }`}
                    disabled={isRandomButtonDisabled}
                >   
                {
                    questionIndex === 0 &&
                    <FaDice className="w-5 h-5" />
                }
                    <span>
                        {answer
                            ? window.innerWidth < 768
                                ? 'Elaborate'
                                : 'Elaborate on Idea'
                            : questionIndex === 1
                              ? 'Answer for me'
                              : 'Random'}
                    </span>
                </button>
            )}
            {questionIndex === 0 && answer?.length > 0 && (
                <button
                    onClick={() => randomIdea(null)} // Assuming you want to generate a new idea without an answer
                    className={`flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-green-500 text-white font-bold py-2 px-4 rounded-full ${
                        isRandomButtonDisabled
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:shadow-xl hover:scale-105 transition duration-200'
                    }`}
                    disabled={isRandomButtonDisabled}
                >
                    <FaDice className="w-5 h-5" />
                    <span>New Idea</span>
                </button>
            )}
        </div>
    );
};
