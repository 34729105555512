import { BookOpenIcon, UserGroupIcon, WindowIcon, DocumentTextIcon, PuzzlePieceIcon, ClockIcon, MapIcon } from '@heroicons/react/24/solid';
import { Icons } from '../../../ProjectDashboard/DockBar/icons'

export const scoping_widget = {
    step_title: 'Scoping Widget',
    icon: <WindowIcon className="h-12 text-white" aria-hidden="true"/>,
    description:
        'The Widget Management feature allows enterprise users to integrate pre.dev widgets into their own websites. These widgets facilitate user engagement by offering features like real-time chat, scheduling, and feedback collection. Users can manage domain settings and widget configurations from a centralized dashboard.',
    features: [
        {
            feature_title: 'Embed Pre.dev Widgets',
            description:
                'Easily embed pre.dev widgets on your website to enable features like real-time chat, project scoping, and client interactions.',
            icon: <PuzzlePieceIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Domain and Widget Management',
            description:
                'Manage widget integrations, domain settings, and security configurations from a single interface, ensuring smooth operations and data protection.',
            icon: <MapIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Integrated Scheduling',
            description:
                'Enable clients and team members to schedule meetings directly through the widgets, syncing with calendars and other scheduling tools.',
            icon: <ClockIcon className="h-12 text-white" />
        }
    ],
    user_story:
        "To facilitate early user signups, Sarah embeds a pre.dev widget on the 'Down to Chill' landing page, allowing visitors to schedule a demo or get in touch with her team directly.",
    screenshots: [
        {
            title: 'Widget Management Interface',
            image_url: '/scoping_widget_2.png'
        },
        {
            title: 'Widget Management Interface',
            image_url: '/scoping_widget_1.png'
        }
    ],
    enterprise_exclusive: true
};