import { MapIcon } from '@heroicons/react/24/solid';
import { Icons } from '../../../ProjectDashboard/DockBar/icons'

export const roadmap = {
    step_title: '4. Product Roadmap',
    icon: <Icons.roadmap className="h-12 text-white" />,
    description:
        'Create a detailed roadmap that visualizes the development timeline for your project. The roadmap includes milestones, task dependencies, and critical paths. Users can integrate this roadmap with popular project management tools like Jira and Linear, enabling seamless synchronization of tasks and timelines.',
    features: [
        {
            feature_title: 'Automatic Roadmap Generation',
            description:
                'Generates a visual roadmap based on the project details, including milestones, task dependencies, and critical paths.',
            icon: <MapIcon className="h-12 text-white" />
        },
        {
            feature_title: 'Jira/Linear Integration',
            description:
                'Allows users to sync the generated roadmap with Jira, Linear, and other project management tools, ensuring all tasks and timelines are aligned across platforms.',
            icon: <img src='/atlassian.svg' className="h-12 text-white" />
        },
    ],
    user_story:
        "Sarah sees a clear timeline for the development of 'Down to Chill.' The roadmap highlights the critical path for launching the MVP, and she integrates it with Jira to assign tasks to her development team.",
    screenshots: [
        {
            title: 'Roadmap Overview',
            image_url: '/roadmap_1.png'
        },
        {
            title: 'Jira Sync Interface',
            image_url: '/roadmap_2.png'
        }
    ],
    enterprise_exclusive: false
};