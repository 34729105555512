import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    setAPIKey,
    setUniqueId
} from '../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { useSelector } from 'react-redux';
import {
    clearStateProject,
    selectProjectError
} from '../../../redux/reducers/generic/project';
import CreateNewProject from '../../CreateNewProject';
import { v4 as uuidv4 } from 'uuid';
import { useProject } from '../../../context-providers/Project';
import { ProjectDashboard } from '../../ProjectDashboard';
import { Header } from '../Header';
import MainHeader from '../ChatWidgetPage/Widget/MainHeader';
import { selectEnterpriseOrganization } from '../../../redux/reducers/enterprise/enterpriseOrganization';

const FullWidget = () => {
    let { apiKey, uniqueId } = useParams();
    const dispatch = useDispatch();

    dispatch(setAPIKey(apiKey));
    dispatch(setUniqueId(uniqueId));
    const { project } = useProject();
    const error = useSelector(selectProjectError);
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    if (error && error.message) {
        error = error.message;
    }
    useEffect(() => {
        dispatch(clearStateProject());
    }, []);

    let showNewProject =
        !uniqueId || (error && error.includes(`No such project for uniqueId`));

    return (
        <div
            className={`h-full ${
                showNewProject ? 'min-h-[650px]' : ''
            } flex flex-col items-center w-full`}
        >
            {showNewProject && !project && (
                <div className="flex justify-center items-center h-full w-full">
                    <section
                        className={`md:h-[590px] h-full md:border-gray-200 md:border rounded-lg md:shadow-lg object-cover mx-auto max-w-[440px] bg-white flex-grow flex flex-col justify-between`}
                    >
                        <CreateNewProject
                            isWidget={true}
                            uniqueId={uniqueId || uuidv4()}
                        />
                        <MainHeader />
                    </section>
                </div>
            )}

            {project && <ProjectDashboard hasHeader={true} isWidget={true} />}
        </div>
    );
};

export default FullWidget;
