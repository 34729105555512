import { Task, ViewMode, Gantt } from './Gantt';
import 'gantt-task-react/dist/index.css';
import { useProject } from '../../../context-providers/Project';
import { useState, useEffect } from 'react';

function addBusinessHours(startDate, hoursToAdd) {
    let endDate = new Date(startDate);

    while (hoursToAdd > 0) {
        // Check if current day is a weekend
        if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
            // Not a weekend, so a business day
            if (hoursToAdd >= 8) {
                // Add a full day and decrease hours by 8
                endDate.setDate(endDate.getDate() + 1);
                hoursToAdd -= 8;
            } else {
                // Add remaining hours and set hoursToAdd to 0
                endDate.setHours(endDate.getHours() + hoursToAdd);
                hoursToAdd = 0;
            }
        } else {
            // It's a weekend, so just move to the next day
            endDate.setDate(endDate.getDate() + 1);
        }
    }

    // Adjust for going over the end of a business day
    if (endDate.getHours() >= 17) {
        endDate.setHours(9 + (endDate.getHours() - 17));
        endDate.setDate(endDate.getDate() + (endDate.getDay() === 5 ? 3 : 1)); // Skip weekend if it's Friday
    }

    return endDate;
}

export const RoadmapChart = ({ type, projectId, sow }) => {
    const [tasks, setTasks] = useState(null);
    let { project } = useProject();

    let startDate =
        project && project.created ? new Date(project.created) : new Date();

    const handleExpanderClick = task => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    const mapTickets = ({ tickets }) => {
        let start = startDate;
        let tasksArr = [];
        let lastProjectId = null;
        let lastDependencyId = null;

        tickets.forEach(ticket => {
            const estimate = ticket.timeoriginalestimate
                ? parseInt(ticket.timeoriginalestimate.slice(0, -1))
                : 0;
            let hoursToAdd = estimate;
            let newStart;
            if (type != 'hackathon')
                newStart = addBusinessHours(start, hoursToAdd);
            else newStart = new Date(start.getTime() + hoursToAdd * 3600000);

            if (ticket.issuetype == 'Epic') lastProjectId = ticket.id;

            let task = {
                start: start,
                end: newStart,
                hoursEstimate: ticket.timeoriginalestimate,
                name: ticket.summary,
                id: ticket.id,
                progress: 0,
                type:
                    ticket.issuetype == 'Epic'
                        ? 'project'
                        : ticket.issuetype == 'Story'
                          ? 'milestone'
                          : 'task',
                hideChildren: false,
                displayOrder: 1,
                dependencies:
                    ticket.issuetype == 'Sub-task' ? [lastDependencyId] : null,
                project: ticket.issuetype != 'Epic' ? lastProjectId : ''
            };

            // Check if a task with the same ID already exists in the array
            if (!tasksArr.some(existingTask => existingTask.id === task.id)) {
                tasksArr.push(task);
            }

            if (ticket.issuetype == 'Sub-task') {
                lastDependencyId = ticket.id;
                start = newStart;
            }
        });

        setTasks(tasksArr);
    };

    useEffect(() => {
        setTasks(null);
    }, [type, projectId]);

    useEffect(() => {
        if (sow?.milestones) {
            mapTickets({
                tickets: extractTicketsFromSowMilestones(sow)
            });
        }
    }, [sow]);

    return (
        tasks &&
        tasks.length > 0 && (
            <div className="overflow-y-scroll">
                <Gantt
                    viewMode={
                        type != 'hackathon' ? ViewMode.Day : ViewMode.Hour
                    }
                    onExpanderClick={handleExpanderClick}
                    tasks={tasks}
                    fontFamily={'Inter, sans-serif'}
                    fontSize={'12px'}
                    style={{ 'text-overflow': 'ellipsis', overflowY: 'scroll' }}
                />
            </div>
        )
    );
};

function extractTicketsFromSowMilestones(sowObj) {
    const milestones = JSON.parse(JSON.stringify(sowObj.milestones));
    let jiraTickets = [];
    let currentEpicId;
    let currentStoryId;

    let count = 0;

    for (let i = 0; i < milestones?.length; i++) {
        const milestone = milestones?.[i];

        // Calculate the total hours for the milestone by summing up the hours of its stories' subtasks
        if (sowObj.internal_version !== 3) {
            milestone.hours = milestone.stories?.reduce(
                (totalMilestoneHours, story) => {
                    return (
                        totalMilestoneHours +
                        (story.subTasks?.reduce((totalStoryHours, subTask) => {
                            return totalStoryHours + (subTask.hours || 0);
                        }, 0) || 0)
                    );
                },
                0
            );
        }

        let epicTicket = {
            issuetype: 'Epic',
            description: `Milestone ${i + 1}: ${milestone.description}`,
            epicName: `Milestone ${i + 1}: ${milestone.description}`,
            timeoriginalestimate: milestone.hours + 'h',
            summary: `Milestone ${i + 1}: ${milestone.description}`,
            id: count,
            displayOrder: count,
            key: `EPIC-${i}`
        };
        jiraTickets.push(epicTicket);
        currentEpicId = `EPIC-${i}`; // Simulated epic ID assignment

        count++;
        for (let j = 0; j < milestone?.stories?.length; j++) {
            const story = milestone.stories?.[j];
            // Calculate the total hours for the story by summing up the hours of its subtasks
            story.hours = story?.subTasks?.reduce((total, subTask) => {
                return total + (subTask.hours || 0);
            }, 0);

            let storyTicket = {
                issuetype: 'Story',
                description: story.description,
                displayOrder: count,
                timeoriginalestimate: story.hours + 'h',
                summary: story.description,
                epicLink: currentEpicId,
                id: count,
                key: `STORY-${j}`
            };
            jiraTickets.push(storyTicket);
            currentStoryId = `STORY-${j}`; // Simulated story ID assignment

            count++;
            for (let k = 0; k < story?.subTasks?.length; k++) {
                const subTask = story?.subTasks?.[k];
                let subTaskTicket = {
                    issuetype: 'Sub-task',
                    displayOrder: count,
                    description: subTask.description,
                    timeoriginalestimate: subTask.hours + 'h',
                    summary: subTask.description,
                    epicLink: currentEpicId,
                    parentId: currentStoryId,
                    id: count,
                    key: `SUBTASK-${k}`
                };
                jiraTickets.push(subTaskTicket);
                count++;
            }
        }
    }

    return jiraTickets;
}
