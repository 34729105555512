import { useDispatch, useSelector } from 'react-redux';
import { useProject } from '../../../../context-providers/Project';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
    selectSowVersion,
    selectSowVersionLoading
} from '../../../../redux/reducers/generic/project';
import { Spinner } from '../../../Components/Spinner';
import { getSow } from '../../../../redux/actions/solo/getSow';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { selectSow } from '../../../../redux/reducers/generic/sow';

export const SowVersions = ({ type }) => {
    const { project } = useProject();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [clickVersion, setClickVersion] = useState(null);

    const versions = project.sowVersions[type];
    const sowVersion = useSelector(selectSowVersion);
    const sowVersionLoading = useSelector(selectSowVersionLoading);
    const sow = useSelector(selectSow);

    const handleShowTechStack = async version => {
        dispatch(getSow(project._id.toString(), type, version));
        setClickVersion(version);
        setIsOpen(false);
    };

    useEffect(() => {
        if (sow) {
            if (!sow.completed) setClickVersion(sow.version);
        }
    }, [sow]);

    if (sowVersion[type] == null) return null;

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="gap-x-1 bg-blue-500 hover:bg-blue-600 transition-colors duration-200 px-3 py-1.5 text-white inline-flex items-center rounded-full text-xs font-medium shadow-sm"
            >
                {sowVersionLoading[type] ? (
                    <>
                        <span>v{clickVersion}</span>
                        <Spinner w="4" />
                    </>
                ) : (
                    <>
                        <span>v{sowVersion[type]}</span>
                        <ChevronDownIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                        />
                    </>
                )}
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setIsOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 mb-4"
                                    >
                                        Select Version
                                    </Dialog.Title>
                                    <div className="mt-2 max-h-60 overflow-y-auto">
                                        {versions.map((item, index) => (
                                            <button
                                                key={index}
                                                onClick={() =>
                                                    handleShowTechStack(item)
                                                }
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-700 transition-colors duration-150 ease-in-out"
                                            >
                                                v{item}
                                            </button>
                                        ))}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
