import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { EmptyProjects } from './EmptyProjects';
import { toast } from 'sonner';
import { useProjects } from '../../../context-providers/Projects';
import { ProjectsList } from './ProjectsList';
import { Title } from './title';
import { TopHeader } from './TopHeader';
import { SubscribeBanner } from './SubscribeBanner';
import LoadMoreButton from '../../Home/Sidebars/LoadMoreButton';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    paginateProjects,
    selectProjectsSkip,
    selectProjectsTotal,
    selectSearchLoading
} from '../../../redux/reducers/generic/projects';
import { projectsLimit } from '../../../constants';
import { SearchProjects } from './Search';
import { getApiDomain } from '../../../auth_config';

export const ClientProjects = ({}) => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const subscriptionStatus = enterpriseOrganization?.subscriptionStatus;
    const searchLoading = useSelector(selectSearchLoading);
    const { projects } = useProjects();
    if (!projects) projects = [];
    const dispatch = useDispatch();
    const divRef = useRef(null);

    const totalProjects = useSelector(selectProjectsTotal);
    const skip = useSelector(selectProjectsSkip);

    useEffect(() => {
        const div = divRef.current;

        const handleScroll = () => {
            let showShowMoreButton = totalProjects > skip + projectsLimit;

            const isBottom =
                div?.scrollTop + div?.clientHeight >= div?.scrollHeight;
            if (isBottom && showShowMoreButton) {
                dispatch(paginateProjects());
            }
        };

        div.addEventListener('scroll', handleScroll);

        return () => {
            div.removeEventListener('scroll', handleScroll);
        };
    }, [divRef]);

    let [showCoupon, setShowCoupon] = useState(false);
    useEffect(() => {
        let getCoupon = async () => {
            try {
                let response = await fetch(
                    getApiDomain() + '/should_show_coupon'
                );
                let couponData = await response.json();
                setShowCoupon(couponData.showCoupon);
            } catch (e) {
                console.log(e);
            }
        };
        getCoupon();
    }, []);

    return (
        <>
            <div className="h-full" ref={divRef} style={{ overflow: 'scroll' }}>
                {/* Main column */}
                <div className="flex flex-col">
                    <main className="flex-1">
                        {/* Page title & actions */}
                        <Title />
                        {projects && projects.length > 0 && <TopHeader />}
                        <div className="flex items-center justify-center px-4 sm:px-2 lg:px-8">
                            <SearchProjects showFilter={true} />
                        </div>
                        {projects && projects.length > 0 ? (
                            <div>
                                <ProjectsList />
                                <div id="page-end" />

                                {!searchLoading && (
                                    <div className="flex justify-center">
                                        <LoadMoreButton />
                                    </div>
                                )}
                          
                            </div>
                        ) : (
                            <EmptyProjects />
                        )}
                    </main>
                </div>
            </div>
        </>
    );
};
