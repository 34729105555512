import { formatExecutiveSummary } from './format_executive_summary';
import { formatTItle } from './format_title';
import { formatGoalsAndObjectives } from './format_goals_and_objectives';
import { formatUserStories } from './format_user_stories';
import { formatSuccessMetrics } from './format_success_metrics';
import { formatCoreFunctionalities } from './format_core_functionalities';
import { formatStakeholders } from './format_stakeholders';
import { formatTechStack } from './format_tech_stack';
import { formatMilestones } from './format_milestones';
import { formatHeading, formatParagraph, formatText } from './helper';

export function convertJSONtoTipTap(sow, hide_hours, previewMode) {
    const formattedContent = [
        ...formatTItle(sow, false),
        ...formatExecutiveSummary(sow, false),
        ...formatGoalsAndObjectives(sow, false),
        ...formatUserStories(sow, false),
        ...formatSuccessMetrics(sow, false),
        ...formatCoreFunctionalities(sow, false),
        ...formatStakeholders(sow, false),
        ...formatTechStack(sow, false)
    ];

    formattedContent.push(...formatMilestones(sow, hide_hours, previewMode));

    return {
        type: 'doc',
        content: formattedContent
    };
}
