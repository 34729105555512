'use client';

import { BorderBeam } from '../Components/magicui/border-beam';
import TextShimmer from '../Components/magicui/text-shimmer';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { motion, useInView } from 'framer-motion';
import React from 'react';
import { useRef } from 'react';
import Modal from '../Components/Modal';
import { HeroExperience } from './HeroExperience';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { salesCalendlyLink } from '../../constants';

export default function HeroSection({ onGetStarted }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });
    const { projectId } = useParams();

    return (
        <section
            id="hero"
            ref={ref}
            className="mb-36 dark relative mx-auto pt-32 sm:pt-64 sm:mb-52 max-w-[80rem] px-6 text-center md:px-8"
        >
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1 }}
                className="backdrop-filter-[12px] inline-flex h-7 items-center justify-between rounded-full border border-white/5 bg-white/10 px-3 text-xs text-white dark:text-black transition-all ease-in hover:cursor-pointer hover:bg-white/20 group gap-1"
            >
                <TextShimmer className="inline-flex items-center justify-center">
                    <a href="#features">
                        ✨ Introducing{' '}
                        <span className="text-blue-600">pre.dev</span> AI
                        Product Architect
                    </a>{' '}
                    <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
                </TextShimmer>
            </motion.div>
            <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1, delay: 0.2 }}
                className="bg-gradient-to-br dark:from-white from-black from-30% dark:to-white/40 to-black/40 bg-clip-text py-6 text-5xl font-medium leading-none tracking-tighter text-transparent text-balance sm:text-6xl md:text-7xl lg:text-8xl"
            >
                <span className="text-blue-600">pre.dev</span> is the fastest
                way
                <br className="hidden md:block" /> to plan and build products.
            </motion.h1>
            <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1, delay: 0.4 }}
                className="mb-12 text-lg tracking-tight text-gray-400 md:text-xl text-balance"
            >
                Generate comprehensive product documentation, detailed roadmaps,
                and customized boilerplate codebases in seconds with{' '}
                <a href="#features" className="cursor-pointer text-blue-600">
                    our AI Product Architect
                </a>
                .
            </motion.p>
            <div className="flex justify-center">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={
                        isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                    }
                    transition={{ duration: 1, delay: 0.6 }}
                    className="cursor-pointer max-w-xl text-lg leading-normal text-gray-400 lg:text-xl"
                >
                    <a
                        onClick={onGetStarted}
                        style={{ borderRadius: '0.5rem' }}
                        className=" text-primary-foreground shadow inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 opacity-[0.9] mt-4 inline-flex items-center justify-center px-5 py-3 mr-3 font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-900"
                    >
                        Get Started
                        <svg
                            className="w-5 h-5 ml-2 -mr-1"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ borderRadius: '0.5rem' }}
                        href={salesCalendlyLink}
                        className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 mt-4 inline-flex items-center justify-center px-5 py-3 font-medium text-center text-white border rounded-lg border-gray-700 hover:bg-gray-700 focus:ring-4 focus:ring-gray-800"
                    >
                        Talk to Sales
                    </a>
                </motion.div>
            </div>
            {/* <HeroModal isInView={isInView} projectId={projectId} /> */}
        </section>
    );
}

const HeroModal = ({ isInView, projectId }) => {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1, delay: 0.6 }}
                className="mt-3"
            >
                <button
                    className="mt-4 relative inline-flex items-center justify-center px-6 py-3 overflow-hidden font-bold text-white rounded-lg group"
                    style={{
                        background:
                            'linear-gradient(45deg, #15803d, #0d9488, #047857)',
                        boxShadow: '0 4px 15px rgba(79, 70, 229, 0.4)',
                        transition: 'all 0.3s ease'
                    }}
                    onClick={() => setOpen(true)}
                >
                    <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out opacity-0 bg-gradient-to-r from-green-400 via-teal-500 to-emerald-600 group-hover:opacity-100"></span>
                    <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3 transition-all duration-300 ease-out transform origin-top scale-y-0 group-hover:scale-y-100"></span>
                    <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5 transition-all duration-300 ease-out transform origin-bottom scale-y-0 group-hover:scale-y-100"></span>
                    <span className="relative flex items-center transition-all duration-300 ease-out group-hover:scale-110">
                        Instant Demo
                        <svg
                            className="w-5 h-5 ml-2 transition-all duration-300 ease-in-out group-hover:translate-x-2 group-hover:scale-110"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 10V3L4 14h7v7l9-11h-7z"
                            ></path>
                        </svg>
                    </span>
                </button>
            </motion.div>
            <Modal
                title={null}
                open={open}
                onClose={close}
                maxWidth={`max-w-${
                    projectId ? 'full' : '4xl'
                } min-w-full sm:min-w-0 dark z-[200] sm:mt-16 mt-12 mb-0`}
                bgColor="bg-black"
            >
                <HeroExperience />
            </Modal>
        </>
    );
};
