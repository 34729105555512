import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { addToEnterpriseOrganizations } from '../../../redux/reducers/enterprise/enterpriseOrganizations';
import { Spinner } from '../../Components/Spinner';
import { clearStateProjects } from '../../../redux/reducers/generic/projects';

export const Input = ({ id }) => {
    const dispatch = useDispatch();
    const [newOrganizationName, setNewOrganizationName] = useState('');
    const [isLoading, setLoading] = useState(false);
    let [createEnterpriseOrganization, { data, error }] = useMutation(
        CREATE_ENTERPRISE_ORGANIZATION
    );
    const navigate = useNavigate();

    const handleNewOrganizationSubmit = async e => {
        e.preventDefault();
        if (newOrganizationName.trim() !== '') {
            setLoading(true);

            try {
                let new_enterprise_organization =
                    await createEnterpriseOrganization({
                        variables: {
                            name: newOrganizationName
                        }
                    });
                dispatch(
                    addToEnterpriseOrganizations(
                        new_enterprise_organization.data
                            .createEnterpriseOrganization
                    )
                );
                dispatch(clearStateProjects());
                setNewOrganizationName('');
                setLoading(false);
                navigate(
                    `/enterprise/dashboard/${new_enterprise_organization.data.createEnterpriseOrganization._id}/clientProjects`
                );
            } catch (err) {
                alert(err);
            }
        }
    };

    return (
        <div>
            <form
                onSubmit={handleNewOrganizationSubmit}
                className="mb-4 flex flex-col items-center justify-center"
            >
                <input
                    id={id || 'createNewOrganization'}
                    type="text"
                    placeholder="Input Organization Name"
                    value={newOrganizationName}
                    onChange={e => setNewOrganizationName(e.target.value)}
                    className=" border-gray-400 px-4 py-2 w-full outline-none mb-4"
                />
                <button
                    type="submit"
                    disabled={isLoading}
                    className="mt-4 inline-flex items-center gap-x-2 rounded-md bg-blue-600 px-5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    {isLoading ? (
                        <>
                            Creating Organization
                            <Spinner w="5" />
                        </>
                    ) : (
                        'Create Organization'
                    )}
                    {!isLoading && (
                        <PaperAirplaneIcon
                            className="h-5 w-5 mr-2"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </form>
        </div>
    );
};
