import { project_discovery } from './project_discovery';
import { documentation } from './documentation';
import { architecture } from './architecture';
import { roadmap } from './roadmap';
import { knowledge_management } from './knowledge_management';
import { user_management } from './user_management';
import { scoping_widget } from './scoping_widget';

export const userJourney = {
    app_name: 'Down to Chill',
    story: "Sarah, a budding entrepreneur, wants to build 'Down to Chill,' a social app that helps users find and join local activities and events based on shared interests. She uses pre.dev to take her idea from concept to execution seamlessly.",
    steps: [
        project_discovery,
        documentation,
        architecture,
        roadmap,
        knowledge_management,
        user_management,
        scoping_widget
    ]
};
