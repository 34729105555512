import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import {
    selectSkillLevel,
    selectSow,
    setSkillLevel
} from '../../../../redux/reducers/generic/sow';
import { Icon } from '../TipTap/components/ui/Icon';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export default function SkillLevel() {
    const dispatch = useDispatch();
    const skillLevel = useSelector(selectSkillLevel);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const sow = useSelector(selectSow);
    const { isSubbed } = useSubscriptionPayment();

    const options = [
        {
            name: 'Junior Developers',
            value: 'junior',
            icon: 'GraduationCap'
        },
        {
            name: 'Intermediate Developers',
            value: 'intermediate',
            icon: 'Code'
        },
        {
            name: 'Senior Developers',
            value: 'senior',
            icon: 'Briefcase'
        }
    ];

    const handleSkillLevelChange = value => {
        dispatch(setSkillLevel(value));
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const currentOption = options.find(option => option.value === skillLevel);

    return (
        sow?.completed && (
            <div className="relative" ref={dropdownRef}>
                <button
                    onClick={toggleDropdown}
                    className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <Icon
                        name={currentOption ? currentOption.icon : 'User'}
                        className="mr-2 h-5 w-5"
                    />
                    <span className="hidden md:inline">
                        {currentOption
                            ? currentOption.name
                            : 'Select Skill Level'}
                    </span>
                    <Icon name="ChevronDown" className="ml-2 h-5 w-5" />
                </button>
                {isDropdownOpen && (
                    <div className="z-10 absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                        >
                            {options.map(option => (
                                <button
                                    key={option.value}
                                    onClick={() =>
                                        handleSkillLevelChange(option.value)
                                    }
                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    role="menuitem"
                                >
                                    <Icon
                                        name={option.icon}
                                        className="mr-3 h-5 w-5"
                                    />
                                    <span className="flex-grow">
                                        {option.name}
                                    </span>
                                </button>
                            ))}
                        </div>
                        <div className="px-4 py-2 text-xs text-gray-500 border-t border-gray-100">
                            Hours will be estimated for the selected role
                        </div>
                    </div>
                )}
            </div>
        )
    );
}
