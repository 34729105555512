import { useState, useEffect, useRef } from 'react';
// Importing components and hooks from sandpack-react and themes
import {
    Sandpack,
    SandpackProvider,
    SandpackLayout,
    SandpackCodeEditor,
    SandpackTests,
    SandpackFileExplorer,
    SandpackPreview,
    SandpackConsole,
    useSandpack,
    useSandpackConsole,
    Navigator
} from '@codesandbox/sandpack-react';
//import { toast } from 'sonner';
import { UploadWireframe } from './UploadWireframe';
import { Spinner } from '../../../Components/Spinner'; // Spinner component for loading states
import { atomDark } from '@codesandbox/sandpack-themes'; // Theme for Sandpack components
import { autocompletion, completionKeymap } from '@codemirror/autocomplete'; // Autocompletion for code editor
import { ChatInput } from './Chat/ChatInput'; // Chat input component
import { ChatHistory } from './Chat/ChatHistory'; // Chat history component
import { BlahIcon } from '@heroicons/react/24/outline';
import { useActiveCode } from '@codesandbox/sandpack-react';

// Tabs for UI navigation
const tabs = [
    { name: 'Preview', current: true },
    { name: 'Chat', current: false }
];

// Helper function for conditional class names
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const CustomSandpackEditor = ({ currentFiles, setFiles }) => {
    // const codeVersion = useSelector(selectSowVersion);
    const { sandpack } = useSandpack();
    const { activeFile, files } = sandpack;
    const { code, updateCode } = useActiveCode();
    useEffect(() => {
        if (code && currentFiles) {
            try {
                const newFiles = currentFiles;
                console.log(newFiles);
                console.log(activeFile);
                newFiles[activeFile].code = code;
                //console.log(newFiles)
                setFiles(newFiles);
            } catch (err) {
                console.log(err);
            }
        }
    }, [files]);

    return <SandpackCodeEditor autoReload={true} />;
};
// UIBuilder component
export const UIBuilder = ({
    projectId,
    nodeId,
    activeTab,
    setActiveTab,
    node,
    regenerateUI,
    uiView,
    testingPhase,
    editingPhase,
    loadingUIView,
    filesWithTests,
    files,
    setFiles,
    isSolo,
    imageUrl,
    onTestComplete,
    handleSave
}) => {
    // Main render function
    return (
        <div className="my-8">
            <hr className="border-gray-200" />

            {/* Navigation tabs */}
            <div className="border-b border-gray-200">
                <nav className="flex justify-center" aria-label="Tabs">
                    {tabs.map(tab => (
                        <a
                            key={tab.name}
                            onClick={() => setActiveTab(tab.name)}
                            className={classNames(
                                activeTab === tab.name
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'w-full border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer'
                            )}
                            aria-current={
                                activeTab === tab.name ? 'page' : undefined
                            }
                        >
                            {tab.name}
                        </a>
                    ))}
                </nav>
            </div>

            {/* UI Builder Header */}
            <div className="flex justify-between items-start">
                <div>
                    <h2 className="my-2 text-lg tracking-tight font-extrabold leading-tight text-black">
                        {node?.label}
                    </h2>
                    <p className="font-light text-gray-500 dark:text-gray-400 text-md mb-4">
                        {node?.description}
                    </p>
                </div>

                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={handleSave}
                        className="mt-4 mr-8 flex-none text-white bg-green-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Save Code
                    </button>
                    {/* Regenerate UI Button */}
                    <button
                        type="button"
                        onClick={regenerateUI}
                        className="ml-4 mt-4 mr-8 flex-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <>
                            {!loadingUIView && uiView ? (
                                <>
                                    Regenerate UI{' '}
                                    <span aria-hidden="true">&rarr;</span>
                                </>
                            ) : uiView && testingPhase ? (
                                <>
                                    Testing UI... <Spinner w="5" />
                                </>
                            ) : uiView && editingPhase ? (
                                <>
                                    Editing UI... <Spinner w="5" />
                                </>
                            ) : !uiView ? (
                                <>
                                    Loading UI... <Spinner w="5" />{' '}
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    </button>
                </div>
            </div>

            {/* Conditional rendering based on testingPhase */}
            {testingPhase ? (
                <>
                    {filesWithTests ? (
                        <div className="max-h-[300px] mb-8">
                            {/* SandpackProvider for testing phase */}
                            <SandpackProvider
                                template="test-ts"
                                theme={atomDark}
                                options={{
                                    rtl: true,
                                    initMode: 'immediate',
                                    showConsole: true,
                                    autoReload: true,
                                    autorun: true,
                                    externalResources: [
                                        'https://cdn.tailwindcss.com',
                                        'https://rsms.me/inter/inter.css'
                                    ]
                                }}
                                customSetup={{
                                    dependencies: {
                                        '@heroicons/react': 'latest',
                                        react: 'latest',
                                        'react-router-dom': 'latest',
                                        'react-dom': 'latest',
                                        html2canvas: 'latest',
                                        '@headlessui/react': 'latest',
                                        '@testing-library/react': 'latest',
                                        '@testing-library/dom': 'latest',
                                        'react-scroll': 'latest'
                                    }
                                }}
                                files={filesWithTests}
                            >
                                {/* SandpackTests component */}
                                <div className="max-h-[300px] overflow-auto">
                                    <SandpackTests
                                        verbose={false}
                                        onComplete={specs =>
                                            onTestComplete(specs)
                                        }
                                    />
                                </div>
                            </SandpackProvider>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    {files && (
                        <SandpackProvider
                            template="react-ts"
                            theme={atomDark}
                            options={{
                                initMode: 'immediate',
                                rtl: true,
                                autoReload: true,
                                autorun: true,
                                externalResources: [
                                    'https://cdn.tailwindcss.com',
                                    'https://rsms.me/inter/inter.css'
                                ]
                            }}
                            customSetup={{
                                dependencies: {
                                    '@heroicons/react': 'latest',
                                    react: 'latest',
                                    'react-router-dom': 'latest',
                                    'react-dom': 'latest',
                                    html2canvas: 'latest',
                                    '@headlessui/react': 'latest',
                                    'react-scroll': 'latest',
                                    'html-figma': 'latest',
                                    '@craco/craco': 'latest'
                                }
                            }}
                            files={files}
                        >
                            <div id="screenId">
                                {activeTab === 'Preview' ? (
                                    <>
                                        {node?.id === 'core' && <Navigator />}
                                        <SandpackPreview className="h-screen w-full" />

                                        <SandpackLayout>
                                            <SandpackFileExplorer
                                                autoHiddenFiles={false}
                                            />
                                            <CustomSandpackEditor
                                                currentFiles={files}
                                                setFiles={setFiles}
                                            />
                                        </SandpackLayout>
                                    </>
                                ) : (
                                    <>
                                        <div className="h-[320px] md:mx-20">
                                            <ChatHistory isSolo={isSolo} />
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="md:mx-20">
                                <ChatInput
                                    isSolo={isSolo}
                                    image_url={imageUrl}
                                />
                            </div>
                        </SandpackProvider>
                    )}
                </>
            )}
        </div>
    );
};
