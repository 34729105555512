import gql from 'graphql-tag';
import {
    graph,
    jira,
    loadingStatus,
    project,
    recommended_job_roles,
    recommended_tech_stack,
    sow,
    thumbsRating
} from '../helper';

export const GET_USER_SUBSCRIPTION = gql`
    query userSubscriptionStatus {
        userSubscriptionStatus {
            tier
            status
            created_at
            current_period_end
            duration
            current_period_start
            price
            cancel_at_period_end
        }
    }
`;

export const GET_PROJECT_LOADING_STATUS = gql`
	query loadingStatusByProject($projectId: ID!) {
		loadingStatusByProject(projectId: $projectId) {
			${loadingStatus}
		}
	}
`;

export const GET_SOW_STATUSES = gql`
    query sowStatuses($projectId: ID!) {
        sowStatuses(projectId: $projectId) {
            sow_mvp_up_to_date
            sow_custom_up_to_date
        }
    }
`;

export const GET_SOW = gql`
    query getSow($projectId: ID!, $type: String!, $version: Int) {
        getSow(projectId: $projectId, type: $type, version: $version) {
            ${sow}
        }
    }
`;

export const GET_THUMBS_RATING = gql`
    query getThumbsRating($id: String!) {
        getThumbsRating(id: $id) {
            ${thumbsRating}
        }
    }
`;

export const message = `
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
userTitle
tagged
nodeId
`;

export const GET_UIVIEW = gql`
    query getUIView($projectId: ID!, $nodeId: ID!, $version: Int, $forceNew: Boolean, $image_url: String) {
        getUIView(projectId: $projectId, nodeId: $nodeId, version: $version, forceNew: $forceNew, image_url: $image_url) {
            _id
            created
            projectId
            nodeId
            chatHistory {
                ${message}
            }
            files {
                _id
                created
                projectId
                nodeId
                uiViewId
                code
                path
                version
            }
            version
            tested
            passed  
            fileKeyScreenshot
        }
    }
`;

export const GET_UIVIEWS = gql`
    query getUIViews($projectId: ID!, $nodeId: ID, $version: Int) {
        getUIViews(projectId: $projectId, nodeId: $nodeId, version: $version) {
            routingTable
            views {
                _id
                created
                projectId
                nodeId
                chatHistory {
                    ${message}
                }
                files {
                    _id
                    created
                    projectId
                    nodeId
                    uiViewId
                    code
                    path
                    version
                }
                version
                tested
                passed  
                fileKeyScreenshot
            }
        }
    }
`;

export const GET_JIRA = gql`
    query getJira($projectId: ID!, $type: String!) {
        getJira(projectId: $projectId, type: $type) {
            ${jira}
        }
    }
`;

export const GET_PROJECTS_BY_USER = gql`
    query GetProjectsByUser($limit: Int!, $skip: Int!, $searchQuery: String) {
        projectsByUser(limit: $limit, skip: $skip, searchQuery: $searchQuery) {
            projects {
                _id
                type
                name
                created
            }
            totalProjects
        }
    }
`;

export const GET_PROJECT_BY_USER = gql`
	query GetProjectByUser($projectId: ID!) {
		projectByUser(projectId: $projectId) {
			${project}
		}
	}
`;

export const GET_PROJECT_BY_LINK = gql`
	query GetProjectByUser($projectId: ID!) {
		projectByLink(projectId: $projectId) {
			${project}
		}
	}
`;

export const RECOMMENDED_TECH_STACK_BY_VERSION = gql`
	query recommendedTechStackByVersion($projectId: String!, $version: Int!) {
		recommendedTechStackByVersion(projectId: $projectId, version: $version) {
			${recommended_tech_stack}
		}
	}
`;

export const RECOMMENDED_JOB_ROLES_BY_VERSION = gql`
	query recommendedJobRolesByVersion($projectId: String!, $version: Int!) {
		recommendedJobRolesByVersion(projectId: $projectId, version: $version) {
			${recommended_job_roles}
		}
	}
`;

export const GRAPHS_BY_VERSION = gql`
    query graphsByVersion($projectId: String!, $version: Int!) {
        graphsByVersion(projectId: $projectId, version: $version) {
            ${graph}
        }
    }
`;

export const USER_STRIPE_LINK = gql`
    query userStripeLink {
        userStripeLink
    }
`;

export const GET_SOLO_SUBSCRIPTION_LINKS = gql`
    query getSoloSubscriptionLinks {
        getSoloSubscriptionLinks {
            trial
            url
            tier
            price
            priceId
            productId
            duration
        }
    }
`;
