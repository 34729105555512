import { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useEnterpriseOrganizations } from '../../../../context-providers/EnterpriseOrganizations';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearStateProjects } from '../../../../redux/reducers/generic/projects';
import { selectEnterpriseOrganizations } from '../../../../redux/reducers/enterprise/enterpriseOrganizations';
import { useSelector } from 'react-redux';

export function ChooseOrganization() {
    let { enterpriseOrganization } = useEnterpriseOrganization();
    const { enterpriseOrganizationId } = useParams();
    const [currentName, setCurrentName] = useState(null);
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    const enterpriseOrganizations = useSelector(selectEnterpriseOrganizations);

    useEffect(() => {
        if (enterpriseOrganization) {
            setCurrentName(enterpriseOrganization.name);
        }
    }, [enterpriseOrganization]);

    return enterpriseOrganizations.length > 0 && currentName ? (
        <Popover className="relative">
            {enterpriseOrganizationId && (
                <Popover.Button
                    onClick={() => setOpen(true)}
                    className="flex border border-white items-center w-full gap-x-2 rounded-lg py-2 px-4 text-left text-sm font-medium transition-colors duration-200 hover:bg-slate-700 focus:outline-none"
                >
                    <span className="w-72 whitespace-nowrap overflow-hidden text-ellipsis">
                        Organization: <br />
                        <span className="text-gray-300">{currentName}</span>
                    </span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Popover.Button>
            )}

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-20 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                    <div
                        style={{ display: !open && 'none' }}
                        className="w-56 shrink max-h-96 overflow-scroll rounded-xl flex flex-col gap-y-4 bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5"
                    >
                        <Link
                            to="new"
                            className="text-center w-full whitespace-nowrap overflow-hidden text-green-800 rounded-lg py-2 hover:text-green-600"
                        >
                            + Add New Organization
                        </Link>
                        <hr className="border-gray-200" />
                        <span className="flex items-center justify-center w-full text-gray-500">
                            {enterpriseOrganizations.length > 0
                                ? 'Select an Organization'
                                : 'No Organizations'}
                        </span>
                        <div className="h-full overflow-y-auto">
                            {enterpriseOrganizations.map(item => (
                                <Link
                                    key={item.name}
                                    to={`${item._id}/clientProjects`}
                                    onClick={() => {
                                        setOpen(false);
                                        setCurrentName(item.name);
                                        dispatch(clearStateProjects());
                                    }}
                                    className={`
                  cursor-pointer
                  whitespace-nowrap overflow-hidden text-ellipsis
                  block p-2 hover:text-blue-600
                  ${
                      item._id === enterpriseOrganizationId
                          ? 'text-blue-700'
                          : 'text-gray-900'
                  }`}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    ) : (
        <Link
            to="new"
            className="flex w-full gap-x-2 rounded-lg  py-3 px-4 text-left text-sm font-medium transition-colors duration-200 hover:bg-slate-700 focus:outline-none"
        >
            <span className="w-72">{currentName}</span>
        </Link>
    );
}
