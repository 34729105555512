import { useDispatch, useSelector } from 'react-redux';
import { useProject } from '../../../context-providers/Project';
import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
    selectGraphVersion,
    selectGraphVersionLoading
} from '../../../redux/reducers/generic/projectArchitecture';
import { Spinner } from '../../Components/Spinner';
import { getGraphsByVersion } from '../../../redux/actions/solo/getGraphsByVersion';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const GraphVersions = () => {
    const { project } = useProject();
    const dispatch = useDispatch();

    const graphVersion = useSelector(selectGraphVersion);

    const handleShowGraph = async item => {
        dispatch(getGraphsByVersion(project._id.toString(), item));
    };

    const graphVersionLoading = useSelector(selectGraphVersionLoading);

    let buttons = (
        <>
            {project.graphVersions.map((item, index) => (
                <Menu.Item key={`graph-version-${index}`}>
                    {({ active }) => (
                        <button
                            onClick={() => handleShowGraph(item)}
                            className={classNames(
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'block px-4 py-2 text-sm w-full text-left flex flex-row items-center'
                            )}
                        >
                            v{item}
                        </button>
                    )}
                </Menu.Item>
            ))}
        </>
    );

    return (
        graphVersion != null && (
            <div className="flex items-center">
                <Menu as="div" className="cursor-pointer relative">
                    <Menu.Button className="bg-transparent cursor-pointer gap-x-1 text-2xs px-2 py-1 flex w-full justify-center items-center rounded-lg font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-700">
                        {graphVersionLoading ? (
                            <>
                                <span className="flex items-center">
                                    v{graphVersion}
                                </span>
                                <Spinner w="3" className="" />
                            </>
                        ) : (
                            <>
                                <span className="flex items-center">
                                    v{graphVersion}
                                </span>
                                <ChevronDownIcon
                                    className="h-4 w-4 text-gray-400"
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-left z-50 absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">{buttons}</div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        )
    );
};
