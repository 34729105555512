import { HeroExperience } from './HeroExperience/old_index';
import ClientSection from './client-section';
import PricingSection from '../Pricing/pricing-section';
import { SphereMask } from '../Components/magicui/sphere-mask';
import { CtaSection } from './cta-section';
import { FeaturesSection } from './features-section';
import { Footer } from '../Footer';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { setRedirectURL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { SiteHeader } from './site-header';
import RadialGradient from '../Components/magicui/RadialGradient';
import HeroSection from './hero-section';
import { ParticlesEffect } from './HeroExperience/ParticlesEffect';
import { FAQ } from './faq';
import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import { HowItWorks } from './HowItWorks';
import { useLocation } from 'react-router-dom';

export const LandingPage = () => {
    const sessionContext = useSessionContext();
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const onGetStarted = () => {
        let redirectURL = '/projects';
        if (enterpriseOrganizations.length > 0) {
            redirectURL = '/enterprise/dashboard';
        }
        setRedirectURL(redirectURL);
        if (sessionContext.doesSessionExist === true) {
            navigate(redirectURL);
        } else {
            navigate('/auth/?redirectToPath=%2Fprojects');
        }
    };

    return (
        <div className="bg-black">
            <div className="dark max-w-screen-xl mx-auto">
                <ParticlesEffect />
                <SiteHeader onGetStarted={onGetStarted} />
                <RadialGradient />
            </div>

            {pathname === '/how-it-works' ? (
                <>
                    <HowItWorks />
                    <Footer />
                </>
            ) : (
                <>
                    <HeroExperience />
                    <div className="dark max-w-screen-xl mx-auto mt-16">
                        <HeroSection onGetStarted={onGetStarted} />
                        <FeaturesSection />
                        <ClientSection />
                        <SphereMask />
                        <PricingSection />
                        <FAQ />
                        <CtaSection />
                        <Footer />
                    </div>
                    <div className="fixed">
                        <ParticlesEffect />
                    </div>
                </>
            )}
        </div>
    );
};
