import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

export const formatUserStories = (sow, previewMode) => {
    const formatUserStory = (story, previewMode) => {
        const formattedUserType = formatUserType(story, previewMode);
        const formattedDetails = formatStoryDetails(story, previewMode);

        return formatBulletList([
            formatListItem([
                formattedUserType,
                formatBulletList(formattedDetails)
            ])
        ]);
    };

    const formatUserType = (story, previewMode) => {
        try {
            return formatParagraph([
                formatBoldText(`${story?.userType}`, false)
            ]);
        } catch (error) {
            console.log('Error formatting user type:', error);
            return formatParagraph([formatText('Loading...', false)]);
        }
    };

    const formatStoryDetails = (story, previewMode) => {
        try {
            return [
                formatListItem([
                    formatParagraph([
                        formatBoldText('Action: ', false),
                        formatText(story?.action, previewMode)
                    ])
                ]),
                formatListItem([
                    formatParagraph([
                        formatBoldText('Benefit: ', false),
                        formatText(story?.benefit, previewMode)
                    ])
                ])
            ];
        } catch (error) {
            console.log('Error formatting story details:', error);
            return [
                formatListItem([
                    formatParagraph([formatText('Loading...', false)])
                ])
            ];
        }
    };

    let formattedContent = [];
    try {
        let heading = formatHeading(2, [formatText('User Stories', false)]);
        let stories =
            sow?.userStories?.map(story =>
                formatUserStory(story, previewMode)
            ) || [];
        formattedContent = [heading, ...stories];
    } catch (error) {
        console.log('Error formatting user stories:', error);
    }
    return formattedContent;
};
