'use client'

import { EditorContent } from '@tiptap/react'
import React, { useMemo, useRef, useEffect, useState } from 'react'

import { LinkMenu } from './TipTap/components/menus'

import { useBlockEditor } from './TipTap/hooks/useBlockEditor'

import './TipTap/styles/index.css'
import './TipTap/styles/globals.css';
import './TipTap/styles/editor.css';
import 'iframe-resizer/js/iframeResizer.contentWindow';

import { Sidebar } from './TipTap/components/Sidebar'
import ImageBlockMenu from './TipTap/extensions/ImageBlock/components/ImageBlockMenu'
import { ColumnsMenu } from './TipTap/extensions/MultiColumn/menus'
import { TableColumnMenu, TableRowMenu } from './TipTap/extensions/Table/menus'
import { EditorHeader } from './EditorHeader'
import { TextMenu } from './TipTap/components/menus/TextMenu'
import { ContentItemMenu } from './TipTap/components/menus/ContentItemMenu'
import { convertJSONtoTipTap } from './JSONtoTipTap'
import { selectEditMode, selectHideHours, selectSow, setEditMode } from '../../../redux/reducers/generic/sow'
import { useDispatch, useSelector } from 'react-redux'
import { Doc as YDoc } from 'yjs';
import showdown from 'showdown';
import ContentLoader from 'react-content-loader'
import 'react-loading-skeleton/dist/skeleton.css';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment'
import { useProject } from '../../../context-providers/Project'

export const BlockEditor = ({isEnterprise, isShare}) => {
  const ydoc = useMemo(() => new YDoc(), []);
  const converter = new showdown.Converter();
  const dispatch = useDispatch();
  const isEditMode = useSelector(selectEditMode);
  
  const sow = useSelector(selectSow);
  const hide_hours = useSelector(selectHideHours);
  const menuContainerRef = useRef(null)
  const [showEditor, setShowEditor] = useState(false);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const editorFlowRef = useRef(null);

  const { editor, leftSidebar } = useBlockEditor({ ydoc })
  const { showFullText} = useSubscriptionPayment();
  const {project} = useProject();

  let previewMode = !showFullText && !project?.isSubscribed

  const setContent = () => {
    if (sow?.projectId && editor) {
      let wasEmpty = editor.isEmpty || editor.getText() === '""';
      if(wasEmpty) {
        if(isMobile) {
          setShowEditor(false);
          setTimeout(() => {
            setShowEditor(true);
          }, 500);
        }
      }
      if (isEditMode && sow.userContent) {
        editor?.commands.setContent(JSON.parse(sow.userContent));
      } else if (sow?.type === 'custom') {
        dispatch(setEditMode(true));
        if(sow.userContent) {
          editor?.commands.setContent(JSON.parse(sow.userContent));
        }
        else editor?.commands.setContent(converter.makeHtml(sow.content));
      } else {
        editor?.commands.setContent(convertJSONtoTipTap(sow, hide_hours, previewMode));
      }
      // Scroll to the top of the DOM
      window.scrollTo(0, 0);
      
      // If on mobile, delay showing the editor slightly to allow for content to load
      // if (isMobile) {
      //   setShowEditor(false);
      //   setTimeout(() => {
      //     setShowEditor(true);
      //     window.scrollTo(0, 0); // Ensure we're still at the top after the delay
      //   }, 100);
      // }
    }
  }

  let timeoutId: NodeJS.Timeout | null = null;

  useEffect(() => {
    const handleCreate = () => {
      if (editor?.isEmpty && sow?.projectId) {
        setContent();
      }
    }
    const checkAndSetContent = () => {
      if (editor) {
        setContent();
      } else {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(checkAndSetContent, 100);
      }
    };

    checkAndSetContent();
    if (editor) {
      editor.on('update', handleCreate);
    }
    return () => {
      editor?.off('update', handleCreate);
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [editor, sow]);


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      ) !== null);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    if(isMobile) {
      setShowEditor(false);
      setTimeout(() => {
        setShowEditor(true);
      }, 500);
    }
    editor?.setEditable(isEditMode)
  }, [isEditMode])

  useEffect(() => {
    if(sow?.projectId) {
      if(sow.completed) editor.setEditable(isEditMode);
      if (!sow.completed) editor.setEditable(false);
    }
  }, [sow])

  useEffect(() => {
    const style = document.createElement('style')
    style.textContent = `
      .ProseMirror {
        padding-left: ${isEditMode ? '' : '2rem'} !important;
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [isEditMode])

  useEffect(() => {
    if (editor)  setContent()
  }, [sow, hide_hours, isEditMode])

  const handleScroll = () => {
    if (editorFlowRef.current) {
      setIsScrolledToTop(editorFlowRef.current.scrollTop === 0);
    }
  };

  if (!editor) {
    return null
  }

  return (
      <div className="flex h-full" ref={menuContainerRef}>
        <Sidebar isOpen={leftSidebar.isOpen} onClose={leftSidebar.close} editor={editor} />
        <div className="relative flex flex-col flex-1 h-full overflow-hidden">
          <EditorHeader
            isScrolledToTop={isScrolledToTop}
            isEnterprise={isEnterprise}
            isSidebarOpen={leftSidebar.isOpen}
            toggleSidebar={leftSidebar.toggle}
            editor={editor}
          />

          {!sow?.projectId || (!showEditor && isMobile)
            ?
            <Loading /> 
            : 
            <div 
            id='editorFlow'
            ref={editorFlowRef}
            onScroll={handleScroll}
            className={`flex-1 overflow-y-auto ${isEditMode ? 'lg:pl-16' : ''} pl-2`}>
              <EditorContent editor={editor} /> 
            </div>
          }
          {isEditMode && sow && sow.completed &&
           (
            <div className=''>
              <ContentItemMenu editor={editor} />
              <LinkMenu editor={editor} appendTo={menuContainerRef} />
              <TextMenu editor={editor} />
              <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
              <TableRowMenu editor={editor} appendTo={menuContainerRef} />
              <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
            </div>
          )}
        </div>
      </div>
  )
}


const Loading = () => {
  return (
      <div className="flex flex-col items-center justify-center">
          <Article />
      </div>
  );
};

const Article = props => (
  <ContentLoader viewBox="0 0 400 960" width={'80%'} {...props}>
      <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="80" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="100" rx="5" ry="5" width="400" height="200" />

      <rect x="0" y="310" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="326" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="347" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="362" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="377" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="397" rx="5" ry="5" width="400" height="200" />

      <rect x="0" y="607" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="623" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="644" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="659" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="674" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="694" rx="5" ry="5" width="400" height="200" />

      <rect x="0" y="904" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="920" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="941" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="956" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="971" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="991" rx="5" ry="5" width="400" height="200" />

      <rect x="0" y="1201" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="1217" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="1238" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="1253" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="1268" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="1288" rx="5" ry="5" width="400" height="200" />

      <rect x="0" y="1498" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="1514" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="1535" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="1550" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="1565" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="1585" rx="5" ry="5" width="400" height="200" />
  </ContentLoader>
);

export default BlockEditor
