import {
    clearHighlights,
    setHighlightNodes,
    setHighlightLinks
} from '../../reducers/generic/projectArchitecture';

export const highlightNodesLinks = node => async (dispatch, getState) => {
    let highlightNodes = [];
    let highlightLinks = [];

    dispatch(clearHighlights());

    if (node) {
        highlightNodes.push(node['id']);
        node?.neighbors?.forEach(neighbor => highlightNodes.push(neighbor));
        node?.links?.forEach(link => highlightLinks.push(link.id));
    }
    dispatch(setHighlightNodes(highlightNodes));
    dispatch(setHighlightLinks(highlightLinks));
};

export const clearNodesLinks = () => async dispatch => {
    dispatch(clearHighlights());
};
