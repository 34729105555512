import TextArea from 'react-textarea-autosize';
import { useRef, useState } from 'react';
import autosize from 'autosize';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { addExistingProjectContext } from '../../../../redux/actions/solo/addExistingProjectContext';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../../context-providers/Project';
import { Spinner } from '../../../Components/Spinner';
import { AttachFile } from '../ChatInput/AttachFile';
import { toast } from 'sonner';

export const Input = () => {
    const textareaRef = useRef(null);
    const [answer, setAnswer] = useState('');
    const [waiting, setWaiting] = useState(false);
    const dispatch = useDispatch();
    const { projectId } = useProject();
    const [image_url, setImageUrl] = useState(null);
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);

    const onChange = e => {
        setAnswer(e.target.value);
        autosize.destroy(document.querySelectorAll('textarea'));
    };

    const onClick = async () => {
        if (!answer && !fileKeyS3)
            return alert('Please provide a file or text');
        try {
            setWaiting(true);
            let type = 'text';
            if (image_url) type = 'image';
            else if (fileKeyS3) type = 'file';

            await dispatch(
                addExistingProjectContext({
                    text: answer,
                    image_url,
                    fileKeyS3,
                    type,
                    projectId
                })
            );
            setWaiting(false);
            setAnswer('');
            setImageUrl(null);
            setFileKeyS3(null);
            setFile(null);
            setProgress(0);
        } catch (error) {
            console.log(error);
            setWaiting(false);
            setAnswer('');
            setImageUrl(null);
            setFileKeyS3(null);
            setFile(null);
            setProgress(0);
            toast.error(error.message);
        }
    };

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                onClick();
            }}
        >
            <div
                className={`mt-3 py-2 px-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
            >
                <div className="flex flex-row items-center">
                    <TextArea
                        id="raw_text"
                        rows="1"
                        ref={textareaRef}
                        onChange={onChange}
                        value={answer}
                        disabled={waiting}
                        className="min-h-[40px] max-h-[150px] resize-none overflow-y-auto px-0 w-full text-base text-gray-900 border-0 focus:ring-0 dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                        placeholder={
                            waiting
                                ? 'Summarizing...'
                                : 'Write anything about your project...'
                        }
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                onClick();
                            }
                        }}
                    />
                    <button
                        type="submit"
                        disabled={waiting}
                        className={`text-white h-10 font-medium rounded-lg text-sm px-4 py-2 ml-2 text-center ${
                            waiting
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                        }`}
                    >
                        {waiting ? (
                            <Spinner w="4" />
                        ) : (
                            <PaperAirplaneIcon
                                className="h-5 w-5 mr-2"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
                <div className="flex flex-row items-center gap-x-2 mt-4">
                    <AttachFile
                        setImageUrl={setImageUrl}
                        setFileKeyS3={setFileKeyS3}
                        setFile={setFile}
                        setProgress={setProgress}
                        progress={progress}
                        setUploading={setUploading}
                        isUploading={isUploading}
                        file={file}
                    />
                </div>
            </div>
        </form>
    );
};
