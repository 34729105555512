import { useEffect, useRef } from 'react';
import { ChatItem } from './ChatItem';
import { useLoading } from '../../../../../context-providers/Loading';
import { StreamingChat } from './StreaminingChat';
import { useDispatch, useSelector } from 'react-redux';
import { selectChatHistory } from '../../../../../redux/reducers/generic/uiview';

export const ChatHistory = ({ isSolo }) => {
    let chatHistory = useSelector(selectChatHistory);
    let { loadingStatus } = useLoading();
    const chatContainer = useRef(null);

    useEffect(() => {
        //scroll to bottom of the chat
        try {
            if (chatContainer.current) {
                chatContainer.current.scrollTo({
                    top: chatContainer.current.scrollHeight
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [loadingStatus]);

    return (
        <div
            id="chatScroll"
            className="md:flex md:flex-col justify-end md:justify-start h-full lg:pb-0 overflow-y-auto"
            ref={chatContainer}
        >
            <div id="chatHistory">
                {chatHistory.data &&
                    chatHistory.data.map((message, i) => (
                        <ChatItem
                            isSolo={isSolo}
                            message={message}
                            i={i}
                            key={message.id}
                            length={chatHistory.data.length}
                        />
                    ))}
                <StreamingChat chatContainer={chatContainer} />
            </div>
        </div>
    );
};
