import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

export const formatStakeholders = (sow, previewMode) => {
    const formatStakeholder = stakeholder => {
        try {
            return formatListItem([
                formatParagraph([
                    formatBoldText(`${stakeholder?.title}: `, false),
                    formatText(stakeholder?.description, previewMode)
                ])
            ]);
        } catch (error) {
            console.log('Error formatting stakeholder:', error);
            return formatListItem([
                formatParagraph([formatText('Loading...', false)])
            ]);
        }
    };

    let formattedContent = [];
    try {
        let heading = formatHeading(2, [formatText('Stakeholders', false)]);
        let stakeholders = sow?.stakeholders?.map(formatStakeholder) || [];
        formattedContent = [heading, formatBulletList(stakeholders)];
    } catch (error) {
        console.log('Error formatting stakeholders:', error);
        formattedContent = [
            formatHeading(2, [formatText('Stakeholders', false)]),
            formatParagraph([formatText('Error loading stakeholders.', false)])
        ];
    }
    return formattedContent;
};
