import { client } from '../../../api/client';
import { GET_ENTERPRISE_ORGANIZATION_BY_USER } from '../../../api/graphQL';
import { ADD_SEATS_TO_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { fetchEnterpriseOrganizationSuccess } from '../../reducers/enterprise/enterpriseOrganization';
import { updateNumUsersLeft } from '../../reducers/enterprise/enterpriseUsers';
import { getEnterpriseOrganizationByUser } from './getEnterpriseOrganizationByUser';

export const addSeatsToEnterpriseOrganization =
    (enterpriseOrganizationId, seatsToAdd) => async (dispatch, getState) => {
        try {
            seatsToAdd = parseInt(seatsToAdd);

            let { data } = await client.mutate({
                mutation: ADD_SEATS_TO_ENTERPRISE_ORGANIZATION,
                variables: { enterpriseOrganizationId, seatsToAdd }
            });

            let currentNumUsersLeft = getState().enterpriseUsers.numUsersLeft;
            currentNumUsersLeft += seatsToAdd;
            dispatch(updateNumUsersLeft(currentNumUsersLeft));

            updateBilling(enterpriseOrganizationId, dispatch);

            return 'Successfully added seats to organization.';
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
            return error.message;
        }
    };

const updateBilling = async (enterpriseOrganizationId, dispatch) => {
    const { data } = await client.query({
        query: GET_ENTERPRISE_ORGANIZATION_BY_USER,
        variables: { enterpriseOrganizationId },
        fetchPolicy: 'network-only'
    });
    dispatch(
        fetchEnterpriseOrganizationSuccess(data.enterpriseOrganizationByUser)
    );
};
