export const getGraphData = els => {
    if (!els) {
        return null;
        // return {
        // 	nodes: [],
        // 	links: [],
        // };
    }
    const edges_links = els.links ? 'links' : 'edges';
    const data = {
        nodes: els.nodes.map(node => ({
            _id: node._id,
            id: node.id,
            label: node.label,
            hours: node.hours,
            description: node.description
        })),
        links: els[edges_links]
            .filter(link => {
                const sourceExists = els.nodes.some(
                    node => node.id === link.source
                );
                const targetExists = els.nodes.some(
                    node => node.id === link.target
                );
                return sourceExists && targetExists;
            })
            .map(link => ({
                id: edges_links === 'edges' ? link._id : link.id,
                source: link.source,
                target: link.target,
                description: link.description,
                curvature: -0.2
            }))
    };

    data.links.forEach(link => {
        const a = data.nodes.find(x => x.id === link.source);
        const b = data.nodes.find(x => x.id === link.target);

        if (a && b) {
            !a.neighbors && (a.neighbors = []);
            !b.neighbors && (b.neighbors = []);
            a.neighbors.push(b.id);
            b.neighbors.push(a.id);

            !a.links && (a.links = []);
            !b.links && (b.links = []);
            a.links.push(link);
            b.links.push(link);
        }
    });

    data.side = els.side;
    return data;
};

export const getDateTime = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    return new Date().toLocaleDateString(undefined, options);
};
