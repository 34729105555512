import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { useState } from 'react';
import { useCases } from './data';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { salesCalendlyLink } from '../../../constants';

export function UseCases() {
    const [selectedUseCase, setSelectedUseCase] = useState(0);

    return (
        <Popover className="relative">
            <PopoverButton className="inline-flex bg-gray-800 rounded-full px-4 py-2 items-center gap-x-1 text-sm font-bold text-white hover:bg-gray-700 transition-colors">
                <span>Use Cases</span>
                <ChevronDownIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-white"
                />
            </PopoverButton>

            <PopoverPanel
                transition
                style={{ maxHeight: 'calc(100vh - 180px)' }}
                className="fixed sm:absolute sm:-translate-x-[165px] left-0 z-10 mt-5 flex w-screen max-w-max px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="w-screen flex-auto overflow-y-scroll overflow-x-hidden rounded-3xl bg-gray-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-1 px-6 py-4 sm:grid-cols-2">
                        {useCases.map((item, index) => (
                            <UseCaseItem
                                key={item.name}
                                useCase={item}
                                index={index}
                                selectedUseCase={selectedUseCase}
                                setSelectedUseCase={setSelectedUseCase}
                            />
                        ))}
                    </div>
                    <ChosenUseCase useCase={useCases[selectedUseCase]} />
                </div>
            </PopoverPanel>
        </Popover>
    );
}

const UseCaseItem = ({
    useCase,
    selectedUseCase,
    setSelectedUseCase,
    index
}) => {
    return (
        <div
            key={index}
            className={`group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-700 transition-colors ${
                useCase.name === useCases[selectedUseCase].name
                    ? 'bg-gray-700'
                    : ''
            }`}
            onMouseEnter={() => {
                setSelectedUseCase(index);
            }}
            onClick={() => {
                setSelectedUseCase(index);
            }}
        >
            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-700 group-hover:bg-gray-600 transition-colors">
                <useCase.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-300 group-hover:text-indigo-400 transition-colors"
                />
            </div>
            <div className="flex flex-col justify-center">
                <a
                    href={useCase.href}
                    className="font-bold text-white text-lg hover:text-indigo-400 transition-colors"
                >
                    {useCase.name}
                    <span className="absolute inset-0" />
                </a>
                <p className="mt-1 text-gray-300 hidden sm:flex font-medium">
                    <div>{useCase.description}</div>
                </p>
                <div className="w-fit mt-2 inline-block sm:bg-indigo-600 sm:rounded-full sm:px-3 sm:py-1 text-xs font-bold text-indigo-400 sm:text-white">
                    {useCase.tagline}
                </div>
            </div>
        </div>
    );
};
const ChosenUseCase = ({ useCase }) => {
    return (
        <div className="bg-gray-700 px-8 py-6">
            <div className="flex justify-between items-center mb-2">
                <h3 className="text-xl font-bold text-white">{useCase.name}</h3>
                <div className="bg-indigo-600 rounded-full px-3 py-1 text-xs sm:text-sm font-bold text-white">
                    {useCase.tagline}
                </div>
            </div>
            <p className="text-gray-200 mb-4 font-medium">
                {useCase.description}
            </p>
            {useCase.advantages.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 border-gray-600">
                    {useCase.advantages.map((advantage, index) => (
                        <div
                            key={index}
                            className={`flex items-center p-3 ${
                                index % 2 === 0
                                    ? 'sm:border-r border-gray-600'
                                    : ''
                            } ${
                                index < useCase.advantages.length - 2
                                    ? 'border-b border-gray-600'
                                    : ''
                            }`}
                        >
                            <div className="bg-gray-600 rounded-full p-2 mr-3">
                                <advantage.icon className="h-5 w-5 text-indigo-400" />
                            </div>
                            <span className="text-sm text-gray-200 font-medium">
                                {advantage.text}
                            </span>
                        </div>
                    ))}
                </div>
            )}
            <div className="mt-4 flex justify-center space-x-4">
                <Link
                    to="/auth"
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Get Started
                </Link>
                <a
                    href={salesCalendlyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                >
                    Contact Us
                </a>
            </div>
        </div>
    );
};
