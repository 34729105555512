import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

export const formatSuccessMetrics = (sow, previewMode) => {
    const formatMetric = metric => {
        try {
            return formatListItem([
                formatParagraph([
                    formatBoldText(`${metric?.name}: `, false),
                    formatText(metric?.description, previewMode)
                ])
            ]);
        } catch (error) {
            console.log('Error formatting success metric:', error);
            return formatListItem([
                formatParagraph([formatText('Loading...', false)])
            ]);
        }
    };

    let formattedContent = [];
    try {
        let heading = formatHeading(2, [formatText('Success Metrics', false)]);
        let metrics = sow?.successMetrics?.map(formatMetric) || [];
        formattedContent = [heading, formatBulletList(metrics)];
    } catch (error) {
        console.log('Error formatting success metrics:', error);
        formattedContent = [
            formatHeading(2, [formatText('Success Metrics', false)]),
            formatParagraph([
                formatText('Error loading success metrics.', false)
            ])
        ];
    }
    return formattedContent;
};
