import { useEffect, useState } from 'react';
import DesktopSidebar from './Sidebars/DesktopSidebar';
import MobileSidebar from './Sidebars/MobileSidebar';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SubscribeModal } from './SubscribeModal';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectInitLoaded } from '../../redux/reducers/enterprise/enterpriseOrganizations';
import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import { useDispatch } from 'react-redux';
import { clearStateEnterpriseOrganization } from '../../redux/reducers/enterprise/enterpriseOrganization';
import { clearStateEnterpriseOrganizations } from '../../redux/reducers/enterprise/enterpriseOrganizations';
import { clearWidgetState } from '../../redux/reducers/enterprise_widget/enterpriseWidget';
import { clearStateProjects } from '../../redux/reducers/generic/projects';

export default function EnterpriseHome() {
    const dispatch = useDispatch();
    //create a state variable to track the height of the window
    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const initLoaded = useSelector(selectInitLoaded);
    const { enterpriseOrganizations } = useEnterpriseOrganizations();

    useEffect(() => {
        return () => {
            dispatch(clearWidgetState());
            dispatch(clearStateEnterpriseOrganization());
            dispatch(clearStateEnterpriseOrganizations());
        };
    }, []);

    //create an event handler that tracks the height of the window
    useEffect(() => {
        const resizeHandler = () => {
            setPageHeight(window.innerHeight);
            setPageWidth(window.innerWidth);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler(); // Call it initially to set the initial width
        return () => {
            window.removeEventListener('resize', resizeHandler);
            dispatch(clearStateProjects());
        };
    }, []);

    useEffect(() => {
        if (
            enterpriseOrganizations.length > 0 &&
            pathname === '/enterprise/dashboard'
        ) {
            navigate(
                `/enterprise/dashboard/${enterpriseOrganizations[0]._id}/clientProjects`
            );
        }
    }, [enterpriseOrganizations]);

    if (
        !pathname.includes('new') &&
        initLoaded &&
        enterpriseOrganizations.length === 0
    ) {
        navigate('new');
    }

    const height = pageHeight - (pageWidth < 1024 ? 46 : 0) + 'px';

    let hideSidebar = pathname === '/enterprise/dashboard/new';

    return (
        <>
            {!hideSidebar && <MobileSidebar />}
            <div
                className="bg-white w-full h-screen flex flex-row "
                style={{ maxHeight: height }}
            >
                {!hideSidebar && <DesktopSidebar />}
                <SubscribeModal />
                <div
                    id="mainPage"
                    className={`w-full flex h-full overflow-auto lg:h-screen flex-col justify-center`}
                >
                    <div className="w-full flex flex-col h-full">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}
