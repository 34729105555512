import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../Components/Spinner';
import { getSignedUploadURL } from '../../../../redux/actions/solo/getSignedUploadURL';
import { getSignedDownloadURL } from '../../../../redux/actions/solo/getSignedDownloadURL';
import { v4 as uuidv4 } from 'uuid';
import { getUIView } from '../../../../redux/actions/solo/getUIView';

export const UploadWireframe = ({ node, projectId }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [fileKey, setFileKey] = useState(null);
    const [uploading, setUploading] = useState(null);
    //UPON Creation of Project, call

    const handleFileChange = async e => {
        const file = e.target.files[0];
        setFile(file);
        if (!file) return;
        setUploading(true);

        // Randomize filename
        let uniqueFilename = uuidv4() + file.name;

        if (file.size > 50 * 1024 * 1024) {
            alert('File is too large. Please upload a file smaller than 50MB.');
            setUploading(false);
            return;
        }

        // 1. Fetch the signed URL
        const signedUrl = await dispatch(getSignedUploadURL(uniqueFilename));

        // 2. Upload the file using the signed URL
        await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', signedUrl, true);
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    setProgress(percentComplete.toFixed(2));
                    console.log(
                        `File upload progress: ${percentComplete.toFixed(2)}%`
                    );
                }
            };
            xhr.onload = function () {
                if (xhr.status === 200) {
                    console.log('File uploaded successfully.');
                    resolve();
                } else {
                    console.error('File upload failed.');
                    reject(new Error('File upload failed.'));
                }
            };
            xhr.onerror = function () {
                console.error('File upload failed.');
                reject(new Error('File upload failed.'));
            };
            xhr.send(file);
        });

        const url = await dispatch(getSignedDownloadURL(uniqueFilename));

        setUploading(false);
        // 3. Store the key (filename or path in S3) in a state
        setFileKey(uniqueFilename);

        dispatch(
            getUIView({
                nodeId: node._id,
                projectId: projectId,
                forceNew: true,
                image_url: url
            })
        );
    };

    return (
        <div className="mt-2 mb-4 w-[400px] items-center">
            <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="user_avatar"
            >
                Optional: Upload Wireframe
            </label>

            <input
                onChange={handleFileChange}
                accept=".png,.jpg,.jpeg,.svg"
                className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${
                    uploading ? 'hidden' : ''
                }`}
                aria-describedby="user_avatar_help"
                id="user_avatar"
                type="file"
            />

            {uploading && (
                <div className="flex flex-col gap-y-1">
                    <div className="w-full bg-blue-200 rounded-lg h-10 relative">
                        <div
                            className="bg-blue-500 h-10 rounded-lg"
                            style={{ width: `${progress}%` }}
                        >
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-xs">
                                {progress}% - Uploading{' '}
                                {file &&
                                    file.name.substring(0, 20) +
                                        (file.name.length > 20 ? '...' : '')}
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <span className="ml-2"></span>
                    </div>
                </div>
            )}
        </div>
    );
};
